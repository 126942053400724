import {PartsStyleObject} from '@chakra-ui/theme-tools';
import {alertAnatomy as parts} from '@chakra-ui/anatomy';
import {colors} from '../colors';

type Dict = Record<string, any>;

const baseStyle: PartsStyleObject<typeof parts> = {
  title: {
    fontWeight: '300',
  },
};

function variantSubtle(props: Dict) {
  const {status: c} = props;
  let bg;
  let caption;
  let icon;
  switch (c) {
    case 'info':
      bg = 'blue.50';
      caption = colors.alertInfoCaption;
      break;
    case 'red':
      bg = colors.errorBackgroundColor;
      caption = 'white';
      icon = 'white';
      break;
  }

  return {
    container: {bg: bg, color: caption},
    icon: {color: icon},
  };
}

const variants = {
  subtle: variantSubtle,
};

const defaultProps = {
  variant: 'subtle',
  colorScheme: 'blue',
};

export const Alert = {
  baseStyle,
  variants,
  defaultProps,
};
