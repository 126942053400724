import dayjs from 'dayjs';
import {defaultPageSize} from '../../../api/getServiceTimeslotSets';
import {ServiceTimeslotSet} from '../types/Services';

const createMockTimeSlotSet = (
  index: number,
  showCapacity: boolean,
  startDate?: string,
): ServiceTimeslotSet[] => {
  const startTime1 = dayjs(startDate).add(index, 'day').toISOString();
  const endTime1 = dayjs(startDate)
    .add(index, 'day')
    .add(8, 'hour')
    .toISOString();
  const startTime2 = dayjs(startDate)
    .add(index + 7, 'day')
    .toISOString();
  const endTime2 = dayjs(startDate)
    .add(index + 7, 'day')
    .add(8, 'hour')
    .toISOString();
  return [
    {
      locationId: '28a913c1-fe41-ec11-8c62-000d3aaf0a4f',
      resourceAvailabilityId: '6fd81444-649b-eb11-b1ac-000d3ab191f3',
      activityDefinitionId: 'f53c6832-878c-eb11-b1ac-002248995deb',
      activityTitle: 'EHBO2 2',
      practitionerName: 'John Doe',
      practitionerRoles: ['Trainer'],
      startTime: startTime1,
      endTime: endTime1,
      availableCapacity: 6,
      showAvailableCapacity: showCapacity,
    },
    {
      locationId: '28a913c1-fe41-ec11-8c62-000d3aaf0a4f',
      resourceAvailabilityId: '6fd81444-649b-eb11-b1ac-000d3ab191f4',
      activityDefinitionId: 'f53c6832-878c-eb11-b1ac-002248995deb',
      activityTitle: 'EHBO2 2.1',
      practitionerName: 'John Doe',
      practitionerRoles: ['Trainer'],
      startTime: startTime2,
      endTime: endTime2,
      availableCapacity: 6,
      showAvailableCapacity: showCapacity,
    },
  ];
};

const createMockTimeSlotSets = (
  showCapacity: boolean,
  startDate?: string,
): ServiceTimeslotSet[][] => {
  const result: ServiceTimeslotSet[][] = [];
  let i = startDate ? defaultPageSize - 1 : 0;
  while (i < defaultPageSize) {
    result.push(createMockTimeSlotSet(i, showCapacity, startDate));
    i++;
  }
  return result;
};

export const mockTimeslotSetsWithCapacity = createMockTimeSlotSets(true);
export const mockTimeslotSetsWithoutCapacity = createMockTimeSlotSets(false);
export const getMockTimeslotSetsWithCapacityLoadMore = (
  showCapacity: boolean,
  startDate: string,
): ServiceTimeslotSet[][] => {
  return createMockTimeSlotSets(showCapacity, startDate);
};
