import {Box, Container, Flex} from '@chakra-ui/react';
import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useAppointment} from '../../../api/getAppointment';
import {useAppointmentSet} from '../../../api/getAppointmentSet';
import {
  startService,
  startServiceForMultipleStudents,
} from '../../../api/startService';
import {updateAppointment} from '../../../api/updateAppointment';
import {updateAppointmentSet} from '../../../api/updateAppointmentSet';
import {Alert} from '../../../components/Common/Alert';
import {Loader} from '../../../components/Common/Loader';
import {FreeFormatAppointmenController} from '../../../components/Service/Appointment/FreeFormatAppointmentController';
import {AppointmentTimeslotSelectorController} from '../../../components/Service/AppointmentNew/AppointmentTimeslotSelectorController';
import {StepperButtonBar} from '../../../components/Service/StepperButtonBar/StepperButtonBar';
import {
  AppointmentsOverviewRoute,
  ServiceRouteStart,
} from '../../../constants/Routes';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {useErrorDispatch} from '../../../contexts/errorDispatchContext';
import {useAcquireApiToken} from '../../../hooks/useAcquireApiToken';
import {useDurableFunction} from '../../../hooks/useDurableFunction';
import {StartServiceRequestData} from '../../../lib/api/types/Requests';
import {DurableActionResponse} from '../../../lib/api/types/Services';
import {i18n} from '../../../providers/i18n/i18n';
import {ErrorActionType} from '../../../reducers/errorReducer';
import {FapperError} from '../../../utils/fapper';

interface RouteParams {
  state: {
    navigateBack?: boolean;
  };
}

export const ServiceDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const {state, dispatch} = useCustomerPortalState();
  const {state: routeState} = useLocation() as RouteParams;
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [error, setError] = useState<Error>();
  const navigate = useNavigate();
  const {accessToken} = useAcquireApiToken();
  const errorDispatch = useErrorDispatch();

  const {
    setdurableStatusUrl,
    setIsRunningDurableFunction,
    setIsLoading: setIsLoadingDurableFunction,
    isLoading: isLoadingDurableFunction,
    error: durableFunctionError,
    errorJson: durableFunctionErrorJson,
    isSucceeded,
  } = useDurableFunction();

  const id = searchParams.get('id');
  const setId = searchParams.get('setId');
  const {
    data: appointmentData,
    isLoading: isLoadingAppointment,
    isFetching: isFetchingAppointment,
    error: errorAppointment,
  } = useAppointment(id, i18n.resolvedLanguage);

  const {
    data: appointmentSetData,
    isLoading: isLoadingAppointmentSet,
    isFetching: isFetchingAppointmentSet,
    error: errorAppointmentSet,
  } = useAppointmentSet(setId, i18n.resolvedLanguage);

  useEffect(() => {
    if (
      !state.service.data.service &&
      !state.service.data.currentAppointment &&
      !id &&
      !setId &&
      accessToken
    ) {
      navigate(ServiceRouteStart);
    }
  }, [id, navigate, setId, state, accessToken]);

  useEffect(() => {
    if (appointmentData) {
      dispatch({
        type: 'SERVICE_STORE_CURRENT_APPOINTMENT',
        payload: {
          currentAppointment: {
            appointmentId: appointmentData.appointmentId,
            accountId: appointmentData.accountId,
            isProcessing: appointmentData.isProcessing,
            patientName: appointmentData.patientName,
            planDefinitionName: appointmentData.planDefinitionName,
            planDefinitionId: appointmentData.planDefinitionId,
            employeeNumber: appointmentData.employeeNumber,
            departmentCode: appointmentData.departmentCode,
            appointments: [
              {
                ...appointmentData,
              },
            ],
          },
        },
      });
    } else if (
      appointmentSetData &&
      appointmentSetData.appointments.length > 0
    ) {
      dispatch({
        type: 'SERVICE_STORE_CURRENT_APPOINTMENT',
        payload: {
          currentAppointment: {
            appointmentSetId: appointmentSetData.appointmentSetId,
            accountId: appointmentSetData.accountId,
            isProcessing: appointmentSetData.isProcessing,
            patientName: appointmentSetData.patientName,
            planDefinitionName: appointmentSetData.planDefinitionName,
            planDefinitionId:
              appointmentSetData.appointments[0].planDefinitionId!!,
            employeeNumber: appointmentSetData.employeeNumber,
            departmentCode: appointmentSetData.departmentCode,
            appointments: appointmentSetData.appointments,
          },
        },
      });
    }
  }, [appointmentData, appointmentSetData, dispatch]);

  useEffect(() => {
    setIsEditting(false);
    if (state.service.data.currentAppointment) {
      setIsEditting(true);
    }
  }, [state.service.data.currentAppointment]);

  useEffect(() => {
    if (!isLoading && durableFunctionErrorJson) {
      const names: string[] = [];
      durableFunctionErrorJson.patientIds?.forEach((patientId) => {
        const result = state.service.data.student?.find(
          (student) => student.patientId === patientId,
        );
        if (result) {
          names.push(`${result.firstName} ${result.lastName}`);
        }
      });

      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error: {
            statusText: t('service.failed_start_service_for_patients', {
              patients: names.join(', '),
            }),
          },
        },
      });
    }
  }, [
    isLoading,
    durableFunctionErrorJson,
    errorDispatch,
    state.service.data.student,
    t,
  ]);

  useEffect(() => {
    const err = (error ||
      durableFunctionError ||
      errorAppointment ||
      errorAppointmentSet) as FapperError;
    if (!isLoading && err) {
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error: err,
        },
      });
    }
  }, [
    isLoading,
    error,
    durableFunctionError,
    errorAppointment,
    errorAppointmentSet,
    errorDispatch,
  ]);

  const clearState = () => {
    dispatch({
      type: 'SERVICE_CLEAR',
    });
  };

  const clearDetails = () => {
    dispatch({
      type: 'SERVICE_DETAILS_CLEAR',
    });
  };

  const nextButtonIsEnabled =
    state.service.data.timeslots || state.service.data.freeFormat;

  const nextButtonLabel = () => {
    if (isEditting) {
      return isSucceeded
        ? t('service.start_new_update.title')
        : t('service.update.title');
    }

    return isSucceeded
      ? t('service.start_new.title')
      : t('service.start.title');
  };

  const onPreviousClick = () => {
    if (isSucceeded) {
      clearState();
    }
    if (isEditting || routeState?.navigateBack) {
      clearState();
      navigate(-1);
    } else {
      navigate(ServiceRouteStart);
    }
  };

  const onNextClick = () => {
    if (isEditting) {
      if (isSucceeded) {
        clearState();
        navigate(AppointmentsOverviewRoute, {
          state: {
            refetch: true,
          },
        });
      } else {
        onRunService();
      }
    } else {
      if (isSucceeded) {
        clearState();
        navigate(ServiceRouteStart);
      } else {
        onRunService();
      }
    }
  };

  const reset = () => {
    setIsLoading(false);
    setIsRunningDurableFunction(false);
    setIsLoadingDurableFunction(false);
  };

  const onUpdateAppointment = async () => {
    try {
      const appointment = state.service.data.currentAppointment;
      const timeslots = state.service.data.timeslots;
      // const timeslotSet = state.service.data.timeslotSet;

      if (timeslots) {
        setIsLoading(true);
        setError(undefined);
        setIsRunningDurableFunction(true);
      }
      let response: DurableActionResponse | undefined;
      if (timeslots && appointment?.appointmentId) {
        response = await updateAppointment(
          appointment?.appointmentId,
          {
            timeslot: {
              activityDefinitionId: timeslots[0].activityDefinitionId,
              startTime: timeslots[0].startTime,
              endTime: timeslots[0].endTime,
              resourceAvailabilityId: timeslots[0].resourceAvailabilityId,
            },
          },
          accessToken,
        );
      }
      if (timeslots && appointment?.appointmentSetId) {
        response = await updateAppointmentSet(
          appointment?.appointmentSetId,
          {
            timeSlots: timeslots.map((set) => {
              return {
                activityDefinitionId: set.activityDefinitionId,
                startTime: set.startTime,
                endTime: set.endTime,
                resourceAvailabilityId: set.resourceAvailabilityId,
              };
            }),
          },
          accessToken,
        );
      }
      if (!response?.statusQueryGetUri) {
        handleError(t('service.start.no_status_uri'));
      } else {
        setIsLoading(false);
        setIsLoadingDurableFunction(true);
        setdurableStatusUrl(response.statusQueryGetUri);
      }
    } catch (e: any) {
      console.error(e);
      setError(e as Error);
      reset();
    }
  };

  const onRunService = async () => {
    if (isEditting) {
      onUpdateAppointment();
    } else {
      onStartService();
    }
  };

  const onStartService = async () => {
    try {
      let payload: StartServiceRequestData[] = [];
      if (!state.service.data.service || !state.service.data.student) {
        return;
      }
      if (!state.service.data.timeslots && !state.service.data.freeFormat) {
        return;
      }

      const timeslots = state.service.data.timeslots;

      if (timeslots && timeslots.length > 0) {
        if (timeslots.length === 1) {
          payload.push({
            activityDefinitionId: timeslots[0].activityDefinitionId,
            startTime: timeslots[0].startTime,
            endTime: timeslots[0].endTime,
            resourceAvailabilityId: timeslots[0].resourceAvailabilityId,
          });
        } else {
          payload = timeslots.map((set) => {
            return {
              activityDefinitionId: set.activityDefinitionId,
              startTime: set.startTime,
              endTime: set.endTime,
              resourceAvailabilityId: set.resourceAvailabilityId,
            };
          });
        }
      }

      setIsLoading(true);
      setError(undefined);
      setIsRunningDurableFunction(true);
      let response;
      if (state.service.data.isMultipleStudentsSelected) {
        const patients = state.service.data.student.map((stu) => stu.patientId);
        response = await startServiceForMultipleStudents(
          {
            planDefinitionId: state.service.data.service.planDefinitionId,
            accountId: state.service.data.service.accountId,
            appointments: payload,
            onBehalfOfPatientIds: patients,
          },
          accessToken,
        );
      } else {
        response = await startService(
          {
            planDefinitionId: state.service.data.service.planDefinitionId,
            accountId: state.service.data.service.accountId,
            appointments: payload,
            onBehalfOfPatientId: state.service.data.student[0].patientId,
          },
          accessToken,
        );
      }

      if (!response?.statusQueryGetUri) {
        handleError(t('service.start.no_status_uri'));
      } else {
        setIsLoading(false);
        setIsLoadingDurableFunction(true);
        setdurableStatusUrl(response.statusQueryGetUri);
      }
    } catch (e: any) {
      console.error(e);
      setError(e as Error);
      reset();
    }
  };

  const handleError = (message: string) => {
    const err = new Error(message);
    console.error(err);
    setError(err);
  };

  return (
    <Container maxW="container.xl" pt={8} pb={28}>
      {(isLoadingAppointment ||
        isFetchingAppointment ||
        isLoadingAppointmentSet ||
        isFetchingAppointmentSet) && <Loader />}

      {(state.service.data.service?.type.toLowerCase() === 'appointment' ||
        state.service.data.service?.type.toLowerCase() === 'appointmentset' ||
        state.service.data.currentAppointment) && (
        <AppointmentTimeslotSelectorController />
      )}

      {state.service.data.service?.type.toLowerCase() ===
        'freeformatappointment' && <FreeFormatAppointmenController />}
      {isSucceeded && (
        <Flex justifyContent="center">
          <Alert
            message={
              isEditting
                ? t('appointment.succes_dashboard_delay')
                : t('service.succes_dashboard_delay')
            }
            status="success"
          />
        </Flex>
      )}
      {(isLoadingDurableFunction || isLoading) && (
        <Box
          position="absolute"
          top={0}
          left={0}
          height={'100%'}
          width={'100%'}
          bg={'#FFFFFF80'}
        />
      )}

      <StepperButtonBar
        onCancelClick={() => {
          clearState();
          if (isEditting) {
            navigate(AppointmentsOverviewRoute);
          } else {
            navigate(ServiceRouteStart);
          }
        }}
        onPreviousClick={() => {
          clearDetails();
          onPreviousClick();
        }}
        onNextClick={() => {
          onNextClick();
        }}
        nextButtonLabel={nextButtonLabel()}
        nextButtonIsDisabled={
          !nextButtonIsEnabled || durableFunctionErrorJson !== undefined
        }
        isRunningStartService={isLoading || isLoadingDurableFunction}
      />
    </Container>
  );
};
