import {Heading} from './Heading';
import {Modal} from './Modal';
import {Alert} from './Alert';
import {Accordion} from './Accordion';
import {Button} from './Button';
import {Badge} from './Badge';

export const components = {
  Heading,
  Modal,
  Alert,
  Accordion,
  Button,
  Badge,
};
