import {Heading, Flex} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

interface Props {
  planDefinitionName: string;
  patientName: string;
}
export const DetailHeader = ({planDefinitionName, patientName}: Props) => {
  const {t} = useTranslation();

  return (
    <Flex wrap={'wrap'}>
      <Heading me="3">{planDefinitionName} </Heading>
      <Heading fontWeight={'light'} me="3">
        {t('for')}
      </Heading>
      <Heading>{patientName} </Heading>
    </Flex>
  );
};
