import {Stack} from '@chakra-ui/react';
import {MenuProps} from './DesktopNav';
import {MobileNavItem} from './MobileNavItem';

export const MobileNav = ({menuOne, menuTwo}: MenuProps) => {
  return (
    <Stack p={4} display={{lg: 'none'}}>
      {menuOne && <MobileNavItem {...menuOne} />}
      {menuTwo && <MobileNavItem {...menuTwo} />}
    </Stack>
  );
};
