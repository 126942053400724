import {Badge, Box, Button, Flex, Text, Tooltip} from '@chakra-ui/react';
import {InfoOutlineIcon} from '@chakra-ui/icons';
import React, {useEffect, useState} from 'react';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {useTranslation} from 'react-i18next';
import {Student} from '../../../lib/api/types/Student';

export const StudentMetaData = () => {
  const {t} = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [patientName, setPatientName] = useState<string | undefined>('');
  const [employeeNumber, setEmployeeNumber] =
    useState<string | undefined | null>('');
  const [departmentCode, setDepartmentCode] =
    useState<string | undefined | null>('');
  const {state} = useCustomerPortalState();
  const {student, currentAppointment} = state.service.data;

  useEffect(() => {
    setPatientName(currentAppointment?.patientName);
    setEmployeeNumber(currentAppointment?.employeeNumber);
    setDepartmentCode(currentAppointment?.departmentCode);
  }, [currentAppointment]);

  const renderTooltip = (stu: Student, index: number) => {
    return (
      <Box mr={3}>
        <Text as="span" d="flex" alignItems="center" fontSize="md">
          {stu.firstName} {stu.lastName}
          <Tooltip
            label={
              <>
                {stu.employeeNumber && (
                  <Box>
                    {t('service.employee_number')}: {stu.employeeNumber}
                  </Box>
                )}
                {stu.departmentCode && (
                  <Box>
                    {t('service.department_code')}: {stu.departmentCode}
                  </Box>
                )}
              </>
            }
          >
            <InfoOutlineIcon mx={1} w={3} h={3} color="faqIconColor" />
          </Tooltip>
          {student && index < student.length - 1 && ','}
        </Text>
      </Box>
    );
  };
  return (
    <>
      <Flex alignItems="center">
        {patientName && (
          <Text as="span" mr={5}>
            {patientName}
          </Text>
        )}
        {departmentCode && <Badge>{departmentCode}</Badge>}
        {employeeNumber && <Badge mr={2}>{employeeNumber}</Badge>}

        {student?.map((stu, index) => (
          <React.Fragment key={index}>
            {index < 4 && <>{renderTooltip(stu, index)}</>}
          </React.Fragment>
        ))}
        {student?.map((stu, index) => (
          <Box key={index} display={showMore ? 'block' : 'none'}>
            {index > 3 && <Box>{renderTooltip(stu, index)}</Box>}
          </Box>
        ))}
        {student && student.length > 4 && (
          <Button
            variant="link"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {showMore ? t('show_less') : t('show_more')}
          </Button>
        )}
      </Flex>
    </>
  );
};
