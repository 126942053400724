import React, {useState, useEffect} from 'react';
import logo from '../../../assets/images/logo@x1.svg';
import {Button, Image, Box, Text, Flex, Link, Collapse} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {useMsal, useAccount} from '@azure/msal-react';
import {useTranslation} from 'react-i18next';
import {LogIn, LogOut} from 'react-feather';
import {MenuToggle} from './MenuToggle';
import {NavBarContainer} from './NavBarContainer';
import {useIsAuthenticated} from '@azure/msal-react';
import {InteractionStatus} from '@azure/msal-browser';
import {loginRequest} from '../../../authentication/authenticationConfiguration';
import {DesktopNav} from './DesktopNav';
import {ExtendedRoute} from '../../../routesConfiguration';
import {MobileNav} from './MobileNav';
import {useMenu} from './useMenu';
export interface NavigationBarProps {
  children?: React.ReactNode;
}

export type Menu = {
  label: string;
  items: ExtendedRoute[];
};

export const ChakraNavigationBar = () => {
  const {instance, inProgress, accounts} = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const {menuOne, menuTwo} = useMenu();

  const account = useAccount(accounts[0] || {});
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (account && account.name) {
      setName(account.name);
    } else {
      setName('');
    }
  }, [account]);

  return (
    <>
      <NavBarContainer>
        <Link as={RouterLink} to="/">
          <Image
            className="logo pb-2"
            w={200}
            fit="contain"
            src={logo}
            alt="logo"
          />
        </Link>
        {isAuthenticated ? (
          <>
            <Box d="flex" flexDirection={{base: 'column', lg: 'row'}}>
              <Flex justifyContent="flex-end" alignItems="center">
                <MenuToggle toggle={toggle} isOpen={isOpen} />
                <Button
                  ml={2}
                  leftIcon={<LogIn />}
                  colorScheme="secondary"
                  variant="solid"
                  onClick={() => instance.logoutRedirect()}
                >
                  <Text m={0} d={{base: 'none', lg: 'block'}}>
                    {t('logout')}
                  </Text>
                </Button>
              </Flex>
              <Box>
                <Text margin="auto" padding="5px 0.5rem">
                  {name}
                </Text>
              </Box>
            </Box>
            <Box
              display={{base: 'none', lg: 'block'}}
              flexBasis="100%"
              sx={{
                my: {base: 0, lg: 4},
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: {base: 'transparent', lg: 'primary'},
              }}
            >
              <DesktopNav menuOne={menuOne} menuTwo={menuTwo} />
            </Box>
            <Box w="100%">
              <Collapse in={isOpen} animateOpacity>
                <MobileNav menuOne={menuOne} menuTwo={menuTwo} />
              </Collapse>
            </Box>
          </>
        ) : (
          <>
            {/* inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two*/}
            {inProgress !== InteractionStatus.Startup &&
            inProgress !== InteractionStatus.HandleRedirect ? (
              <Button
                leftIcon={<LogOut />}
                colorScheme="secondary"
                variant="solid"
                onClick={() => instance.loginRedirect(loginRequest)}
              >
                {t('login')}
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </NavBarContainer>
    </>
  );
};
