export enum PowerBIReports {
  Appointment,
  Timeline,
  Covid19,
  Questionnaire,
  Results,
  Content,
  Flow,
  Coordinator,
}
