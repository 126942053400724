import {Box, Container, Heading} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {FormEvent, useState, useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  defaultPageSize,
  getAppointmentOverview,
  useAppointmentOverview,
} from '../../../api/getAppointmentsOverview';
import {Loader} from '../../../components/Common/Loader';
import {TextWithLineBreak} from '../../../components/Common/TextWithLineBreak/TextWithLineBreak';
import {AppointmentDetailsRoute} from '../../../constants/Routes';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {useAcquireApiToken} from '../../../hooks/useAcquireApiToken';
import {Appointment} from '../../../lib/api/types/AppointmentsOverview';
import {AppointmentsOverviewRequest} from '../../../lib/api/types/Requests';
import {Filters} from './Filters';
import {ListItem} from './ListItem';
import {LoadMore} from './LoadMore';
import {useErrorDispatch} from '../../../contexts/errorDispatchContext';
import {ErrorActionType} from '../../../reducers/errorReducer';

interface RouteParams {
  state: {
    refetch: boolean;
  };
}
export const AppointmentsOverviewPage = () => {
  const navigate = useNavigate();
  const {state: routeState} = useLocation() as RouteParams;
  const {state, dispatch} = useCustomerPortalState();
  const {t} = useTranslation();
  const [patientSearchFilterValue, setPatientSearchFilterValue] = useState(
    state.appointment.data.filter?.patientSearchFilterValue || '',
  );
  const [items, setItems] = useState<Appointment[]>([]);
  const [noMoreItems, setNoMoreItems] = useState(false);
  const [servicesError, setServicesError] = useState();
  const {accessToken} = useAcquireApiToken();
  const [page, setPage] = useState(1);
  const errorDispatch = useErrorDispatch();

  const {data, isLoading, error, refetch} = useAppointmentOverview(
    state.appointment.data.filter,
  );

  useEffect(() => {
    if (data && items.length === 0) {
      setItems(data);
      if (data.length < defaultPageSize) {
        setNoMoreItems(true);
      } else {
        setNoMoreItems(false);
      }
    }
  }, [data, items, state.appointment.data.filter?.pageSize]);

  useEffect(() => {
    dispatch({
      type: 'SERVICE_CLEAR',
    });
  }, [dispatch]);

  useEffect(() => {
    if (routeState?.refetch) {
      refetch();
    }
  }, [refetch, routeState]);

  useEffect(() => {
    const err = error || servicesError;
    if (!isLoading && err) {
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error: err,
        },
      });
    }
  }, [isLoading, error, servicesError, errorDispatch]);

  const storeFilter = (value?: AppointmentsOverviewRequest) => {
    dispatch({
      type: 'APPOINTMENT_STORE',
      payload: {
        filter: {
          ...state.appointment.data.filter,
          ...value,
          patientSearchFilterValue:
            patientSearchFilterValue.length > 0
              ? patientSearchFilterValue
              : undefined,
          page: 1,
        },
      },
    });
  };
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    setPage(1);
    setItems([]);
    storeFilter();
    e.preventDefault();
  };

  const onChangeMonth = (value: string) => {
    setPage(1);
    setItems([]);
    let startDate;
    if (value.length > 0) {
      if (dayjs().isAfter(value)) {
        startDate = dayjs();
      } else {
        startDate = dayjs(value, 'MM-DD-YYYY');
      }
    }

    storeFilter({
      startDate: startDate?.format('YYYY-MM-DD'),
    });
  };

  const onChangePlandefinition = (value: string) => {
    setPage(1);
    setItems([]);
    storeFilter({
      planDefinitionId: value.length > 0 ? value : undefined,
    });
  };

  const onChangePatient = (value: string) => {
    setPatientSearchFilterValue(value);
  };

  const onClick = (appointment: Appointment) => {
    dispatch({
      type: 'APPOINTMENT_STORE',
      payload: {
        id: appointment.appointmentId,
        setId: appointment.appointmentSetId,
      },
    });

    if (appointment.appointmentId) {
      navigate(AppointmentDetailsRoute + '?id=' + appointment.appointmentId);
    } else if (appointment.appointmentSetId) {
      navigate(
        AppointmentDetailsRoute + '?setId=' + appointment.appointmentSetId,
      );
    }
  };

  const onLoadMore = async () => {
    if (data) {
      const newPage = page + 1;
      setPage(newPage);
      const newItems = await getAppointmentOverview(accessToken, {
        ...state.appointment.data.filter,
        page: newPage,
      });
      if (newItems && newItems.length > 0) {
        if (newItems.length < defaultPageSize) {
          setNoMoreItems(true);
        }

        setItems([...items, ...newItems]);
      } else {
        setNoMoreItems(true);
      }
    }
  };

  return (
    <Container maxW="container.xl">
      <form onSubmit={onSubmit}>
        <Box>
          <Heading me="3">{t('appointments.edit_appointment')} </Heading>
          <Filters
            month={state.appointment.data.filter?.startDate || ''}
            patient={patientSearchFilterValue}
            service={state.appointment.data.filter?.planDefinitionId || ''}
            onChangeMonth={onChangeMonth}
            onChangePlandefinition={onChangePlandefinition}
            onChangePatient={onChangePatient}
            onError={(e?: any) => setServicesError(e)}
            onInputSubmit={onSubmit}
          />
        </Box>
      </form>
      {isLoading && <Loader />}
      {!isLoading && (
        <Box height={530} overflowY="auto" borderBottom="1px solid #ddd">
          {items.length === 0 && (
            <TextWithLineBreak keyToTranslate="service.no_results" />
          )}
          {items.map((appointment) => (
            <ListItem
              key={appointment.appointmentId || appointment.appointmentSetId}
              onClick={() => onClick(appointment)}
              appointment={appointment}
            />
          ))}
          {!noMoreItems && <LoadMore onLoadMore={onLoadMore} />}
        </Box>
      )}
    </Container>
  );
};
