import {Box} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {AppointmentTimeslotSelectorView} from './../../components/Service/AppointmentNew/AppointmentTimeslotSelectorView';
import {useTranslation} from 'react-i18next';
import {
  addMonthAndSetFirstDate,
  addMonthAndSetLastDate,
} from '../../utils/timeFormatting';
import dayjs from 'dayjs';
import {TimeslotListSection} from '../../lib/api/types/Services';
import {
  getTrainingDataByhash,
  useGetTrainingDataByHash,
} from '../../api/trainingData';
import {useParams} from 'react-router-dom';
import {transformTimeslotItemsToSection} from '../../components/Service/AppointmentNew/AppointmentTimeslotSelectorController';

export const TrainingData = () => {
  const initialStartDate = dayjs().format('YYYY-MM-DD').toString();
  const maxMonth = addMonthAndSetFirstDate(dayjs(initialStartDate), 12);
  const initialEndDate = dayjs()
    .add(2, 'month')
    .endOf('month')
    .format('YYYY-MM-DD')
    .toString();
  const {hash} = useParams();
  const {t} = useTranslation();
  const {i18n} = useTranslation();
  const [showCapacity, setShowCapacity] = useState(false);
  const [hideShowMore, setHideShowMore] = useState(false);
  const [timeslotSections, setTimeslotSections] = useState<
    TimeslotListSection[]
  >([]);
  const [nextMonth, setNextMonth] = useState(
    dayjs(initialEndDate).add(1, 'month').toString(),
  );
  const [selectedMonth, setSelectedMonth] = useState(initialStartDate);

  const {data, isLoading} = useGetTrainingDataByHash(
    i18n.resolvedLanguage,
    hash,
    {startDate: initialStartDate, endDate: initialEndDate},
  );

  useEffect(() => {
    if (data) {
      const currentDate = dayjs().format('YYYY-MM-DD');
      const sections: TimeslotListSection[] = [];

      for (let i = 0; i < 3; i++) {
        const date = dayjs(currentDate).add(i, 'month').toString();
        const section = transformTimeslotItemsToSection(data, date);
        sections.push(...section);
      }
      setTimeslotSections(sections);
      if (data.length > 0) {
        setShowCapacity(data[0][0].showAvailableCapacity);
      }
    }
  }, [data]);

  const onLoadMore = async () => {
    const nextM = addMonthAndSetFirstDate(dayjs(nextMonth), 1);
    const startDate = addMonthAndSetFirstDate(dayjs(nextMonth), 0);
    const endDate = addMonthAndSetLastDate(dayjs(nextMonth), 0);

    const newItems = await getTrainingDataByhash(i18n.resolvedLanguage, hash, {
      startDate,
      endDate,
    });
    const newSlots = transformTimeslotItemsToSection(newItems, startDate);
    setSelectedMonth(nextMonth);
    setNextMonth(nextM);
    setHideShowMore(nextM === maxMonth);

    setTimeslotSections([...timeslotSections, ...newSlots]);
  };

  const onChangeMonth = async (value: string) => {
    let currDate = dayjs();

    if (value.length > 0) {
      if (dayjs().isBefore(value)) {
        currDate = dayjs(value);
      }
    }
    const startDate = currDate.format('YYYY-MM-DD');
    const endDate = dayjs(currDate).endOf('month').format('YYYY-MM-DD');
    const nextM = addMonthAndSetFirstDate(currDate, 1);

    const newItems = await getTrainingDataByhash(i18n.resolvedLanguage, hash, {
      startDate,
      endDate,
    });
    const newSlots = transformTimeslotItemsToSection(newItems, startDate);
    setTimeslotSections(newSlots);
    setNextMonth(nextM);
    setSelectedMonth(startDate);
    setHideShowMore(nextM === maxMonth);
  };
  return (
    <Box>
      <AppointmentTimeslotSelectorView
        timeslotSections={timeslotSections || []}
        isLoading={isLoading}
        showCapacity={showCapacity}
        onChangeMonth={onChangeMonth}
        selectedMonth={selectedMonth}
        onLoadMore={onLoadMore}
        hideMoreItems={hideShowMore}
        hideCopyAndMailButtons={true}
        title={t('service.title.cursist')}
      />
    </Box>
  );
};
