import {Box, Flex} from '@chakra-ui/react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppointmentSet} from '../../../api/getAppointmentSet';
import {AppointmentSetView} from '../../../components/Appointments/AppointmentSetPicker/AppointmentSetView';
import {AppointmentSet} from '../../../lib/api/types/AppointmentSet';
import {DetailHeader} from './DetailsHeader';

export interface AppointmentSetDetailsProps {
  id: string;
  setIsLoading: (value: boolean) => void;
  onError: (e: Error | null) => void;
  onAppointmentResult: (set: AppointmentSet) => void;
}

export const AppointmentSetDetails = ({
  id,
  setIsLoading,
  onError,
  onAppointmentResult,
}: AppointmentSetDetailsProps) => {
  const {i18n} = useTranslation();
  const {data, isLoading, isFetching, error} = useAppointmentSet(
    id,
    i18n.resolvedLanguage,
  );

  useEffect(() => {
    if (data) {
      onAppointmentResult(data);
    }
  }, [data, onAppointmentResult]);

  useEffect(() => {
    setIsLoading(isLoading || isFetching);
  }, [setIsLoading, isLoading, isFetching]);

  useEffect(() => {
    onError(error);
  }, [error, onError]);

  if (!data) {
    return null;
  }

  return (
    <Box>
      <DetailHeader
        planDefinitionName={data.planDefinitionName}
        patientName={data.patientName}
      />
      <Flex mt="20">
        <AppointmentSetView
          selectable={false}
          timeslotSet={data.appointments.map((appointment) => {
            return {
              activityTitle: appointment.appointmentTitle,
              startTime: appointment.startTime,
              endTime: appointment.endTime,
              practitionerName: appointment.requester,
              address: appointment.address,
            };
          })}
        />
      </Flex>
    </Box>
  );
};
