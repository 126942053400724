import {Link as ReactRouterLink} from 'react-router-dom';
import {RouteLinkProps} from '../../RouteLinkProps';
import {Icon, Button, Link} from '@chakra-ui/react';
import {ChevronRightIcon} from '@chakra-ui/icons';

export const DashboardButton = ({icon, path, text}: RouteLinkProps) => {
  return (
    <Link as={ReactRouterLink} to={path}>
      <Button display="flex" justifyContent="space-between" w="100%">
        <Icon data-testid="icon" color="white" as={icon} w={6} h={6} />
        {text}
        <ChevronRightIcon color="white" w={8} h={8} />
      </Button>
    </Link>
  );
};
