import {AppointmentsOverviewRequest} from '../lib/api/types/Requests';
import {
  AppointmentPages,
  CustomerPortalAppointmentState,
  defaultAppointmentState,
} from '../lib/api/types/State';

export type AppointmentDataPayLoad = {
  filter?: AppointmentsOverviewRequest;
  id?: string;
  setId?: string;
};

type Payload = {
  page: AppointmentPages;
};

export type State = {
  page: AppointmentPages;
  data: AppointmentDataPayLoad;
};

type StoreData = {
  type: 'APPOINTMENT_STORE';
  payload: AppointmentDataPayLoad;
};

type NextStep = {
  type: 'APPOINTMENT_GOTO';
  payload: Payload;
};

type Clear = {
  type: 'APPOINTMENT_CLEAR';
  payload?: undefined;
};

export type Action = NextStep | StoreData | Clear;

export const reducer = (
  state: CustomerPortalAppointmentState = defaultAppointmentState,
  action: Action,
) => {
  const {type} = action;
  switch (type) {
    case 'APPOINTMENT_GOTO':
      const {payload: nextStepPayload} = action as NextStep;
      return {
        ...state,
        page: nextStepPayload.page,
      };
    case 'APPOINTMENT_STORE':
      const {payload: storePayload} = action as StoreData;
      return {
        ...state,
        data: {
          ...state.data,
          ...storePayload,
        },
      };
    case 'APPOINTMENT_CLEAR':
      return {
        ...state,
        data: {
          filter: {
            startDate: undefined,
            patient: undefined,
            planDefinitionId: undefined,
            pageSize: undefined,
            page: undefined,
          },
        },
      };
    default:
      return state;
  }
};
