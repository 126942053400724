import {Box, Flex, Grid, GridItem, Text} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {ArrowRightCircle, Check} from 'react-feather';
import {AppointmentTimeslot} from '../../../lib/api/types/Services';
import {colors} from '../../../theme/colors';

interface Props {
  timeslotListItems: AppointmentTimeslot[];
  selected: boolean;
  onClick?: () => void;
}

export const TimeslotListItemView = ({
  timeslotListItems,
  selected,
  onClick,
}: Props) => {
  const renderTimeslotSpecificData = (
    timeslotListItem: AppointmentTimeslot,
    index: number,
  ) => {
    return (
      <Grid
        templateColumns="0.75fr 0.75fr 1.5fr"
        cursor={onClick ? 'pointer' : 'default'}
        lineHeight="20px"
        key={timeslotListItem.resourceAvailabilityId + index}
      >
        <GridItem>
          <Flex
            key={timeslotListItem.startTime + index}
            flexDirection="column"
            p={2}
            pb={0}
          >
            <Box>
              <Text
                as="span"
                display="block"
                fontWeight={index === 0 ? 'bold' : ''}
              >
                {dayjs(timeslotListItem.startTime).format('DD MMM YYYY')}
              </Text>
            </Box>
            {timeslotListItems.length > 1 && (
              <Box>
                <Text as="span" display="block" fontSize="medium">
                  {timeslotListItem.activityTitle}
                </Text>
              </Box>
            )}
          </Flex>
        </GridItem>
        <GridItem>
          <Flex key={timeslotListItem.startTime + index} p={2} pb={0}>
            <Text
              as="span"
              display="inline-block"
              fontWeight={index === 0 ? 'bold' : ''}
            >
              {dayjs(timeslotListItem.startTime).format('HH:mm')}
            </Text>
            {'-'}
            <Text
              as="span"
              display="inline-block"
              fontWeight={index === 0 ? 'bold' : ''}
            >
              {dayjs(timeslotListItem.endTime).format('HH:mm')}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Text
            key={timeslotListItem.address!! + index}
            as="span"
            display="block"
            p={2}
            pb={0}
            fontWeight={index === 0 ? 'bold' : ''}
          >
            {timeslotListItem.address}
          </Text>
        </GridItem>
      </Grid>
    );
  };

  return (
    <Grid
      templateColumns="0.75fr 0.20fr 0.05fr"
      gap={6}
      cursor={onClick ? 'pointer' : 'default'}
      lineHeight="20px"
      onClick={onClick ? () => onClick() : undefined}
      mb={2}
    >
      <GridItem>
        {timeslotListItems.map((timeslotListItem, index) => {
          return renderTimeslotSpecificData(timeslotListItem, index);
        })}
      </GridItem>
      {timeslotListItems[0].showAvailableCapacity && (
        <GridItem>
          <Flex alignItems="center" height="100%">
            <Text as="span" display="inline-block">
              {timeslotListItems[0].availableCapacity}
            </Text>
          </Flex>
        </GridItem>
      )}
      <GridItem>
        <Flex justifyContent="right" alignItems="center" height="100%">
          {onClick && !selected && (
            <ArrowRightCircle color={colors.listIconColor} />
          )}
          {onClick && selected && <Check color="white" />}
        </Flex>
      </GridItem>
    </Grid>
  );
};
