import {
  Stack,
  Box,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import {Menu} from './ChakraNavigationBar';
import {DesktopSubNav} from './DesktopSubNav';

export interface MenuProps {
  menuOne?: Menu;
  menuTwo?: Menu;
}
export const DesktopNav = ({menuOne, menuTwo}: MenuProps) => {
  return (
    <Stack direction={'row'} spacing={4}>
      {menuOne && (
        <Box>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                _hover={{
                  textDecoration: 'none',
                }}
              >
                {menuOne.label}
              </Link>
            </PopoverTrigger>
            <PopoverContent
              border={0}
              boxShadow={'xl'}
              p={4}
              rounded={'xl'}
              minW={'sm'}
            >
              <Stack>
                {menuOne.items.map((link, index) => (
                  <DesktopSubNav key={index} {...link} />
                ))}
              </Stack>
            </PopoverContent>
          </Popover>
        </Box>
      )}
      {menuTwo && (
        <Box>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                _hover={{
                  textDecoration: 'none',
                }}
              >
                {menuTwo.label}
              </Link>
            </PopoverTrigger>
            <PopoverContent
              border={0}
              boxShadow={'xl'}
              p={4}
              rounded={'xl'}
              minW={'sm'}
            >
              <Stack>
                {menuTwo.items.map((link, index) => (
                  <DesktopSubNav key={index} {...link} />
                ))}
              </Stack>
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </Stack>
  );
};
