import React from 'react';
import {PageHeader} from '../components/Common/PageHeader/PageHeader';
import {Box, Container} from '@chakra-ui/react';

export interface PageProps {
  header: string;
  children: React.ReactNode;
  cyTag?: string;
}

export const Page = (props: PageProps) => {
  return (
    <Container maxW="container.xl" display="flex" flexDirection="column">
      <PageHeader text={props.header} />
      <Box mt={4} data-cy={props.cyTag}>
        {props.children}
      </Box>
    </Container>
  );
};
