import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {useEffect, useState} from 'react';

interface Props {
  scopes: string[];
}
export const useAcquireToken = ({scopes}: Props) => {
  const {instance} = useMsal();
  const [accessToken, setAccessToken] = useState<string>();
  const [isLoading, setIsloading] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  const responseHandler = (response: AuthenticationResult) => {
    if (response) {
      setAccessToken(response.accessToken);
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (!accessToken && isAuthenticated) {
      setIsloading(true);
      instance
        .acquireTokenSilent({
          account: instance.getActiveAccount() as AccountInfo,
          scopes,
        })
        .then(responseHandler)
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              scopes,
              account: instance.getActiveAccount() as AccountInfo,
            });
            setIsloading(false);
          }
        });
    }
  }, [accessToken, instance, scopes, isAuthenticated]);

  return {
    accessToken,
    isLoading,
  };
};
