import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {fapper, FapperError} from '../utils/fapper';

const renewableCertificate = async (
  accessToken?: string,
  observationId?: string,
): Promise<string> => {
  const responseData = await fapper.patch(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/certificates/${observationId}/renewable`,
    {
      renewable: false,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const useRenewableCertificate = (
  observationId?: string,
  options: UseQueryOptions<
    string | undefined,
    FapperError,
    string | undefined,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 1,
  },
) => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<string | undefined, FapperError>(
    ['RenewableCertificate', observationId, accessToken],
    () => renewableCertificate(accessToken, observationId),
    {
      ...options,
      enabled: !!(observationId && accessToken),
    },
  );
};

export {useRenewableCertificate};
