import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {ContentRequest} from '../lib/api/types/Content';
import {fapper, FapperError} from '../utils/fapper';

const getAppointmentCancelConditions = async <T>(
  data: ContentRequest,
): Promise<T> => {
  const isPreview = process.env.REACT_APP_ENVIRONMENT !== 'production';
  const responseData = await fapper.get(
    `${process.env.REACT_APP_UMBRACO_API_URL}/v1/content/item?culture=${
      data.language
    }&tags=${data.tags.join(';')}&type=${data.type}&preview=${isPreview}`,
  );
  return responseData.contentItems as T;
};

const useContent = (
  data: ContentRequest,
  options: UseQueryOptions<any, FapperError, any, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  return useQuery<any, FapperError>(
    ['CancelConditions', data],
    () => getAppointmentCancelConditions(data),
    options,
  );
};

export {useContent, getAppointmentCancelConditions};
