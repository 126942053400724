import {CancelAppointmentResponse} from '../lib/api/types/AppointmentsOverview';
import {fapper} from '../utils/fapper';

const cancelAppointment = async (
  accessToken: string,
  id: string,
  isSet: boolean = false,
): Promise<CancelAppointmentResponse> => {
  const responseData: CancelAppointmentResponse = await fapper.patch(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/${
      isSet ? 'appointmentSets' : 'appointments'
    }/${id}/cancel`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

export {cancelAppointment};
