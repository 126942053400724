import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {Service} from '../lib/api/types/Services';
import {fapper, FapperError} from '../utils/fapper';

const sendGetServices = async (
  patientId: string,
  accessToken?: string,
): Promise<Service[]> => {
  const responseData: Service[] = await fapper.get(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/patients/${patientId}/services`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const sendGetServicesForMultiplePatients = async (
  patientIds: string[],
  accessToken: string,
) => {
  const responseData: Service[] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/patients/servicesformultiplepatients`,
    {onBehalfOfPatientIds: patientIds},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const getServices = async (
  patientIds: string[],
  isMultipleStudentsSelected: boolean,
  isInValidMultipleStudentSelection: boolean,
  accessToken?: string,
) => {
  if (
    (patientIds.length < 2 && isMultipleStudentsSelected) ||
    isInValidMultipleStudentSelection ||
    !accessToken
  ) {
    const result: Service[] = [];
    return new Promise<Service[]>((resolve) => resolve(result));
  }
  if (patientIds.length > 1 && isMultipleStudentsSelected) {
    return sendGetServicesForMultiplePatients(patientIds, accessToken);
  }
  return sendGetServices(patientIds[0], accessToken);
};

const useServices = (
  patientIds: string[],
  isMultipleStudentsSelected: boolean,
  isInValidMultipleStudentSelection: boolean,
  options: UseQueryOptions<Service[], FapperError, Service[], QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  const {accessToken} = useAcquireApiToken();

  return useQuery<Service[], FapperError>(
    [
      'Services',
      patientIds,
      isMultipleStudentsSelected,
      isInValidMultipleStudentSelection,
      accessToken,
    ],
    () =>
      getServices(
        patientIds,
        isMultipleStudentsSelected,
        isInValidMultipleStudentSelection,
        accessToken,
      ),
    options,
  );
};

export {useServices, getServices};
