import {SystemStyleObject} from '@chakra-ui/react';

const baseStyleButton: SystemStyleObject = {
  fontSize: '1.125rem',
  lineHeight: 'base',
};

const baseStylePanel: SystemStyleObject = {
  pt: 2,
  px: 4,
  pb: '0.5px',
};

const baseStyle = {
  button: baseStyleButton,
  panel: baseStylePanel,
};

export const Accordion = {
  baseStyle,
};
