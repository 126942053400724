import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {Student} from '../lib/api/types/Student';
import {fapper, FapperError} from '../utils/fapper';

const sendDecoupleStudent = async (
  accessToken: string,
  student: Student,
): Promise<string> => {
  const responseData = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/patients/decouple`,
    student,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const decoupleStudent = async (accessToken?: string, student?: Student) => {
  if (!accessToken || !student) {
    return new Promise<undefined>((resolve) => resolve(undefined));
  }
  return sendDecoupleStudent(accessToken, student);
};

const useDecoupleStudent = (
  student?: Student,
  executeDecoupleStudent?: boolean,
  options: UseQueryOptions<
    string | undefined,
    FapperError,
    string | undefined,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 1,
  },
) => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<string | undefined, FapperError>(
    ['DecoupleStudent', student, accessToken],
    () => decoupleStudent(accessToken, student),
    {
      ...options,
      enabled: student && accessToken !== undefined && executeDecoupleStudent,
    },
  );
};

export {useDecoupleStudent, decoupleStudent};
