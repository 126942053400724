import {RefetchOptions} from 'react-query';
import {FapperError} from '../utils/fapper';

export interface ErrorState {
  errors: ErrorKHS[];
}

export const initialErrorState: ErrorState = {
  errors: [],
};

export enum ErrorActionType {
  ErrorActionSet,
  ErrorActionRemove,
  ErrorActionRemoveAll,
}
export type SingleError = {statusText?: string};
export type ErrorId = {id: number};

export type ErrorProps = {
  error?: SingleError | FapperError | null | undefined;
  refetch?: (options?: RefetchOptions | undefined) => Promise<any>;
};
export type ErrorKHS = ErrorProps & ErrorId;
export type AddError = ErrorProps;
export type RemoveError = {
  id: number;
};
export type AddErrorPayload = {
  type: ErrorActionType.ErrorActionSet;
  payload?: AddError;
};
export type RemoveErrorPayload = {
  type: ErrorActionType.ErrorActionRemove;
  payload: RemoveError;
};
export type RemoveAllErrorsPayload = {
  type: ErrorActionType.ErrorActionRemoveAll;
};

export type Action =
  | AddErrorPayload
  | RemoveErrorPayload
  | RemoveAllErrorsPayload;

export const errorReducer = (state: ErrorState, action: Action) => {
  const {type} = action;
  const {errors} = state;

  switch (type) {
    case ErrorActionType.ErrorActionSet: {
      const {payload} = action as AddErrorPayload;
      const id = errors.length === 0 ? 0 : errors.length;
      const error = {
        id,
        ...payload,
      };

      return {
        ...state,
        errors: [...errors, error as ErrorKHS],
      };
    }
    case ErrorActionType.ErrorActionRemove: {
      const {payload} = action as RemoveErrorPayload;
      const id = payload.id;

      const foundError = errors.find((e) => e.id === id);
      if (foundError) {
        const index = errors.indexOf(foundError);
        const newErrors = errors
          .slice(0, index)
          .concat(errors.slice(index + 1));

        return {
          ...state,
          errors: newErrors,
        };
      }

      return {
        ...state,
      };
    }
    case ErrorActionType.ErrorActionRemoveAll: {
      return {
        ...state,
        errors: [],
      };
    }
    default:
      return state;
  }
};
