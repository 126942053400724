import {Page} from './Page';

interface Props {
  children?: any;
}

export const UnauthorizedPage = ({children}: Props) => {
  const header = 'U heeft geen toegang tot de gevraagde pagina.';

  return <Page header={header}>{children}</Page>;
};
