import {useState, useEffect} from 'react';
import {Appointment} from '../../../lib/api/types/Appointment';
import {AppointmentSet} from '../../../lib/api/types/AppointmentSet';

export const useButtonStatus = (
  appointment?: Appointment,
  appointmentSet?: AppointmentSet,
  isLoadingCancel: boolean = false,
  isLoadingDurableFunction: boolean = false,
) => {
  const [
    isFreeOfChargeCancellationDateExceeded,
    setIsFreeOfChargeCancellationDateExceeded,
  ] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (isLoadingCancel || isLoadingDurableFunction) {
      setIsDisabled(true);
    } else {
      if (appointment) {
        setIsDisabled(appointment.isProcessing);
      }
      if (appointmentSet) {
        setIsDisabled(appointmentSet.isProcessing);
      }
    }
  }, [appointment, appointmentSet, isLoadingCancel, isLoadingDurableFunction]);

  useEffect(() => {
    if (appointment) {
      setIsFreeOfChargeCancellationDateExceeded(
        appointment.isFreeOfChargeCancellationDateExceeded,
      );
    } else if (appointmentSet) {
      setIsFreeOfChargeCancellationDateExceeded(
        appointmentSet.isFreeOfChargeCancellationDateExceeded,
      );
    }
  }, [appointment, appointmentSet]);

  useEffect(() => {
    if (appointment) {
      setIsStarted(appointment.isStarted);
    } else if (appointmentSet) {
      setIsStarted(appointmentSet.isStarted);
    }
  }, [appointment, appointmentSet]);

  useEffect(() => {
    if (appointment) {
      setIsProcessing(appointment.isProcessing);
    } else if (appointmentSet) {
      setIsProcessing(appointmentSet.isProcessing);
    }
  }, [appointment, appointmentSet]);

  return {
    isFreeOfChargeCancellationDateExceeded,
    isDisabled,
    isStarted,
    isProcessing,
  };
};
