import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {AppointmentSet} from '../lib/api/types/AppointmentSet';
import {AdditionalLocationInfo} from '../lib/api/types/Services';
import {fapper, FapperError} from '../utils/fapper';
import {getAdditionalLocationInfo} from './getAdditionalLocationInfo';

const sendAppointmentSet = async (
  setId: string,
  language: string,
  accessToken: string,
): Promise<AppointmentSet> => {
  const responseData: AppointmentSet = await fapper.get(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/appointmentsets/${setId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  const additionalInfoCalls: Promise<void>[] = [];
  responseData.appointments.forEach((appointment) => {
    additionalInfoCalls.push(
      getAdditionalLocationInfo({
        tag: appointment.location,
        language,
      })
        .then((locationData: AdditionalLocationInfo) => {
          if (locationData) {
            appointment.address = locationData.address;
          }
        })
        .catch(() => Promise.resolve()),
    );
  });
  await Promise.all(additionalInfoCalls);

  return responseData;
};

const getAppointmentSet = async (
  setId: string | null,
  language: string,
  accessToken?: string,
) => {
  if (!setId || !language || !accessToken) {
    return new Promise<AppointmentSet | undefined>((resolve) =>
      resolve(undefined),
    );
  }
  return sendAppointmentSet(setId, language, accessToken);
};

const useAppointmentSet = (
  setId: string | null,
  language: string,
  options: UseQueryOptions<
    AppointmentSet | undefined,
    FapperError,
    AppointmentSet | undefined,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<AppointmentSet | undefined, FapperError>(
    ['AppointmentSet', setId, accessToken, language],
    () => getAppointmentSet(setId, language, accessToken),
    options,
  );
};

export {useAppointmentSet, getAppointmentSet};
