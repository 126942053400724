import {Box, useRadio} from '@chakra-ui/react';
import {ReactNode} from 'react';

export interface Props {
  children: ReactNode;
  size?: 'small' | 'normal';
}
export const RadioCard = ({children, size = 'normal', ...rest}: Props) => {
  const {getInputProps, getCheckboxProps} = useRadio(rest);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="base"
        height="100%"
        bg="white"
        _checked={{
          bg: 'primaryBackground',
          color: 'white',
          borderColor: 'primaryBackground',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={size === 'normal' ? 5 : 2}
        py={size === 'normal' ? 3 : 1}
      >
        {children}
      </Box>
    </Box>
  );
};
