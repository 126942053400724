import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {UserProfile} from '../lib/api/types/User';
import {fapper, FapperError} from '../utils/fapper';

const sendUserProfile = async (accessToken: string): Promise<UserProfile> => {
  const responseData: UserProfile = await fapper.get(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/user/profile`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const getUserProfile = async (accessToken?: string) => {
  if (!accessToken) {
    return new Promise<UserProfile | undefined>((resolve) =>
      resolve(undefined),
    );
  }
  return sendUserProfile(accessToken);
};

const useUserProfile = (
  options: UseQueryOptions<
    UserProfile | undefined,
    FapperError,
    UserProfile | undefined,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<UserProfile | undefined, FapperError>(
    ['UserProfile', accessToken],
    () => getUserProfile(accessToken),
    options,
  );
};

export {useUserProfile, getUserProfile};
