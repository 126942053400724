import {Dispatch, SetStateAction, useState} from 'react';
import {useCustomerPortalState} from '../../contexts/customerPortalProvider';
import {
  HasSucceedingTrainingDateFilter,
  CertificatesFilter,
} from '../../lib/api/types/Certificates';
import {FilteredService} from '../../lib/api/types/Services';
import {FilterView} from './FilterView';

interface Props {
  planDefinitionFilters: FilteredService[];
  hasSucceedingTrainingDateFilters: HasSucceedingTrainingDateFilter[];
  filter: CertificatesFilter;
  setFilter: Dispatch<SetStateAction<CertificatesFilter>>;
}

export const FilterController = ({
  planDefinitionFilters,
  hasSucceedingTrainingDateFilters,
  filter,
  setFilter,
}: Props) => {
  const {state} = useCustomerPortalState();
  const [firstname, setFirstname] = useState<string | undefined>(
    state.certificates.data.filter?.firstname,
  );
  const [lastname, setLastname] = useState<string | undefined>(
    state.certificates.data.filter?.lastname,
  );

  const onChangeFirstnameEvent = (value: string) => {
    setFirstname(value);
  };

  const onChangeLastnameEvent = (value: string) => {
    setLastname(value);
  };

  const onChangePlanDefinitionEvent = (value: string) => {
    setFilter({
      ...filter,
      plandefinitionId: value,
    });
  };

  const onChangeExpireDateEvent = (value: string) => {
    setFilter({
      ...filter,
      expireDate: value,
      expireRange: undefined,
    });
  };

  const onChangeExpireRangeEvent = (value: string) => {
    setFilter({
      ...filter,
      expireDate: undefined,
      expireRange: value,
    });
  };

  const onChangeHasSucceedingTrainingDateEvent = (value: string) => {
    let booleanValue: boolean | undefined = undefined;
    if (value !== undefined && value !== '') {
      if (value === 'true') {
        booleanValue = true;
      } else {
        booleanValue = false;
      }
    }

    setFilter({
      ...filter,
      hasSucceedingTrainingDate: booleanValue,
    });
  };

  const onSubmitFilter = () => {
    setFilter({...filter, firstname, lastname});
  };

  return (
    <FilterView
      planDefinitionFilters={planDefinitionFilters}
      hasSucceedingTrainingDateFilters={hasSucceedingTrainingDateFilters}
      firstname={firstname}
      lastname={lastname}
      filter={filter}
      onChangeFirstnameEvent={onChangeFirstnameEvent}
      onChangeLastnameEvent={onChangeLastnameEvent}
      onChangePlanDefinitionEvent={onChangePlanDefinitionEvent}
      onChangeExpireDateEvent={onChangeExpireDateEvent}
      onChangeExpireRangeEvent={onChangeExpireRangeEvent}
      onChangeHasSucceedingTrainingDateEvent={
        onChangeHasSucceedingTrainingDateEvent
      }
      onSubmit={onSubmitFilter}
    />
  );
};
