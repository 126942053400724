import {Box, Flex} from '@chakra-ui/react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppointment} from '../../../api/getAppointment';
import {AppointmentSetView} from '../../../components/Appointments/AppointmentSetPicker/AppointmentSetView';
import {Appointment} from '../../../lib/api/types/Appointment';
import {DetailHeader} from './DetailsHeader';

export interface SingleAppointmentDetailsProps {
  id: string;
  setIsLoading: (value: boolean) => void;
  onError: (e: Error | null) => void;
  onAppointmentResult: (appointment: Appointment) => void;
}

export const SingleAppointmentDetails = ({
  id,
  setIsLoading,
  onError,
  onAppointmentResult,
}: SingleAppointmentDetailsProps) => {
  const {i18n} = useTranslation();
  const {data, isLoading, isFetching, error} = useAppointment(
    id,
    i18n.resolvedLanguage,
  );

  useEffect(() => {
    setIsLoading(isLoading || isFetching);
  }, [setIsLoading, isLoading, isFetching]);

  useEffect(() => {
    if (data) {
      onAppointmentResult(data);
    }
  }, [data, onAppointmentResult]);

  useEffect(() => {
    onError(error);
  }, [error, onError]);

  if (!data) {
    return null;
  }

  return (
    <Box>
      <DetailHeader
        planDefinitionName={data.planDefinitionName}
        patientName={data.patientName}
      />
      <Flex mt="20">
        <AppointmentSetView
          selectable={false}
          timeslotSet={[
            {
              activityTitle: data.title,
              startTime: data.startTime!!,
              endTime: data.endTime!!,
              practitionerName: data.requester,
              address: data.address,
            },
          ]}
        />
      </Flex>
    </Box>
  );
};
