import {
  ClientManagerGroup,
  HRManagerGroup,
  KHSGroup,
  MedischSpecialistGroup,
  PcrGroup,
  TrainingCoordinatorGroup,
} from './constants/AadGroups';
import {PowerBIReports} from './constants/PowerBIReports';
import {
  TimelineRoute,
  RootRoute,
  AppointmentRoute,
  Covid19Route,
  QuestionnaireRoute,
  ResultsRoute,
  ContentRoute,
  FlowRoute,
  CoordinatorRoute,
  AppointmentsOverviewRoute,
  AppointmentDetailsRoute,
  CertificatesRoute,
} from './constants/Routes';

export const environment = {
  aad: {
    clientId: process.env.REACT_APP_AAD_APP_REGISTRATION_CLIENT_ID as string,
    authority: process.env
      .REACT_APP_AAD_APP_REGISTRATION_AUTHORITY_URL as string,
  },
  routeAuthorizations: [
    {
      route: RootRoute,
      authorizedGroups: [
        HRManagerGroup,
        ClientManagerGroup,
        MedischSpecialistGroup,
        KHSGroup,
        PcrGroup,
        TrainingCoordinatorGroup,
      ],
    },
    {
      route: TimelineRoute,
      authorizedGroups: [HRManagerGroup, ClientManagerGroup],
    },
    {
      route: AppointmentRoute,
      authorizedGroups: [HRManagerGroup, ClientManagerGroup],
    },
    {
      route: Covid19Route,
      authorizedGroups: [PcrGroup],
    },
    {
      route: QuestionnaireRoute,
      authorizedGroups: [HRManagerGroup, ClientManagerGroup],
    },
    {
      route: ResultsRoute,
      authorizedGroups: [
        HRManagerGroup,
        ClientManagerGroup,
        MedischSpecialistGroup,
        KHSGroup,
      ],
    },
    {
      route: ContentRoute,
      authorizedGroups: [
        HRManagerGroup,
        ClientManagerGroup,
        MedischSpecialistGroup,
        KHSGroup,
      ],
    },
    {
      route: FlowRoute,
      authorizedGroups: [
        HRManagerGroup,
        ClientManagerGroup,
        MedischSpecialistGroup,
        KHSGroup,
      ],
    },
    {
      route: CoordinatorRoute,
      authorizedGroups: [TrainingCoordinatorGroup],
    },
    {
      route: AppointmentsOverviewRoute,
      authorizedGroups: [TrainingCoordinatorGroup],
    },
    {
      route: AppointmentDetailsRoute,
      authorizedGroups: [TrainingCoordinatorGroup],
    },
    {
      route: CertificatesRoute,
      authorizedGroups: [TrainingCoordinatorGroup],
    },
  ],
  powerBi: {
    groupId: process.env.REACT_APP_POWERBI_GROUP_ID as string,
    reports: [
      {
        name: PowerBIReports.Appointment,
        type: 'report',
        mappings: [
          {
            group: HRManagerGroup,
            id: process.env.REACT_APP_POWERBI_APPOINTMENT_REPORT_ID as string,
          },
          {
            group: ClientManagerGroup,
            id: process.env.REACT_APP_POWERBI_APPOINTMENT_REPORT_ID as string,
          },
        ],
      },
      {
        name: PowerBIReports.Covid19,
        type: 'report',
        mappings: [
          {
            group: PcrGroup,
            id: process.env.REACT_APP_POWERBI_COVID19_REPORT_ID as string,
          },
        ],
      },
      {
        name: PowerBIReports.Timeline,
        type: 'report',
        mappings: [
          {
            group: HRManagerGroup,
            id: process.env.REACT_APP_POWERBI_TIMELINE_REPORT_ID as string,
          },
          {
            group: ClientManagerGroup,
            id: process.env.REACT_APP_POWERBI_TIMELINE_REPORT_ID as string,
          },
        ],
      },
      {
        name: PowerBIReports.Questionnaire,
        type: 'report',
        mappings: [
          {
            group: HRManagerGroup,
            id: process.env.REACT_APP_POWERBI_QUESTIONNAIRE_REPORT_ID as string,
          },
          {
            group: ClientManagerGroup,
            id: process.env.REACT_APP_POWERBI_QUESTIONNAIRE_REPORT_ID as string,
          },
        ],
      },
      {
        name: PowerBIReports.Results,
        type: 'report',
        mappings: [
          {
            group: HRManagerGroup,
            id: process.env
              .REACT_APP_POWERBI_MANAGER_RESULTS_REPORT_ID as string,
          },
          {
            group: ClientManagerGroup,
            id: process.env
              .REACT_APP_POWERBI_MANAGER_RESULTS_REPORT_ID as string,
          },
          {
            group: KHSGroup,
            id: process.env
              .REACT_APP_POWERBI_PROFESSIONAL_RESULTS_REPORT_ID as string,
          },
          {
            group: MedischSpecialistGroup,
            id: process.env
              .REACT_APP_POWERBI_PROFESSIONAL_RESULTS_REPORT_ID as string,
          },
        ],
      },
      {
        name: PowerBIReports.Content,
        type: 'report',
        mappings: [
          {
            group: HRManagerGroup,
            id: process.env
              .REACT_APP_POWERBI_MANAGER_CONTENT_REPORT_ID as string,
          },
          {
            group: ClientManagerGroup,
            id: process.env
              .REACT_APP_POWERBI_MANAGER_CONTENT_REPORT_ID as string,
          },
          {
            group: KHSGroup,
            id: process.env
              .REACT_APP_POWERBI_PROFESSIONAL_CONTENT_REPORT_ID as string,
          },
          {
            group: MedischSpecialistGroup,
            id: process.env
              .REACT_APP_POWERBI_PROFESSIONAL_CONTENT_REPORT_ID as string,
          },
        ],
      },
      {
        name: PowerBIReports.Flow,
        type: 'report',
        mappings: [
          {
            group: HRManagerGroup,
            id: process.env.REACT_APP_POWERBI_MANAGER_FLOW_REPORT_ID as string,
          },
          {
            group: ClientManagerGroup,
            id: process.env.REACT_APP_POWERBI_MANAGER_FLOW_REPORT_ID as string,
          },
          {
            group: KHSGroup,
            id: process.env
              .REACT_APP_POWERBI_PROFESSIONAL_FLOW_REPORT_ID as string,
          },
          {
            group: MedischSpecialistGroup,
            id: process.env
              .REACT_APP_POWERBI_PROFESSIONAL_FLOW_REPORT_ID as string,
          },
        ],
      },
      {
        name: PowerBIReports.Coordinator,
        type: 'report',
        mappings: [
          {
            group: TrainingCoordinatorGroup,
            id: process.env.REACT_APP_POWERBI_COORDINATOR_ID as string,
          },
        ],
      },
    ],
  },
};
