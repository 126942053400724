import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {CertificateOverview} from '../lib/api/types/Certificates';
import {CertificatesOverviewFilterRequest} from '../lib/api/types/Requests';
import {fapper, FapperError} from '../utils/fapper';

export const defaultPageSize = 20;

const sendCertificatesOverview = async (
  accessToken: string,
  data: CertificatesOverviewFilterRequest,
): Promise<CertificateOverview[]> => {
  const responseData: CertificateOverview[] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/certificates`,
    {
      ...data,
      minimumExpirationDate: data.minimumExpirationDate
        ? data.minimumExpirationDate
        : undefined,
      maximumExpirationDate: data.maximumExpirationDate
        ? data.maximumExpirationDate
        : undefined,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const getCertificateOverview = async (
  accessToken?: string,
  data?: CertificatesOverviewFilterRequest,
) => {
  if (!accessToken || !data) {
    return new Promise<CertificateOverview[] | null>((resolve) =>
      resolve(null),
    );
  }
  return sendCertificatesOverview(accessToken, data);
};

const useCertificatesOverview = (
  data?: CertificatesOverviewFilterRequest,
  options: UseQueryOptions<
    CertificateOverview[] | null,
    FapperError,
    CertificateOverview[] | null,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<CertificateOverview[] | null, FapperError>(
    ['CertificatesOverview', accessToken, data],
    () => getCertificateOverview(accessToken, data),
    options,
  );
};

export {useCertificatesOverview, getCertificateOverview};
