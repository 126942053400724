import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import {PowerBIReports} from '../../constants/PowerBIReports';
import {PageHeader} from '../../components/Common/PageHeader/PageHeader';
import {useFetchPowerBIReport} from '../../hooks/useFetchPowerBIReport';
import {Box, Container} from '@chakra-ui/react';
import './PowerBIPage.css';
import {useAcquirePowerBIToken} from '../../hooks/useAcquirePowerBIToken';
import {Loader} from '../../components/Common/Loader';

export interface PowerBIPageProps {
  reportName: PowerBIReports;
  showPageNavigation?: boolean;
  title?: string;
}
export const PowerBIPage = ({
  reportName,
  title,
  showPageNavigation = false,
}: PowerBIPageProps) => {
  const {accessToken, isLoading} = useAcquirePowerBIToken();
  const {groupId, type, id} = useFetchPowerBIReport(reportName);

  const url = process.env.REACT_APP_POWERBI_EMBED_URL;

  if (isLoading) {
    return <Loader />;
  }

  if (!accessToken) return null;

  return (
    <Box display="flex" flexDirection="column">
      <Container
        display="flex"
        flexDirection="row"
        maxW="container.xl"
        justifyContent="space-between"
        marginBottom="4"
      >
        {title && <PageHeader text={title} />}
      </Container>
      <Box className="power-bi-wrapper">
        <PowerBIEmbed
          embedConfig={{
            type: type,
            id: id,
            groupId: groupId,
            embedUrl: url,
            accessToken: accessToken,
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {visible: false},
                pageNavigation: {visible: showPageNavigation},
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
