import dayjs from 'dayjs';
import {defaultPageSize} from '../../../api/getServiceTimeslotSets';
import {AppointmentTimeslot} from '../types/Services';

const createMockTimeSlot = (
  index: number,
  singleAppointment: boolean,
  startDate?: string,
): AppointmentTimeslot[] => {
  const addDays = index * 10;
  const startTime1 = dayjs(startDate).add(addDays, 'day').toISOString();
  const endTime1 = dayjs(startDate)
    .add(addDays, 'day')
    .add(8, 'hour')
    .toISOString();
  const startTime2 = dayjs(startDate)
    .add(addDays + 7, 'day')
    .toISOString();
  const endTime2 = dayjs(startDate)
    .add(addDays + 7, 'day')
    .add(8, 'hour')
    .toISOString();
  if (singleAppointment) {
    return [
      {
        locationId: '28a913c1-fe41-ec11-8c62-000d3aaf0a4f',
        resourceAvailabilityId: '6fd81444-649b-eb11-b1ac-000d3ab191f3',
        activityDefinitionId: 'f53c6832-878c-eb11-b1ac-002248995deb',
        activityTitle: 'EHBO2 2',
        practitionerName: 'John Doe',
        practitionerRoles: ['Trainer'],
        startTime: startTime1,
        endTime: endTime1,
        availableCapacity: 6,
        showAvailableCapacity: true,
      },
    ];
  } else {
    return [
      {
        locationId: '28a913c1-fe41-ec11-8c62-000d3aaf0a4f',
        resourceAvailabilityId: '6fd81444-649b-eb11-b1ac-000d3ab191f3',
        activityDefinitionId: 'f53c6832-878c-eb11-b1ac-002248995deb',
        activityTitle: 'EHBO2 2',
        practitionerName: 'John Doe',
        practitionerRoles: ['Trainer'],
        startTime: startTime1,
        endTime: endTime1,
        availableCapacity: 6,
        showAvailableCapacity: true,
      },
      {
        locationId: '28a913c1-fe41-ec11-8c62-000d3aaf0a4f',
        resourceAvailabilityId: '6fd81444-649b-eb11-b1ac-000d3ab191f4',
        activityDefinitionId: 'f53c6832-878c-eb11-b1ac-002248995deb',
        activityTitle: 'EHBO2 2.1',
        practitionerName: 'John Doe',
        practitionerRoles: ['Trainer'],
        startTime: startTime2,
        endTime: endTime2,
        availableCapacity: 6,
        showAvailableCapacity: true,
      },
    ];
  }
};

const createMockTimeSlots = (
  singleAppointment: boolean,
  startDate?: string,
): AppointmentTimeslot[][] => {
  const result: AppointmentTimeslot[][] = [];
  let i = startDate ? defaultPageSize - 1 : 0;
  while (i < defaultPageSize) {
    result.push(createMockTimeSlot(i, singleAppointment, startDate));
    i++;
  }
  return result;
};

export const mockTimeslots = createMockTimeSlots(true);
export const mockTimeslotsSet = createMockTimeSlots(false);
export const getMockTimeslotLoadMore = (
  singleAppointment: boolean,
  startDate: string,
): AppointmentTimeslot[][] => {
  return createMockTimeSlots(singleAppointment, startDate);
};
