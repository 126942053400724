import {SearchIcon} from '@chakra-ui/icons';
import {Box, Input} from '@chakra-ui/react';
import {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  onChangePatient: (value: string) => void;
  patient: string;
  onSubmit: any;
}

export const PatientInput = ({onChangePatient, patient, onSubmit}: Props) => {
  const {t} = useTranslation();

  return (
    <Box p="2">
      <Box mb={2}>{t('service.person')}</Box>
      <Box position="relative">
        <Input
          placeholder={t('service.filter.employee')}
          value={patient}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangePatient(e.target.value)
          }
        />
        <Box
          position="absolute"
          width={10}
          height={10}
          sx={{
            top: '2px',
            right: 0,
            textAlign: 'center',
            cursor: 'pointer',
            zIndex: 1,
          }}
          onClick={onSubmit}
        >
          <SearchIcon />
        </Box>
      </Box>
    </Box>
  );
};
