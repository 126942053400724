import dayjs from 'dayjs';

export const determineExprirationLabel = (date: string) => {
  const diff = dayjs(date).diff(dayjs(), 'day');

  let bg = '#00D03A';
  let label = '> 3 maanden';
  if (diff < 0) {
    bg = '#FA0B18';
    label = 'verlopen';
  } else if (diff < 31) {
    bg = '#FFD074';
    label = '< 1 maand';
  } else if (diff > 31 && diff < 90) {
    bg = '#FAFF00';
    label = '< 3 maanden';
  }
  return {
    bg,
    label,
  };
};
