import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {AppointmentTimeslotsSelectorRequest} from '../lib/api/types/Requests';
import {AppointmentTimeslot} from '../lib/api/types/Services';
import {fapper, FapperError} from '../utils/fapper';
import {getLocationInfoByTimeslots} from './getLocationInfoByTimeslots';

const getTimeslots = async (
  data: AppointmentTimeslotsSelectorRequest,
): Promise<any> => {
  const timeslots: AppointmentTimeslot[][] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/ext/v4/appointment/timeslots`,
    {
      ...data,
    },
  );

  const timeslotsWithLocations = await getLocationInfoByTimeslots(
    timeslots,
    data.language,
  );
  return timeslotsWithLocations;
};

const useTimeslots = (
  data: AppointmentTimeslotsSelectorRequest,
  options: UseQueryOptions<
    AppointmentTimeslot[][],
    FapperError,
    AppointmentTimeslot[][],
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  return useQuery<AppointmentTimeslot[][], FapperError>(
    ['AppointmentTimeslot', data],
    () => getTimeslots(data),
    options,
  );
};

export {useTimeslots, getTimeslots};
