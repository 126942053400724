import {Box, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

export interface TextWithLineBreakProps {
  keyToTranslate: string;
}
export const TextWithLineBreak = ({keyToTranslate}: TextWithLineBreakProps) => {
  const {t} = useTranslation();
  return (
    <Box textAlign="center">
      <Text
        as="span"
        dangerouslySetInnerHTML={{
          __html: t(keyToTranslate).split('\n').join('<br />'),
        }}
      ></Text>
    </Box>
  );
};
