import dayjs from 'dayjs';
import {Appointment} from '../types/AppointmentsOverview';
const {v4: uuidv4} = require('uuid');

function getDates(index: number, startDate?: string) {
  const result = [];
  if (index % 2 === 1) {
    result.push(
      dayjs(startDate)
        .add(index * 3, 'day')
        .toISOString(),
    );
    result.push(
      dayjs(startDate)
        .add(index * 3 + 1, 'day')
        .toISOString(),
    );
  } else {
    result.push(
      dayjs(startDate)
        .add(index * 3, 'day')
        .toISOString(),
    );
  }
  return result;
}

const createMockAppointment = (index: number, startDate?: string) => {
  const dates = getDates(index, startDate);
  return {
    startDates: dates,
    patientFullName: 'Test Testmans',
    employeeNumber: `KLM0001234${index}`,
    planDefinitionName: index % 2 !== 1 ? 'Ploegleider herhaling' : 'BHV Basis',
    appointmentId: index % 2 === 1 ? undefined : uuidv4(),
    appointmentSetId: index % 2 !== 1 ? undefined : uuidv4(),
    isProcessing: index % 6 === 0 ? true : false,
  };
};

const createMockAppointments = (startDate?: string): Appointment[] => {
  const result: Appointment[] = [];
  var i = startDate ? 15 : 0;
  while (i < 20) {
    result.push(createMockAppointment(i, startDate));
    i++;
  }
  return result;
};

/* Based on Healhy Me app should be checked when api is created */
export const mockAppointmentsOverview: Appointment[] = createMockAppointments();
export const getMockAppointmentsOverviewLoadMore = (
  startDate: string,
): Appointment[] => {
  return createMockAppointments(startDate);
};
