import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {AdditionalLocationInfoRequest} from '../lib/api/types/Requests';
import {AdditionalLocationInfo} from '../lib/api/types/Services';
import {FapperError} from '../utils/fapper';
import {getContent} from './getContent';

const getAdditionalLocationInfo = async (
  data: AdditionalLocationInfoRequest,
): Promise<AdditionalLocationInfo> => {
  const params = {
    language: data.language,
    tags: [data.tag],
    type: 'location',
  };
  const response: AdditionalLocationInfo[] = await getContent(params);
  return response[0];
};

const useAdditionalLocationInfo = (
  data: AdditionalLocationInfoRequest,
  options: UseQueryOptions<
    AdditionalLocationInfo,
    FapperError,
    AdditionalLocationInfo,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  return useQuery<AdditionalLocationInfo, FapperError>(
    ['LocationInfo', data],
    () => getAdditionalLocationInfo(data),
    options,
  );
};

export {useAdditionalLocationInfo, getAdditionalLocationInfo};
