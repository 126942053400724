import {useEffect, useState} from 'react';
import {useAccountGroups} from '../../../hooks/useAccountGroups';
import {ExtendedRoute, getAuthorizedRoutes} from '../../../routesConfiguration';
import {Menu} from './ChakraNavigationBar';
import {useTranslation} from 'react-i18next';

export const useMenu = () => {
  const {t} = useTranslation();
  const groups = useAccountGroups();
  const [employeeLinks, setEmployeeLinks] = useState<Menu>();
  const [myselfLinks, setMyselfLinks] = useState<Menu>();

  useEffect(() => {
    if (groups.length > 0) {
      const routes = getAuthorizedRoutes(groups);
      const tempEmployeeLinks: ExtendedRoute[] = [];
      const tempMyselfLinks: ExtendedRoute[] = [];
      routes.forEach((route) => {
        if (!route.text) {
          return;
        }
        if (route.category) {
          if (route.category === 'employee') {
            tempEmployeeLinks.push(route);
          }
          if (route.category === 'myself') {
            tempMyselfLinks.push(route);
          }
        }
      });

      setEmployeeLinks({
        label: t('for.employee'),
        items: tempEmployeeLinks,
      });
      setMyselfLinks({
        label: t('for.me'),
        items: tempMyselfLinks,
      });
    }
  }, [groups, t]);
  return {
    menuOne: employeeLinks,
    menuTwo: myselfLinks,
  };
};
