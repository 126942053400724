import {Box} from '@chakra-ui/react';
import dayjs, {Dayjs} from 'dayjs';
import {useEffect, useState} from 'react';
import {getServiceLocationsWithDates} from '../../../api/getServiceLocationsWithDates';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {FreeFormatAppointmentView} from './FreeFormatAppointmentView';

export const FreeFormatAppointmenController = () => {
  const {state, dispatch} = useCustomerPortalState();

  const [isLoading, setisLoading] = useState<boolean>(false);
  const [activityDefinitionId, setActivityDefinitionId] = useState<string>();
  const [date, setDate] = useState<Dayjs>();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();

  useEffect(() => {
    const getActivityDefinitionId = async () => {
      if (!isLoading && !activityDefinitionId) {
        setisLoading(true);
        try {
          const result = await getServiceLocationsWithDates({
            accountId: state.service.data.service?.accountId || null,
            plandefinitionId: state.service.data.service?.planDefinitionId!!,
            pageSize: 1,
          });
          if (result.length > 0) {
            setActivityDefinitionId(result[0].activityDefinitionId);
          } else {
            setActivityDefinitionId('');
          }
          setisLoading(false);
        } catch (e: any) {
          setActivityDefinitionId('');
          setisLoading(false);
        }
      }
    };
    getActivityDefinitionId();
  }, [
    activityDefinitionId,
    isLoading,
    state.service.data.service?.accountId,
    state.service.data.service?.planDefinitionId,
  ]);

  useEffect(() => {
    if (startTime && endTime && activityDefinitionId) {
      dispatch({
        type: 'SERVICE_STORE',
        payload: {
          freeFormat: {
            startTime,
            endTime,
            activityDefinitionId: activityDefinitionId,
          },
        },
      });
    }
  }, [startTime, endTime, activityDefinitionId, dispatch]);

  const onChangeDate = (value: string) => {
    setDate(dayjs(value));
  };

  const onChangeStartTime = (value: string) => {
    if (date) {
      const hh = value.slice(0, 2);
      const mm = value.slice(3, 5);
      const newDate = date.hour(Number(hh)).minute(Number(mm));
      setStartTime(newDate.toISOString());
    }
  };

  const onChangeEndTime = (value: string) => {
    if (date) {
      const hh = value.slice(0, 2);
      const mm = value.slice(3, 5);
      const newDate = date.hour(Number(hh)).minute(Number(mm));
      setEndTime(newDate.toISOString());
    }
  };

  return (
    <Box>
      <FreeFormatAppointmentView
        onChangeDate={onChangeDate}
        onChangeStartTime={onChangeStartTime}
        onChangeEndTime={onChangeEndTime}
        timeInputDisabled={date === undefined}
      />
    </Box>
  );
};
