import {Box} from '@chakra-ui/react';
import {CloseIcon, HamburgerIcon} from '@chakra-ui/icons';

export interface Props {
  isOpen: boolean;
  toggle: () => void;
}
export const MenuToggle = ({toggle, isOpen}: Props) => {
  return (
    <Box display={{base: 'inline-block', lg: 'none'}} onClick={toggle}>
      {isOpen ? (
        <CloseIcon width={6} height={6} />
      ) : (
        <HamburgerIcon width={8} height={8} />
      )}
    </Box>
  );
};
