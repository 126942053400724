import {Badge, Box, Text} from '@chakra-ui/react';
import {Student} from '../../../lib/api/types/Student';
import {searchKeyOptions} from './StudentSelectorController';

interface Props {
  student: Student;
  highlightString: string;
  searchKey: searchKeyOptions;
}
export const StudentHighlightedText = ({
  student,
  highlightString,
  searchKey,
}: Props) => {
  const highLightText = (item: Student, highlightText: string) => {
    const original = item[searchKey];

    if (original !== null && highlightString !== '') {
      var remaining = original;
      const parts: string[] = [];
      while (remaining.length) {
        const index = remaining
          .toLowerCase()
          .indexOf(highlightText.toLowerCase());
        if (index === -1) {
          parts.push(remaining);
          remaining = '';
        } else if (index === 0) {
          parts.push(remaining.slice(0, highlightText.length));
          remaining = remaining.slice(highlightText.length);
        } else {
          parts.push(remaining.slice(0, index));
          parts.push(remaining.slice(index, index + highlightText.length));
          remaining = remaining.slice(index + highlightText.length);
        }
      }

      const RenderParts = () => {
        return parts.map((part, index) => {
          if (part.toLowerCase() === highlightText.toLowerCase()) {
            return (
              <Text key={part + index} as="span" fontWeight="extrabold">
                {part}
              </Text>
            );
          } else {
            return (
              <Text as="span" key={part + index}>
                {part}
              </Text>
            );
          }
        });
      };

      return (
        <>
          {searchKey === 'employeeNumber' ? (
            <Badge mr={2}>{RenderParts()}</Badge>
          ) : (
            <Text as="span">{RenderParts()}</Text>
          )}
        </>
      );
    } else {
      return (
        <>
          {searchKey === 'employeeNumber' ? (
            <Badge mr={2}>{item.employeeNumber}</Badge>
          ) : (
            <Text key={item[searchKey]} textTransform="capitalize" as="span">
              {item[searchKey]}
            </Text>
          )}
        </>
      );
    }
  };
  return (
    <>
      {searchKey === 'firstName' && (
        <>
          <Text textTransform="capitalize" as="span">
            {student.lastName},{' '}
          </Text>
          {highLightText(student, highlightString)}
          <Box lineHeight={5}>
            {student.employeeNumber && (
              <Badge mr={2}>{student.employeeNumber}</Badge>
            )}
            {student.departmentCode && <Badge>{student.departmentCode}</Badge>}
          </Box>
        </>
      )}

      {searchKey === 'lastName' && (
        <>
          {highLightText(student, highlightString)}
          <Text textTransform="capitalize" as="span">
            , {student.firstName}
          </Text>
          <Box lineHeight={5}>
            {student.employeeNumber && (
              <Badge mr={2}>{student.employeeNumber}</Badge>
            )}
            {student.departmentCode && <Badge>{student.departmentCode}</Badge>}
          </Box>
        </>
      )}
      {searchKey === 'employeeNumber' && (
        <>
          <Text textTransform="capitalize" as="span">
            {student.lastName},{' '}
          </Text>
          <Text textTransform="capitalize" as="span">
            {student.firstName}
          </Text>
          <Box lineHeight={5}>
            {highLightText(student, highlightString)}
            {student.departmentCode && <Badge>{student.departmentCode}</Badge>}
          </Box>
        </>
      )}
    </>
  );
};
