import {GetDurableStatusResponse} from '../lib/api/types/Services';
import {fapper} from '../utils/fapper';

export const getDurableStatus = async (url: string, accessToken?: string) => {
  if (!accessToken) {
    return new Promise<undefined>((response) => response(undefined));
  }
  const response: GetDurableStatusResponse = await fapper.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response;
};
