import {Grid, GridItem, Text} from '@chakra-ui/react';
import React, {ReactNode} from 'react';
import {colors} from '../../../theme/colors';

type ColumnOptions = {
  fontWeight: 'normal' | 'bold';
};

export type Row = {
  name: string;
  label?: string;
  node?: ReactNode;
  options: ColumnOptions;
};

export interface Props {
  rows: Row[][];
  templateColumns?: string;
}

export const GridTable = ({rows, templateColumns}: Props) => {
  return (
    <>
      {rows.map((c, rowIndex) => {
        const tc = templateColumns
          ? templateColumns
          : `repeat(${c.length}, 1fr)`;
        return (
          <Grid
            key={rowIndex}
            templateColumns={tc}
            gap={4}
            borderBottom={
              rowIndex < rows.length - 1
                ? `2px solid ${colors.gridDivider}`
                : ''
            }
            py={1}
          >
            {c.map((column, colIndex) => (
              <React.Fragment key={colIndex}>
                {column.label && (
                  <GridItem overflow="hidden">
                    <Text
                      title={column.label}
                      as="span"
                      fontWeight={column.options.fontWeight}
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: '95%',
                        display: 'block',
                      }}
                    >
                      {column.label}
                    </Text>
                  </GridItem>
                )}
                {column.node}
              </React.Fragment>
            ))}
          </Grid>
        );
      })}
    </>
  );
};
