import dayjs from 'dayjs';
import {i18n} from '../providers/i18n/i18n';

export const formatToHHMM = (dateString: string) => {
  return new Date(dateString).toLocaleString(i18n.language, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatToShortWeekday = (dateString: string) => {
  return new Date(dateString).toLocaleString(i18n.language, {
    weekday: 'short',
  });
};

export const formatToNumericDay = (dateString: string) => {
  return new Date(dateString).toLocaleString(i18n.language, {
    day: 'numeric',
  });
};

export const formatToShortMonth = (dateString: string) => {
  return new Date(dateString).toLocaleString(i18n.language, {
    month: 'short',
  });
};

export const addMonthAndSetFirstDate = (
  date: dayjs.Dayjs,
  numberOfMonths: number = 1,
  format: string = 'YYYY-MM-DD',
) => {
  return date
    .add(numberOfMonths, 'month')
    .set('date', 1)
    .format(format)
    .toString();
};

export const addMonthAndSetLastDate = (
  date: dayjs.Dayjs,
  numberOfMonths: number = 1,
  format: string = 'YYYY-MM-DD',
) => {
  return date
    .add(numberOfMonths, 'month')
    .endOf('month')
    .format(format)
    .toString();
};

export const addMonthFormatFullMonth = (
  date: dayjs.Dayjs,
  numberOfMonths: number = 0,
) => {
  return date.add(numberOfMonths, 'month').format('MMMM');
};
