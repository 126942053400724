import {Input, Select, Grid, GridItem, Button, Text} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {Search} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {
  CertificatesFilter,
  HasSucceedingTrainingDateFilter,
} from '../../lib/api/types/Certificates';
import {FilteredService} from '../../lib/api/types/Services';

interface Props {
  planDefinitionFilters: FilteredService[];
  hasSucceedingTrainingDateFilters: HasSucceedingTrainingDateFilter[];
  firstname?: string;
  lastname?: string;
  filter: CertificatesFilter;
  onChangeFirstnameEvent: (value: string) => void;
  onChangeLastnameEvent: (value: string) => void;
  onChangePlanDefinitionEvent: (value: string) => void;
  onChangeExpireDateEvent: (value: string) => void;
  onChangeExpireRangeEvent: (value: string) => void;
  onChangeHasSucceedingTrainingDateEvent: (value: string) => void;
  onSubmit: () => void;
}
export const FilterView = ({
  planDefinitionFilters,
  hasSucceedingTrainingDateFilters,
  firstname,
  lastname,
  filter,
  onChangeFirstnameEvent,
  onChangeLastnameEvent,
  onChangePlanDefinitionEvent,
  onChangeExpireDateEvent,
  onChangeExpireRangeEvent,
  onChangeHasSucceedingTrainingDateEvent,
  onSubmit,
}: Props) => {
  const {t} = useTranslation();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Grid gap={2} py={1} templateColumns={`repeat(6, 1fr) 0.2fr`} pb={6}>
        <GridItem>
          <Text as="span" fontWeight="bold" fontSize="16px" mb={0}>
            {t('certificates.filter.firstname')}
          </Text>
          <Input
            id="firstname"
            name="firstname"
            autoComplete="off"
            placeholder={t('service.enter_value')}
            value={firstname || ''}
            onChange={({target}) => {
              onChangeFirstnameEvent(target.value);
            }}
          />
        </GridItem>
        <GridItem>
          <Text as="span" fontWeight="bold" fontSize="16px" mb={0}>
            {t('certificates.filter.lastname')}
          </Text>
          <Input
            id="lastname"
            name="lastname"
            autoComplete="off"
            placeholder={t('service.enter_value')}
            value={lastname || ''}
            onChange={({target}) => {
              onChangeLastnameEvent(target.value);
            }}
          />
        </GridItem>
        <GridItem width={'160px'}>
          <Text as="span" fontWeight="bold" fontSize="16px" mb={0}>
            {t('certificates.filter.plan_definition_name')}
          </Text>
          <Select
            onChange={({target}) => {
              onChangePlanDefinitionEvent(target.value);
            }}
            value={filter.plandefinitionId || ''}
            placeholder={t('service')}
          >
            {planDefinitionFilters.map((item) => (
              <option value={item.planDefinitionId} key={item.planDefinitionId}>
                {item.planDefinitionName}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem width={'170px'}>
          <Text as="span" fontWeight="bold" fontSize="16px" mb={0}>
            {t('certificates.filter.expire_date')}
          </Text>
          <Input
            type="date"
            value={
              filter.expireDate
                ? dayjs(filter.expireDate).format('YYYY-MM-DD')
                : ''
            }
            onChange={({target}) => {
              onChangeExpireDateEvent(target.value);
            }}
          />
        </GridItem>
        <GridItem width={'170px'}>
          <Text as="span" fontWeight="bold" fontSize="16px" mb={0}>
            {t('certificates.filter.expire_range')}
          </Text>
          <Select
            onChange={({target}) => {
              onChangeExpireRangeEvent(target.value);
            }}
            value={filter.expireRange || ''}
            placeholder={t('certificates.expire_range')}
          >
            <option value="expired">
              {t('certificates.expire_range.expired')}
            </option>
            <option value="less_than_1_month">
              {t('certificates.expire_range.less_than_1_month')}
            </option>
            <option value="less_than_3_months">
              {t('certificates.expire_range.less_than_3_months')}
            </option>
            <option value="more_than_3_months">
              {t('certificates.expire_range.more_than_3_months')}
            </option>
          </Select>
        </GridItem>
        <GridItem width={'170px'}>
          <Text as="span" fontWeight="bold" fontSize="16px" mb={0}>
            {t('certificates.filter.planned_date')}
          </Text>
          <Select
            onChange={({target}) => {
              onChangeHasSucceedingTrainingDateEvent(target.value);
            }}
            value={filter.hasSucceedingTrainingDate?.toString() || ''}
            placeholder={t('certificates.select')}
          >
            {hasSucceedingTrainingDateFilters.map((item) => (
              <option value={item.value.toString()} key={item.label}>
                {item.label}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem alignSelf="end">
          <Button variant="no-outline" type="submit">
            <Search />
          </Button>
        </GridItem>
      </Grid>
    </form>
  );
};
