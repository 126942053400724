import {AppointmentSet} from '../types/AppointmentSet';
import {ActivityStatus} from '../types/AppointmentShared';
import {dateFromNow} from './dates';

export const mockAppointmentSetDetails: AppointmentSet = {
  appointmentSetId: 'dummy-activityDefinition-id-guid-appointment-set-b1',
  isFreeOfChargeCancellationDateExceeded: true,
  accountId: 'fedfe604-c1a0-43d8-854d-d7794a08d5c4', //nullable
  isStarted: false,
  isProcessing: false,
  patientName: 'Test Testmans',
  planDefinitionName: 'Ploegleider basis',
  employeeNumber: '178950',
  departmentCode: 'SPL-400',
  appointments: [
    {
      appointmentId: 'appointment-guid-a1',
      activityDefinitionId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      title: 'some title A1',
      appointmentTitle: 'appointment title A1',
      preparations: 'some preparations',
      reminder: 'some reminder',
      requester: 'T. de Trainer',
      requesterRoles: ['Trainer'],
      startTime: dateFromNow(3, 0, 0),
      endTime: dateFromNow(3, 0, 60),
      dueDate: dateFromNow(1, 0, 0),
      location: 'oost',
      status: 'some status',
      destination: 'some destination',
      appointmentType: 'some appointmentType',
      statusValue: ActivityStatus.Booked,
      planDefinitionId: 'dummy-guid-plandefinition',
      isProcessing: false,
      accountId: 'fedfe604-c1a0-43d8-854d-d7794a08d5c4', //nullable
    },
    {
      appointmentId: 'appointment-guid-a2',
      activityDefinitionId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      title: 'some title A2',
      appointmentTitle: 'appointment title A2',
      preparations: 'some preparations',
      reminder: 'some reminder',
      requester: 'T. de Trainer',
      requesterRoles: ['Trainer'],
      startTime: dateFromNow(4, 0, 0),
      endTime: dateFromNow(4, 0, 60),
      dueDate: dateFromNow(1, 0, 0),
      location: 'oost',
      status: 'some status',
      destination: 'some destination',
      appointmentType: 'some appointmentType',
      statusValue: ActivityStatus.Booked,
      planDefinitionId: 'dummy-guid-plandefinition',
      isProcessing: false,
      accountId: 'fedfe604-c1a0-43d8-854d-d7794a08d5c4', //nullable
    },
    {
      appointmentId: 'appointment-guid-a3',
      activityDefinitionId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      title: 'some title A3',
      appointmentTitle: 'appointment title A3',
      preparations: 'some preparations',
      reminder: 'some reminder',
      requester: 'T. de Trainer',
      requesterRoles: ['Trainer'],
      startTime: dateFromNow(6, 0, 0),
      endTime: dateFromNow(6, 0, 120),
      dueDate: dateFromNow(1, 0, 0),
      location: 'oost',
      status: 'some status',
      destination: 'some destination',
      appointmentType: 'some appointmentType',
      statusValue: ActivityStatus.Booked,
      planDefinitionId: 'dummy-guid-plandefinition',
      isProcessing: false,
      accountId: 'fedfe604-c1a0-43d8-854d-d7794a08d5c4', //nullable
    },
  ],
};
