import {Page} from './Page';

export const LoginPage = () => {
  const header = 'Een portaal, voor een compleet overzicht';
  const content =
    'Met een goed gezondheidsbeleid zorg je als werkgever voor je medewerkers. Niet alleen in Nederland maar ook in het buitenland. Met behulp van deze portal houdt je je medewerkers fit en gezond. Denk aan medische keuringen om beroepsrisico’s te beperken, leefstijladviezen over werk en privé in balans óf vaccinaties om veilig in het buitenland zaken te doen. Deze portal bevat elk overzicht dat nodig om iedereen binnen je bedrijf gezond te houden.';

  return (
    <Page header={header} cyTag="LoginPage">
      {content}
    </Page>
  );
};
