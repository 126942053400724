import {useInterval} from '@chakra-ui/react';
import {t} from 'i18next';
import {useState, useEffect} from 'react';
import {getDurableStatus} from '../api/getDurableStatus';
import {
  GetDurableStatusResponse,
  StartServiceForMultiplePatientsError,
} from '../lib/api/types/Services';
import {useAcquireApiToken} from './useAcquireApiToken';

export const useDurableFunction = (url?: string) => {
  const {accessToken} = useAcquireApiToken();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>();
  const [errorJson, setErrorJson] =
    useState<StartServiceForMultiplePatientsError | null>();
  const [durableStatusUrl, setdurableStatusUrl] =
    useState<string | undefined>(url);
  const [cancelStatus, setCancelStatus] = useState<GetDurableStatusResponse>();
  const [isRunningDurableFunction, setIsRunningDurableFunction] =
    useState(false);
  const [isSucceeded, setIsSucceeded] = useState(false);

  const reset = () => {
    setIsLoading(false);
    setCancelStatus(undefined);
    setIsRunningDurableFunction(false);
  };

  const isCompleted = (status?: GetDurableStatusResponse) => {
    return status?.customStatus === 'Success';
  };

  const isFailed = (status?: GetDurableStatusResponse) => {
    return status?.customStatus === 'Failed';
  };

  useInterval(
    async () => {
      if (durableStatusUrl) {
        try {
          const status = await getDurableStatus(durableStatusUrl, accessToken);
          setCancelStatus(status);
        } catch (e: any) {
          console.error(e);
          setIsRunningDurableFunction(false);
          setError(e as Error);
          reset();
        }
      }
    },
    durableStatusUrl && isRunningDurableFunction ? 3000 : null,
  );

  useEffect(() => {
    if (isFailed(cancelStatus)) {
      setdurableStatusUrl(undefined);
      setIsRunningDurableFunction(false);
      let err;
      if (cancelStatus?.output) {
        try {
          const patientIds = cancelStatus.output.patientIds?.split(',');
          setErrorJson({patientIds} as StartServiceForMultiplePatientsError);
        } catch (e) {
          console.log(e);
          err = new Error(cancelStatus.output.message);
          setError(err);
        }
      } else {
        err = new Error(t('service.start.unknown'));
        setError(err);
      }
      reset();
    }
    if (isCompleted(cancelStatus)) {
      setdurableStatusUrl(undefined);
      setIsRunningDurableFunction(false);
      setIsSucceeded(true);
      reset();
    }
  }, [cancelStatus, isLoading]);

  return {
    setdurableStatusUrl,
    setIsRunningDurableFunction: setIsRunningDurableFunction,
    setIsLoading,
    isLoading,
    error,
    errorJson,
    isSucceeded,
  };
};
