import {
  Text,
  Box,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Spinner,
  Heading,
  Textarea,
} from '@chakra-ui/react';
import {useCallback, useEffect, useState, ReactNode, ChangeEvent} from 'react';
import {
  defaultPageSize,
  getCertificateOverview,
  useCertificatesOverview,
} from '../../api/getCertificateOverview';
import {useFilteredCertificatesServices} from '../../api/getFilteredCertificatesServices';
import {GridTable, Row} from '../../components/Common/GridTable/GridTable';
import {TextWithLineBreak} from '../../components/Common/TextWithLineBreak/TextWithLineBreak';
import {useCustomerPortalState} from '../../contexts/customerPortalProvider';
import {useAcquireApiToken} from '../../hooks/useAcquireApiToken';
import {
  CertificateOverview,
  CertificatesFilter,
} from '../../lib/api/types/Certificates';
import {sizes} from '../../theme/themes/default';
import {LoadMore} from '../Appointments/AppointmentOverview/LoadMore';
import {FilterController} from './FilterController';
import {getListItemForCertificate} from './ListItem';

import {getServices} from '../../api/getServices';
import {useNavigate} from 'react-router-dom';
import {ServiceRouteDetails} from '../../constants/Routes';
import {Loader} from '../../components/Common/Loader';
import {getMinAndMaxRange} from './getMinAndMaxRange';
import {useTranslation} from 'react-i18next';
import {Service} from '../../lib/api/types/Services';
import {useErrorDispatch} from '../../contexts/errorDispatchContext';
import {ErrorActionType} from '../../reducers/errorReducer';
import {FapperError} from '../../utils/fapper';
import {useRenewableCertificate} from '../../api/renewableCertificate';
import {Alert} from '../../components/Common/Alert';
import {updateStudent} from '../../api/updateStudent';
import {AlreadyStarted} from '../../components/Service/Appointment/AlreadyStarted';

interface ModalButton {
  title: string;
  variant: 'primary' | 'secondary';
  disabled?: boolean;
  onClick: () => void;
  isCloseButton?: boolean;
}

export const CertificatesPage = () => {
  const errorDispatch = useErrorDispatch();
  const navigate = useNavigate();
  const {accessToken} = useAcquireApiToken();
  const {t} = useTranslation();
  const {state, dispatch} = useCustomerPortalState();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenAddNote,
    onOpen: onOpenAddNote,
    onClose: onCloseAddNote,
  } = useDisclosure();

  const [modalTitle, setModalTitle] = useState<string | ReactNode>();
  const [modalButtons, setModalButtons] = useState<ModalButton[]>([]);
  const [modalError, setModalError] = useState<string | undefined>();

  const [modalSuccess, setModalSuccess] = useState<string | undefined>();

  const [initialLoad, setInitialLoad] = useState(true);
  const [items, setItems] = useState<Row[][]>();
  const [plainItems, setPlainItems] = useState<CertificateOverview[]>([]);
  const [page, setPage] = useState<number>(1);
  const [noMoreItems, setNoMoreItems] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [filter, setFilter] = useState<CertificatesFilter>({
    ...state.certificates.data.filter,
    page,
  });
  const [observationId, setObservationId] =
    useState<string | undefined>(undefined);

  const [trainingNote, setTrainingNote] =
    useState<string | undefined>(undefined);
  const [studentId, setStudentId] = useState<string | undefined>(undefined);

  const [updateStudentError, setUpdateStudentError] =
    useState<string | undefined>(undefined);
  const [updateStudentIsLoading, setUpdateStudentIsLoading] = useState(false);
  const [updateStudentIsSuccess, setUpdateStudentIsSuccess] = useState(false);

  const {
    data: renewalData,
    error: renewalError,
    isLoading: renewalIsLoading,
  } = useRenewableCertificate(observationId);

  const {data, isLoading, error, refetch} = useCertificatesOverview({
    page: filter.page,
    firstName: filter.firstname,
    lastName: filter.lastname,
    planDefinitionId: filter.plandefinitionId,
    ...getMinAndMaxRange(filter.expireRange, filter.expireDate),
    hasSucceedingTrainingDate: filter.hasSucceedingTrainingDate,
  });
  const {
    data: planDefinitionFilters,
    isLoading: planDefinitionFiltersIsLoading,
    error: planDefinitionFiltersError,
    refetch: planDefinitionFiltersRefetch,
  } = useFilteredCertificatesServices();

  const onCloseModal = useCallback(() => {
    setModalError(undefined);
    setObservationId(undefined);
    setModalSuccess(undefined);
    onClose();
  }, [onClose]);

  const onCloseModalUpdateStudent = useCallback(() => {
    setUpdateStudentError(undefined);
    setUpdateStudentIsSuccess(false);
    setStudentId(undefined);
    onCloseAddNote();
  }, [onCloseAddNote]);

  const onStartService = useCallback(
    async (certificate: CertificateOverview) => {
      const dispatchService = (service: Service) => {
        dispatch({
          type: 'SERVICE_STORE',
          payload: {
            service: service,
            student: [
              {
                patientId: certificate.patientId,
                firstName: certificate.firstName,
                lastName: certificate.lastName,
                dateOfBirth: certificate.dateOfBirth,
                employeeNumber: certificate.employeeNumber,
                departmentCode: null,
                accountId: null,
              },
            ],
          },
        });
      };
      const services = await getServices(
        [certificate.patientId],
        false,
        false,
        accessToken,
      );
      const serviceForCertificate = services.find(
        (service) => service.planDefinitionId === certificate.planDefinitionId,
      );
      if (serviceForCertificate) {
        if (serviceForCertificate?.isActive) {
          dispatchService(serviceForCertificate);
          setModalTitle(<AlreadyStarted />);

          onOpen();
        } else if (!serviceForCertificate?.canBeStarted) {
          setModalTitle(t('service.no_valid_certificate'));

          onOpen();
        } else {
          dispatchService(serviceForCertificate);
          navigate(ServiceRouteDetails, {state: {navigateBack: true}});
        }
      }
    },
    [accessToken, dispatch, navigate, onOpen, t],
  );

  const onRenewalCertificate = useCallback(
    async (certificateObservationId: string) => {
      setModalTitle(t('certificates.no_renewal_text'));
      setModalButtons([
        {
          title: t('yes'),
          variant: 'primary',
          onClick: () => setObservationId(certificateObservationId),
        },
        {
          title: t('no'),
          variant: 'secondary',
          onClick: () => onCloseModal(),
          isCloseButton: true,
        },
      ]);
      onOpen();
    },
    [t, onOpen, onCloseModal],
  );

  const onAddNote = useCallback(
    async (patientId: string, trainingNoteVal: string | null) => {
      setTrainingNote(trainingNoteVal || '');
      setStudentId(patientId);

      onOpenAddNote();
    },
    [onOpenAddNote],
  );

  const transformRows = useCallback(
    (certificates: CertificateOverview[]) => {
      return certificates.map((item) => {
        return getListItemForCertificate(
          item,
          () => onStartService(item),
          () => onRenewalCertificate(item.observationId),
          () => onAddNote(item.patientId, item.trainingNote),
        ) as Row[];
      });
    },
    [onStartService, onRenewalCertificate, onAddNote],
  );

  const throwErrorDispatch = useCallback(
    (err: FapperError) => {
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error: err,
          refetch,
        },
      });
    },
    [errorDispatch, refetch],
  );

  useEffect(() => {
    if (renewalData?.length === 0) {
      const newItems = plainItems.filter(
        (it) => it.observationId !== observationId,
      );
      if (newItems) {
        setPlainItems(newItems);
        setItems(transformRows(newItems));
      }

      setModalSuccess(t('certificates.no_renewal_text.success'));
      setModalButtons([
        {
          title: t('close'),
          variant: 'primary',
          onClick: () => onCloseModal(),
        },
      ]);
      setObservationId(undefined);
    }
  }, [
    observationId,
    onCloseModal,
    plainItems,
    renewalData?.length,
    t,
    transformRows,
  ]);

  useEffect(() => {
    if (renewalError) {
      setModalError(
        renewalError.statusText || t('certificates.no_renewal_text.error'),
      );
      setObservationId(undefined);
    }
  }, [renewalError, t]);

  useEffect(() => {
    if (data) {
      setPlainItems(data);
      setItems(transformRows(data));
      setInitialLoad(false);
      if (data.length < defaultPageSize) {
        setNoMoreItems(true);
      } else {
        setNoMoreItems(false);
      }
    }
  }, [data, transformRows, onStartService]);

  useEffect(() => {
    dispatch({
      type: 'CERTIFICATES_STORE',
      payload: {
        filter,
      },
    });
  }, [dispatch, filter]);

  useEffect(() => {
    if (!isLoading && error) {
      throwErrorDispatch(error);
    }
  }, [isLoading, error, errorDispatch, refetch, throwErrorDispatch]);

  useEffect(() => {
    if (!planDefinitionFiltersIsLoading && planDefinitionFiltersError) {
      throwErrorDispatch(planDefinitionFiltersError);
    }
  }, [
    planDefinitionFiltersIsLoading,
    planDefinitionFiltersError,
    planDefinitionFiltersRefetch,
    refetch,
    throwErrorDispatch,
  ]);

  const onLoadMore = async () => {
    if (data) {
      setIsLoadingMore(true);
      const newPage = page + 1;
      setPage(newPage);
      const newItems = await getCertificateOverview(accessToken, {
        ...filter,
        page: newPage,
      });
      if (newItems && newItems.length > 0) {
        if (newItems.length < defaultPageSize) {
          setNoMoreItems(true);
        }

        const transformedItems = transformRows(newItems);

        if (items) {
          setItems([...items, ...transformedItems]);
        } else {
          setItems([...transformedItems]);
        }
      } else {
        setNoMoreItems(true);
      }
      setIsLoadingMore(false);
    }
  };

  const onUpdateStudent = async () => {
    setUpdateStudentIsLoading(true);
    setUpdateStudentIsSuccess(false);
    setUpdateStudentError(undefined);
    try {
      await updateStudent(accessToken, studentId, {
        trainingNote,
      });
      setUpdateStudentIsSuccess(true);
      refetch();
    } catch (e: any) {
      setUpdateStudentError(e.statusText || t('student.update.error'));
    }
    setUpdateStudentIsLoading(false);
  };

  if (
    (isLoading && planDefinitionFiltersIsLoading) ||
    (initialLoad && !error && !planDefinitionFiltersError)
  ) {
    return <Loader />;
  }

  return (
    <Container maxW="container.xl" pt={8} pb={28} overflow="auto">
      <Box width={`calc(${sizes.container.xl} - 2rem)`}>
        {!planDefinitionFiltersIsLoading && (
          <>
            {planDefinitionFilters ? (
              <FilterController
                planDefinitionFilters={planDefinitionFilters}
                hasSucceedingTrainingDateFilters={[
                  {value: true, label: t('certificates.has_training_date')},
                  {value: false, label: t('certificates.has_no_training_date')},
                ]}
                setFilter={setFilter}
                filter={filter}
              />
            ) : (
              planDefinitionFilters && (
                <Container maxW="container.xl" pt={8} pb={28}>
                  <TextWithLineBreak keyToTranslate="certificates.filter_error" />
                </Container>
              )
            )}
          </>
        )}
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            {items && !error && (
              <>
                {items.length === 0 ? (
                  <Container maxW="container.xl" pt={8} pb={28}>
                    <TextWithLineBreak keyToTranslate="certificates.no_results" />
                  </Container>
                ) : (
                  <GridTable
                    rows={items}
                    templateColumns={`repeat(${
                      items[0].length - 1
                    }, 1fr) 0.2fr`}
                  />
                )}
              </>
            )}
          </>
        )}
        {isLoadingMore && <Loader />}
        {!isLoadingMore && items && !noMoreItems && (
          <Box mt={4}>
            <LoadMore onLoadMore={onLoadMore} />
          </Box>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay backdropFilter="auto" backdropBrightness="60%" />
        <ModalContent>
          <ModalBody mt={8} pt={2} px={8}>
            <>
              <ModalCloseButton />

              <Text mb={2}>{modalTitle}</Text>

              {(modalError || modalSuccess) && (
                <Alert
                  status={modalError ? 'error' : 'success'}
                  message={modalSuccess || modalError || ''}
                ></Alert>
              )}

              <ModalFooter>
                {modalButtons.map((button, index) => (
                  <Button
                    key={index}
                    colorScheme={button.variant}
                    mr={3}
                    onClick={button.onClick}
                    disabled={renewalIsLoading || false}
                    rightIcon={
                      renewalIsLoading && !button.isCloseButton ? (
                        <Spinner />
                      ) : (
                        <></>
                      )
                    }
                  >
                    {button.title}
                  </Button>
                ))}
              </ModalFooter>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenAddNote} onClose={onCloseModalUpdateStudent}>
        <ModalOverlay backdropFilter="auto" backdropBrightness="60%" />
        <ModalContent>
          <ModalBody mt={8} pt={2} px={8}>
            <>
              <ModalCloseButton onClick={onCloseModalUpdateStudent} />

              <Heading as="h3" variant="h3">
                {t('add_note')}
              </Heading>

              <Textarea
                mb={4}
                height="150px"
                placeholder={t('student.update.input_placeholder')}
                value={trainingNote || ''}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  setTrainingNote(event.target.value)
                }
              />

              {(updateStudentError || updateStudentIsSuccess) && (
                <Alert
                  status={updateStudentError ? 'error' : 'success'}
                  message={updateStudentError || t('student.update.success')}
                ></Alert>
              )}

              <ModalFooter>
                <Button
                  colorScheme="primary"
                  mr={3}
                  onClick={() => onUpdateStudent()}
                  disabled={updateStudentIsLoading || false}
                  rightIcon={updateStudentIsLoading ? <Spinner /> : <></>}
                >
                  {t('save')}
                </Button>
              </ModalFooter>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
