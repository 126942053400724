import {Container, Box, Heading, Text, Flex, Input} from '@chakra-ui/react';
import {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {StudentMetaData} from './StudentMetaData';

interface Props {
  onChangeDate: (value: string) => void;
  onChangeStartTime: (value: string) => void;
  onChangeEndTime: (value: string) => void;
  timeInputDisabled: boolean;
}
export const FreeFormatAppointmentView = ({
  onChangeDate,
  onChangeStartTime,
  onChangeEndTime,
  timeInputDisabled,
}: Props) => {
  const {t} = useTranslation();

  const onChangeDateEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    onChangeDate(date);
  };

  const onChangeStartTimeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    onChangeStartTime(date);
  };

  const onChangeEndTimeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    onChangeEndTime(date);
  };

  return (
    <Container maxW="container.xl">
      <Box mb={10}>
        <Heading as="h1" variant="h1" mb={0}>
          {t('service.title')}
        </Heading>
        <Box mb={10}>
          <StudentMetaData />
          <Heading as="h3" variant="h3" mt={5} mb={2}>
            {t('service.location')}
          </Heading>
          <Text as="span" mt={0}>
            {t('service.own_location')}
          </Text>
          <Heading as="h3" variant="h3" mt={7}>
            {t('service.choose_day')}
          </Heading>

          <Text as="span" display="block" mt={0}>
            {t('service.date')}
          </Text>
          <Input type="date" width={220} onChange={onChangeDateEvent} />

          <Flex mt={2}>
            <Box>
              <Text as="span" display="block">
                {t('service.starttime')}
              </Text>
              <Input
                width={150}
                disabled={timeInputDisabled}
                type="time"
                onChange={onChangeStartTimeEvent}
              />
            </Box>
            <Box ms={5}>
              <Text as="span" display="block">
                {t('service.endtime')}
              </Text>
              <Input
                width={150}
                disabled={timeInputDisabled}
                type="time"
                onChange={onChangeEndTimeEvent}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Container>
  );
};
