import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {Appointment} from '../lib/api/types/Appointment';
import {fapper, FapperError} from '../utils/fapper';
import {getAdditionalLocationInfo} from './getAdditionalLocationInfo';

const sendAppointment = async (
  id: string,
  language: string,
  accessToken: string,
): Promise<Appointment> => {
  const responseData: Appointment = await fapper.get(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/appointments/${id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  const additionalLocationInfo = await getAdditionalLocationInfo({
    tag: responseData.location,
    language,
  });
  responseData.address = additionalLocationInfo.address;

  return responseData;
};

const getAppointment = async (
  id: string | null,
  language: string,
  accessToken?: string,
) => {
  if (!id || !language || !accessToken) {
    return new Promise<Appointment | undefined>((resolve) =>
      resolve(undefined),
    );
  }
  return sendAppointment(id, language, accessToken);
};

const useAppointment = (
  id: string | null,
  language: string,
  options: UseQueryOptions<
    Appointment | undefined,
    FapperError,
    Appointment | undefined,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<Appointment | undefined, FapperError>(
    ['Appointment', id, language, accessToken],
    () => getAppointment(id, language, accessToken),
    options,
  );
};

export {useAppointment, getAppointment};
