import dayjs from 'dayjs';
import {dateFromNow} from '../../../utils/dateUtils';
import {ServiceLocationsWithDates} from '../types/Services';

const now = dayjs().format('YYYY-MM-DD[T]HH:mm:ss[Z]');

export const mockLocationsWithCapacity: ServiceLocationsWithDates[] = [
  {
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    locationAlias: 'DenHaag',
    backendDisplayName: 'Afdeling Travel Clinic',
    dates: [
      {
        locationAlias: 'DenHaag',
        date: now,
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(1, 0, 0),
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(2, 0, 0),
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(3, 0, 0),
        availableCapacity: 6,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(4, 0, 0),
        availableCapacity: 4,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(5, 0, 0),
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(6, 0, 0),
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(7, 0, 0),
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'DenHaag',
        date: dateFromNow(7, 0, 0),
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
    ],
  },
  {
    activityDefinitionId: 'bd8bba05-7c82-eb11-a812-000d3abd3fca',
    locationAlias: 'Oost',
    backendDisplayName: 'Schiphol Oost',

    dates: [
      {
        locationAlias: 'Oost',
        date: now,
        availableCapacity: 8,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(1, 0, 0),
        availableCapacity: 5,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(2, 0, 0),
        availableCapacity: 6,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(4, 0, 0),
        availableCapacity: 7,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(6, 0, 0),
        availableCapacity: 2,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(8, 0, 0),
        availableCapacity: 9,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(10, 0, 0),
        availableCapacity: 10,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(12, 0, 0),
        availableCapacity: 11,
        showAvailableCapacity: true,
      },
      {
        locationAlias: 'Oost',
        date: dateFromNow(14, 0, 0),
        availableCapacity: 12,
        showAvailableCapacity: true,
      },
    ],
  },
];
