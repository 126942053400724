export const ClientManagerGroup: string = process.env
  .REACT_APP_AAD_GROUP_CLIENT_MANAGER as string;
export const HRManagerGroup: string = process.env
  .REACT_APP_AAD_GROUP_HR_MANAGER as string;
export const KHSGroup: string = process.env.REACT_APP_AAD_GROUP_KHS as string;
export const MedischSpecialistGroup: string = process.env
  .REACT_APP_AAD_GROUP_MEDISCH_SPECIALIST as string;
export const PcrGroup: string = process.env.REACT_APP_AAD_GROUP_PCR as string;
export const TrainingCoordinatorGroup: string = process.env
  .REACT_APP_AAD_GROUP_TRAINING_COORDINATOR as string;
export const AllGroups: string[] = [
  ClientManagerGroup,
  HRManagerGroup,
  KHSGroup,
  MedischSpecialistGroup,
  PcrGroup,
  TrainingCoordinatorGroup,
];
