import {AppointmentTimeslotSelectorAppointment} from '../components/Service/AppointmentNew/types';
import {
  Service,
  ServiceFreeFormat,
  ServiceLocationsWithDates,
  AppointmentTimeslot,
} from '../lib/api/types/Services';
import {
  CustomerPortalServiceState,
  defaultServiceState,
} from '../lib/api/types/State';
import {Student} from '../lib/api/types/Student';
import {UserProfile} from '../lib/api/types/User';

type ServiceDataPayLoad = {
  service?: Service;
  student?: Student[];
  location?: ServiceLocationsWithDates;
  timeslots?: AppointmentTimeslot[];
  freeFormat?: ServiceFreeFormat;
  userProfile?: UserProfile;
  currentAppointment?: AppointmentTimeslotSelectorAppointment;
  isMultipleStudentsSelected?: boolean;
};

export type State = {
  data: ServiceDataPayLoad;
};

type StoreAppointmentData = {
  type: 'SERVICE_STORE';
  payload: ServiceDataPayLoad;
};

type Clear = {
  type: 'SERVICE_CLEAR';
  payload?: undefined;
};

type ClearDetails = {
  type: 'SERVICE_DETAILS_CLEAR';
  payload?: undefined;
};

type CurrentAppointmentData = {
  type: 'SERVICE_STORE_CURRENT_APPOINTMENT';
  payload: {
    currentAppointment: AppointmentTimeslotSelectorAppointment;
  };
};

export type Action =
  | StoreAppointmentData
  | CurrentAppointmentData
  | Clear
  | ClearDetails;

export const reducer = (
  state: CustomerPortalServiceState = defaultServiceState,
  action: Action,
) => {
  const {type} = action;

  switch (type) {
    case 'SERVICE_STORE':
      const {payload: storePayload} = action as StoreAppointmentData;
      return {
        ...state,
        data: {
          ...state.data,
          ...storePayload,
        },
      };
    case 'SERVICE_STORE_CURRENT_APPOINTMENT':
      const {payload: currentAppointmentPayload} =
        action as CurrentAppointmentData;
      return {
        ...state,
        data: {
          ...state.data,
          currentAppointment: currentAppointmentPayload.currentAppointment,
        },
      };

    case 'SERVICE_CLEAR':
      return {
        ...state,
        data: {
          service: undefined,
          student: undefined,
          location: undefined,
          timeslots: undefined,
          freeFormat: undefined,
        },
      };
    case 'SERVICE_DETAILS_CLEAR':
      return {
        ...state,
        data: {
          ...state.data,
          location: undefined,
          timeslots: undefined,
          freeFormat: undefined,
        },
      };
    default:
      return state;
  }
};
