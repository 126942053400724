import React, {
  useReducer,
  useContext,
  createContext,
  ReactNode,
  useMemo,
  useCallback,
  Dispatch,
} from 'react';
import {
  CustomerPortalState,
  defaultAppointmentState,
  defaultCertificatesState,
  defaultServiceState,
} from '../lib/api/types/State';
import {
  reducer as ServiceReducer,
  Action as ServiceAction,
} from '../reducers/serviceReducer';
import {
  reducer as AppointmentReducer,
  Action as AppointmentAction,
} from '../reducers/appointmentsReducer';
import {
  reducer as CertificatesReducer,
  Action as CertificatesAction,
} from '../reducers/certificatesReducer';

type ContextAction = ServiceAction | AppointmentAction | CertificatesAction;
interface ContextProps {
  state: CustomerPortalState;
  dispatch: ({type, payload}: ContextAction) => void;
}
const StateContext = createContext<ContextProps>({} as ContextProps);

export const useCustomerPortalState = () => {
  const context = useContext(StateContext);

  if (context === undefined) {
    throw new Error(
      'useCustomerPortalState must be used within an CustomerPortalProvider',
    );
  }

  return context;
};

const combineDispatch =  // FIX typings
  (
    ...dispatches: (
      | Dispatch<ServiceAction>
      | Dispatch<AppointmentAction>
      | Dispatch<CertificatesAction>
    )[]
  ) =>
  (action: any) => {
    return dispatches.forEach((dispatch) => dispatch(action));
  };

export const CustomerPortalProvider = ({children}: {children: ReactNode}) => {
  const [serviceState, serviceDispatch] = useReducer(
    ServiceReducer,
    defaultServiceState,
  );
  const [appointmentState, appointmentDispatch] = useReducer(
    AppointmentReducer,
    defaultAppointmentState,
  );

  const [certificatesState, certificatesDispatch] = useReducer(
    CertificatesReducer,
    defaultCertificatesState,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useCallback(
    combineDispatch(serviceDispatch, appointmentDispatch, certificatesDispatch),
    [serviceDispatch, appointmentDispatch, certificatesDispatch],
  );

  const state = React.useMemo(
    () => ({
      service: serviceState,
      appointment: appointmentState,
      certificates: certificatesState,
    }),
    [serviceState, appointmentState, certificatesState],
  );

  const contextValue = useMemo(() => {
    return {state, dispatch};
  }, [state, dispatch]);

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};
