import {
  CalendarIcon,
  PlusSquareIcon,
  QuestionOutlineIcon,
  TimeIcon,
  IconProps,
} from '@chakra-ui/icons';
import {ComponentWithAs} from '@chakra-ui/react';
import {IRoute} from 'auth-react-router';
import {t} from 'i18next';
import {
  Book,
  DollarSign,
  Dribbble,
  Icon,
  Clipboard,
  FileText,
} from 'react-feather';
import {
  AppointmentDetailsRoute,
  AppointmentRoute,
  AppointmentsOverviewRoute,
  CertificatesRoute,
  ContentRoute,
  CoordinatorRoute,
  Covid19Route,
  FlowRoute,
  QuestionnaireRoute,
  ResultsRoute,
  RootRoute,
  ServiceRouteDetails,
  ServiceRouteStart,
  TimelineRoute,
} from './constants/Routes';
import {AppointmentDetailsPage} from './pages/Appointments/AppointmentDetails/AppointmentDetailsPage';
import {AppointmentsOverviewPage} from './pages/Appointments/AppointmentOverview/AppointmentsOverviewPage';
import {CertificatesPage} from './pages/Certificates';
import {HomePage} from './pages/HomePage';
import {AppointmentPage} from './pages/PowerBIPages/AppointmentPage';
import {ContentPage} from './pages/PowerBIPages/ContentPage';
import {CoordinatorPage} from './pages/PowerBIPages/CoordinatorPage';
import {Covid19Page} from './pages/PowerBIPages/Covid19Page';
import {FlowPage} from './pages/PowerBIPages/FlowPage';
import {QuestionnairePage} from './pages/PowerBIPages/QuestionnairePage';
import {ResultsPage} from './pages/PowerBIPages/ResultsPage';
import {TimelinePage} from './pages/PowerBIPages/TimelinePage';
import {ServiceDetailsPage} from './pages/Services/ServiceDetails/ServiceDetailsPage';
import {ServiceStartPage} from './pages/Services/ServiceStart/ServiceStartPage';
import {roles} from './routes';

export type ExtendedRoute = IRoute & {
  text?: string;
  icon?: ComponentWithAs<'svg', IconProps> | Icon;
  category?: 'employee' | 'myself';
};

export const getConfiguratedRoutes = (): ExtendedRoute[] => [
  {
    path: RootRoute,
    component: <HomePage />,
    roles: [
      roles.HRManagerGroup,
      roles.ClientManagerGroup,
      roles.MedischSpecialistGroup,
      roles.KHSGroup,
      roles.PcrGroup,
      roles.TrainingCoordinatorGroup,
    ],
    text: t('route.home'),
  },
  {
    path: TimelineRoute,
    component: <TimelinePage />,
    roles: [roles.HRManagerGroup, roles.ClientManagerGroup],
    text: t('route.timeline'),
    icon: TimeIcon,
    category: 'myself',
  },
  {
    path: AppointmentRoute,
    component: <AppointmentPage />,
    roles: [roles.HRManagerGroup, roles.ClientManagerGroup],
    text: t('route.appointment'),
    icon: CalendarIcon,
    category: 'myself',
  },
  {
    path: Covid19Route,
    component: <Covid19Page />,
    roles: [roles.PcrGroup],
    text: t('route.covid_19'),
    icon: DollarSign,
    category: 'myself',
  },
  {
    path: QuestionnaireRoute,
    component: <QuestionnairePage />,
    roles: [roles.HRManagerGroup, roles.ClientManagerGroup],
    text: t('route.questionnaire'),
    icon: QuestionOutlineIcon,
    category: 'myself',
  },
  {
    path: ResultsRoute,
    component: <ResultsPage />,
    roles: [
      roles.HRManagerGroup,
      roles.ClientManagerGroup,
      roles.MedischSpecialistGroup,
      roles.KHSGroup,
    ],
    text: t('route.results'),
    icon: Clipboard,
    category: 'myself',
  },
  {
    path: ContentRoute,
    component: <ContentPage />,
    roles: [
      roles.HRManagerGroup,
      roles.ClientManagerGroup,
      roles.MedischSpecialistGroup,
      roles.KHSGroup,
    ],
    text: t('route.content'),
    icon: Book,
    category: 'myself',
  },
  {
    path: FlowRoute,
    component: <FlowPage />,
    roles: [
      roles.HRManagerGroup,
      roles.ClientManagerGroup,
      roles.MedischSpecialistGroup,
      roles.KHSGroup,
    ],
    text: t('route.flow'),
    icon: PlusSquareIcon,
    category: 'myself',
  },
  {
    path: ServiceRouteStart,
    component: <ServiceStartPage />,
    roles: [roles.TrainingCoordinatorGroup],
    text: t('route.start_service'),
    icon: Dribbble,
    category: 'employee',
  },
  {
    path: ServiceRouteDetails,
    component: <ServiceDetailsPage />,
    roles: [roles.TrainingCoordinatorGroup],
    category: 'employee',
  },
  {
    path: CoordinatorRoute,
    component: <CoordinatorPage />,
    roles: [roles.TrainingCoordinatorGroup],
    text: t('route.coordinator'),
    icon: Dribbble,
    category: 'myself',
  },
  {
    path: AppointmentsOverviewRoute,
    component: <AppointmentsOverviewPage />,
    roles: [roles.TrainingCoordinatorGroup],
    category: 'employee',
    text: t('route.edit_appointment'),
    icon: CalendarIcon,
  },
  {
    path: AppointmentDetailsRoute,
    component: <AppointmentDetailsPage />,
    roles: [roles.TrainingCoordinatorGroup],
    category: 'employee',
  },
  {
    path: CertificatesRoute,
    component: <CertificatesPage />,
    roles: [roles.TrainingCoordinatorGroup],
    icon: FileText,
    text: t('route.certificates'),
    category: 'employee',
  },
];

export const getAuthorizedRoutes = (groups: string[]) => {
  const configuratedRoutes = getConfiguratedRoutes();
  const result: ExtendedRoute[] = [];
  configuratedRoutes.forEach((route) => {
    if (!!route.roles) {
      const found = route.roles.some((r) => groups.indexOf(r) >= 0);
      if (found) {
        result.push(route);
      }
    }
  });
  return result;
};
