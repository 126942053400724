import type {SystemStyleObject} from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  px: 2,
  textTransform: 'uppercase',
  fontSize: 'md',
  lineHeight: 5,
  borderRadius: 'md',
  fontWeight: 'light',
};

export const Badge = {
  baseStyle,
};
