export enum ActivityStatus {
  'Proposed' = '935000000',
  'Pending' = '935000001',
  'Booked' = '935000002',
  'Arrived' = '935000003',
  'Fulfilled' = '935000004',
  'Cancelled' = '935000005',
  'No Show' = '935000006',
  'Entered In Error' = '935000007',
  'Checked-In' = '935000008',
  'Waitlist' = '935000009',
  'QuestionProposed' = '129420000',
  'QuestionInProgress' = '129420001',
  'QuestionCompleted' = '129420002',
}

export type DateTime = string; // "2020-12-07T11:00:00Z"
export type Guid = string; // "11c56479-f411-eb11-a813-000d3ad91091"
