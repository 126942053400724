import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import {useState} from 'react';
import {X} from 'react-feather';
import {useTranslation} from 'react-i18next';
import parse from 'html-react-parser';

interface Props {
  disabled?: boolean;
  isStarted?: boolean;
  isLoading?: boolean;
  isFreeOfChargeCancellationDateExceeded?: boolean;
  cancelConditions?: string;
  showTooltip?: boolean;
  onCancel: () => void;
}
interface ModalButton {
  title: string;
  variant: 'primary' | 'secondary';
  onClick?: () => void;
  onClickAsync?: () => Promise<void>;
}

export const CancelButton = ({
  disabled = true,
  isStarted = false,
  isLoading = false,
  isFreeOfChargeCancellationDateExceeded = false,
  cancelConditions,
  showTooltip = false,
  onCancel,
}: Props) => {
  const {t} = useTranslation();
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [buttons, setButtons] = useState<ModalButton[]>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const onPress = () => {
    if (isStarted) {
      setTitle(t('appointment.details.cancel.blocked.alert.title'));
      setMessage(t('appointment.details.edit.alert.message_already_started'));
      setButtons([
        {
          title: t('ok'),
          variant: 'primary',
          onClick: () => onClose(),
        },
      ]);
      onOpen();
    } else {
      if (isFreeOfChargeCancellationDateExceeded) {
        setTitle(t('appointment.details.cancel.blocked.alert.title'));
        setMessage(cancelConditions || '');
        setButtons([
          {
            title: t('appointment.details.cancel.alert.button.continue'),
            variant: 'primary',
            onClickAsync: async () => {
              onClose();
              onCancel();
            },
          },
          {
            title: t('appointment.details.cancel.alert.button.cancel'),
            variant: 'secondary',
            onClick: () => onClose(),
          },
        ]);
        onOpen();
      } else {
        setTitle(t('appointment.details.cancel.alert.title'));
        setMessage(t('appointment.details.cancel.alert.message'));
        setButtons([
          {
            title: t('appointment.details.cancel.alert.button.continue'),
            variant: 'primary',
            onClickAsync: async () => {
              onClose();
              onCancel();
            },
          },
          {
            title: t('appointment.details.cancel.alert.button.cancel'),
            variant: 'secondary',
            onClick: () => onClose(),
          },
        ]);
        onOpen();
      }
    }
  };

  return (
    <Box>
      <Tooltip
        label={showTooltip ? t('appointment.is_processing') : undefined}
        shouldWrapChildren
        placement="top"
      >
        <Button
          ms="5"
          leftIcon={<X />}
          colorScheme="secondary"
          onClick={onPress}
          disabled={disabled || isLoading}
          rightIcon={isLoading ? <Spinner /> : undefined}
        >
          <Text as="span" pt={0.5}>
            {t('button.cancel_appointment')}
          </Text>
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={8} px={8}>
            {title}
          </ModalHeader>
          <ModalCloseButton />
          {message && (
            <ModalBody pt={2} px={8}>
              {parse(message)}
            </ModalBody>
          )}

          <ModalFooter>
            {buttons?.map((button) => {
              return (
                <Button
                  key={button.title}
                  colorScheme={button.variant}
                  mr={3}
                  onClick={button.onClick || button.onClickAsync}
                >
                  {button.title}
                </Button>
              );
            })}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
