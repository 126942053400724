import {CertificatesFilter} from '../lib/api/types/Certificates';
import {
  CustomerPortalCertificatesState,
  defaultCertificatesState,
} from '../lib/api/types/State';

export type CertificatesDataPayload = {
  filter?: CertificatesFilter;
};

type StoreCertificatesData = {
  type: 'CERTIFICATES_STORE';
  payload: CertificatesDataPayload;
};

export type Action = StoreCertificatesData;

export const reducer = (
  state: CustomerPortalCertificatesState = defaultCertificatesState,
  action: Action,
) => {
  const {type} = action;

  switch (type) {
    case 'CERTIFICATES_STORE':
      const {payload: certificatesPayload} = action as StoreCertificatesData;
      return {
        ...state,
        data: {
          ...certificatesPayload,
        },
      };
    default:
      return state;
  }
};
