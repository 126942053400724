import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Container,
  CloseButton,
  Button,
} from '@chakra-ui/react';
import {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {useErrorDispatch} from '../../../contexts/errorDispatchContext';
import {useErrorState} from '../../../contexts/errorStateContext';
import {ErrorActionType, ErrorKHS} from '../../../reducers/errorReducer';

export const ErrorAlert = () => {
  const location = useLocation();
  const {t} = useTranslation();
  const {errors} = useErrorState();
  const dispatch = useErrorDispatch();

  useEffect(() => {
    if (location) {
      dispatch({
        type: ErrorActionType.ErrorActionRemoveAll,
      });
    }
  }, [location, dispatch]);

  const hide = (error: ErrorKHS): void => {
    dispatch({
      type: ErrorActionType.ErrorActionRemove,
      payload: {
        id: error.id,
      },
    });
  };

  const REFRESH_PAGE = t('refresh.page');
  return (
    <Container maxW="container.xl" position="relative">
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          zIndex: 2,
          px: '1rem',
        }}
      >
        <Box>
          {errors.map((error: ErrorKHS, index) => (
            <Alert
              borderRadius={8}
              status="error"
              key={index}
              mb={4}
              boxShadow="base"
            >
              <AlertIcon />
              <AlertTitle pr={10}>
                {error.error?.statusText ? (
                  <>
                    {error.error?.statusText}
                    {'. '}
                    {error.refetch && (
                      <Button
                        variant="link"
                        width="auto"
                        onClick={() => {
                          if (error.refetch) {
                            error.refetch();
                            hide(error);
                          }
                        }}
                      >
                        {t('try.again')}
                      </Button>
                    )}
                  </>
                ) : (
                  <Trans
                    i18nKey="error.message"
                    values={{REFRESH: REFRESH_PAGE}}
                  >
                    <Button
                      variant="link"
                      width="auto"
                      onClick={() => window.location.reload()}
                    ></Button>
                  </Trans>
                )}
              </AlertTitle>
              <CloseButton
                onClick={() => hide(error)}
                position="absolute"
                right="8px"
                top="8px"
              />
            </Alert>
          ))}
        </Box>
      </Box>
    </Container>
  );
};
