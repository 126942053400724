import {ChevronDownIcon} from '@chakra-ui/icons';
import {
  useDisclosure,
  Link,
  Stack,
  Flex,
  Icon,
  Collapse,
  Text,
} from '@chakra-ui/react';
import {Menu} from './ChakraNavigationBar';
import {Link as RouterLink} from 'react-router-dom';

export const MobileNavItem = ({label, items}: Menu) => {
  const {isOpen, onToggle} = useDisclosure();

  return (
    <Stack spacing={4} onClick={items && onToggle}>
      <Flex
        py={2}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text fontWeight={600} mb={0}>
          {label}
        </Text>
        {items && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor="secondaryBackground"
          align={'start'}
        >
          {items &&
            items.map((item) => {
              if (!item.path) {
                return null;
              }

              return (
                <Link as={RouterLink} to={item.path} key={item.text} py={2}>
                  {item.text}
                </Link>
              );
            })}
        </Stack>
      </Collapse>
    </Stack>
  );
};
