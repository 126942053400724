import {Heading} from '@chakra-ui/react';

export interface PageHeaderProps {
  text: string;
}

export const PageHeader = ({text}: PageHeaderProps) => {
  return (
    <Heading as="h4" className="page-header">
      {text}
    </Heading>
  );
};
