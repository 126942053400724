import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {
  StartServiceRequest,
  StartServiceForMultipleStudentsRequest,
} from '../lib/api/types/Requests';
import {DurableActionResponse} from '../lib/api/types/Services';
import {fapper, FapperError} from '../utils/fapper';

const startService = async (
  data: StartServiceRequest,
  accessToken?: string,
): Promise<DurableActionResponse | undefined> => {
  if (!accessToken) {
    return new Promise<undefined>((response) => response(undefined));
  }
  const responseData: DurableActionResponse = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/services/start`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const startServiceForMultipleStudents = async (
  data: StartServiceForMultipleStudentsRequest,
  accessToken?: string,
): Promise<DurableActionResponse | undefined> => {
  if (!accessToken) {
    return new Promise<undefined>((response) => response(undefined));
  }
  const responseData: DurableActionResponse = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/services/startformultiplepatients`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const useStartService = (
  data: StartServiceRequest,
  accessToken?: string,
  options: UseQueryOptions<
    DurableActionResponse | undefined,
    FapperError,
    DurableActionResponse | undefined,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  return useQuery<DurableActionResponse | undefined, FapperError>(
    ['Services', data.accountId],
    () => startService(data, accessToken),
    options,
  );
};

export {useStartService, startServiceForMultipleStudents, startService};
