import {Box} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {defaultPageSize} from '../../../api/getServiceTimeslotSets';
import {getTimeslots, useTimeslots} from '../../../api/getTimeslots';
import {useGenerateTrainingDataUrl} from '../../../api/trainingData';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {AppointmentTimeslotsSelectorRequest} from '../../../lib/api/types/Requests';
import {
  AppointmentTimeslot,
  TimeslotListSection,
} from '../../../lib/api/types/Services';
import {
  addMonthAndSetFirstDate,
  addMonthAndSetLastDate,
} from '../../../utils/timeFormatting';
import {AppointmentTimeslotSelectorView} from './AppointmentTimeslotSelectorView';

export const AppointmentTimeslotSelectorController = () => {
  const initialStartDate = dayjs().format('YYYY-MM-DD').toString();
  const maxMonth = addMonthAndSetFirstDate(dayjs(initialStartDate), 12);
  const initialEndDate = dayjs()
    .add(2, 'month')
    .endOf('month')
    .format('YYYY-MM-DD')
    .toString();

  const {i18n} = useTranslation();
  const {state, dispatch} = useCustomerPortalState();
  const [showCapacity, setShowCapacity] = useState(false);
  const [hideShowMore, setHideShowMore] = useState(false);
  const [timeslotSections, setTimeslotSections] = useState<
    TimeslotListSection[]
  >([]);
  const [nextMonth, setNextMonth] = useState(
    dayjs(initialEndDate).add(1, 'month').toString(),
  );
  const [selectedMonth, setSelectedMonth] = useState(initialStartDate);
  const [selectedTimeslots, setSelectedTimeslots] = useState<
    AppointmentTimeslot[] | undefined
  >(state.service.data.timeslots);
  const [filter] = useState<AppointmentTimeslotsSelectorRequest>({
    planDefinitionId:
      state.service.data.currentAppointment?.appointments[0].planDefinitionId ||
      state.service.data.service?.planDefinitionId!!,
    accountId:
      state.service.data.currentAppointment?.accountId ||
      state.service.data.service?.accountId ||
      null,
    language: i18n.resolvedLanguage,
    startDate: initialStartDate,
    endDate: initialEndDate,
    minimumRequestedCapacity: state.service.data.isMultipleStudentsSelected
      ? state.service.data.student?.length
      : undefined,
    pageSize: defaultPageSize,
  });
  const [generatedTrainingDataUrl, setGeneratedTrainingDataUrl] =
    useState<string | undefined>();

  const {data, isLoading} = useTimeslots({
    ...filter,
  });

  const {data: generateUrlData, isLoading: isLoadingGenerateUrlData} =
    useGenerateTrainingDataUrl({
      accountId:
        state.service.data.currentAppointment?.accountId ||
        state.service.data.service?.accountId,
      planDefinitionId:
        state.service.data.currentAppointment?.appointments[0]
          .planDefinitionId || state.service.data.service?.planDefinitionId!!,
    });

  useEffect(() => {
    if (generateUrlData) {
      setGeneratedTrainingDataUrl(generateUrlData.url);
    }
  }, [generateUrlData]);

  useEffect(() => {
    if (data?.length && data[0].length > 0) {
      const currentDate = dayjs().format('YYYY-MM-DD');
      const sections: TimeslotListSection[] = [];

      for (let i = 0; i < 3; i++) {
        const date = dayjs(currentDate).add(i, 'month').toString();
        const section = transformTimeslotItemsToSection(data, date);
        sections.push(...section);
      }
      setTimeslotSections(sections);
      if (data.length > 0) {
        setShowCapacity(data[0][0].showAvailableCapacity);
      }
    }
  }, [data]);

  const onSelectTimeslots = (timeslots: AppointmentTimeslot[]) => {
    dispatch({type: 'SERVICE_STORE', payload: {timeslots}});
    setSelectedTimeslots(timeslots);
  };

  const onLoadMore = async () => {
    const nextM = addMonthAndSetFirstDate(dayjs(nextMonth), 1);
    const startDate = addMonthAndSetFirstDate(dayjs(nextMonth), 0);
    const endDate = addMonthAndSetLastDate(dayjs(nextMonth), 0);

    const newItems = await getTimeslots({
      ...filter,
      startDate,
      endDate,
    });
    const newSlots = transformTimeslotItemsToSection(newItems, startDate);
    setTimeslotSections([...timeslotSections, ...newSlots]);
    setSelectedMonth(nextMonth);
    setNextMonth(nextM);
    setHideShowMore(nextM === maxMonth);
  };

  const onChangeMonth = async (value: string) => {
    let currDate = dayjs();

    if (value.length > 0) {
      if (dayjs().isBefore(value)) {
        currDate = dayjs(value);
      }
    }
    const startDate = currDate.format('YYYY-MM-DD');
    const endDate = dayjs(currDate).endOf('month').format('YYYY-MM-DD');
    const nextM = addMonthAndSetFirstDate(currDate, 1);

    const newItems = await getTimeslots({
      ...filter,
      startDate: startDate,
      endDate: endDate,
    });
    const newSlots = transformTimeslotItemsToSection(newItems, startDate);
    setTimeslotSections(newSlots);
    setNextMonth(nextM);
    setSelectedMonth(startDate);
    setHideShowMore(nextM === maxMonth);
  };

  return (
    <Box>
      <AppointmentTimeslotSelectorView
        currentAppointment={state.service.data.currentAppointment}
        timeslotSections={timeslotSections || []}
        isLoading={isLoading}
        isLoadingGenerateUrlData={isLoadingGenerateUrlData}
        onSelectTimeslots={onSelectTimeslots}
        selectedTimeslots={selectedTimeslots}
        onLoadMore={onLoadMore}
        loadMoreNextMonth={nextMonth}
        hideMoreItems={hideShowMore}
        showCapacity={showCapacity}
        onChangeMonth={onChangeMonth}
        selectedMonth={selectedMonth}
        generatedTrainingDataUrl={generatedTrainingDataUrl}
      />
    </Box>
  );
};

export const transformTimeslotItemsToSection = (
  timeslotData: AppointmentTimeslot[][],
  date: string,
) => {
  const section: TimeslotListSection[] = [];
  const header = dayjs(addMonthAndSetFirstDate(dayjs(date), 0)).format(
    'YYYY-MM',
  );
  const slotItems = timeslotData.filter((slot) => {
    return dayjs(slot[0].startTime).format('YYYY-MM') === header;
  });
  section.push({
    header: header,
    items: slotItems,
  });
  return section;
};
