import {
  Button,
  Flex,
  GridItem,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {MoreVertical} from 'react-feather';
import {CertificateOverview} from '../../lib/api/types/Certificates';
import {determineExprirationLabel} from './determineExprirationLabel';
import i18n from 'i18next';
import {InfoOutlineIcon} from '@chakra-ui/icons';

export const getListItemForCertificate = (
  item: CertificateOverview,
  onStartService: () => void,
  onRenewalCertificate: () => void,
  onAddNote: () => void,
) => {
  const exprirationLabel = determineExprirationLabel(item.expirationDate);
  return [
    {
      name: 'firstname',
      label: '',
      node: (
        <GridItem d="flex" alignItems="center">
          {item.firstName}
          {item.trainingNote && (
            <Tooltip label={item.trainingNote} aria-label="Tooltip">
              <InfoOutlineIcon mx={1} w={4} h={4} />
            </Tooltip>
          )}
        </GridItem>
      ),
      options: {
        fontWeight: 'bold',
      },
    },
    {
      name: 'lastname',
      label: item.lastName || ' ',
      options: {
        fontWeight: 'bold',
      },
    },
    {
      name: 'training',
      label: item.planDefinitionName,
      options: {
        fontWeight: 'normal',
      },
    },
    {
      name: 'expirationDate',
      label: dayjs(item.expirationDate).format('DD-MM-YYYY'),
      options: {
        fontWeight: 'normal',
      },
    },
    {
      name: 'passedDate',
      label: '',
      node: (
        <GridItem bg={exprirationLabel.bg} px={2}>
          {exprirationLabel.label}
        </GridItem>
      ),
      options: {
        fontWeight: 'normal',
      },
    },
    {
      name: 'succeedingTrainingDate',
      label: item.succeedingTrainingDate
        ? dayjs(item.succeedingTrainingDate).format('DD-MM-YYYY')
        : ' ',
      options: {
        fontWeight: 'normal',
      },
    },
    {
      name: 'icon',
      label: '',
      node: (
        <GridItem px={2} alignSelf="center">
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<MoreVertical />}
              variant="no-outline"
              height="26px"
            />
            <MenuList p={2}>
              <Flex>
                <Text
                  as="span"
                  display="inline-block"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  {i18n.t('date_of_birth')}:
                </Text>
                <Text as="span" display="inline-block" fontSize="16px" ms={2}>
                  {dayjs(item.dateOfBirth).format('DD-MM-YYYY')}
                </Text>
              </Flex>
              <Flex>
                <Text
                  as="span"
                  display="inline-block"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  {i18n.t('employee_number')}:
                </Text>
                <Text as="span" display="inline-block" fontSize="16px" ms={2}>
                  {item.employeeNumber}
                </Text>
              </Flex>
              <Flex>
                <Text
                  as="span"
                  display="inline-block"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  {i18n.t('building')}:
                </Text>
                <Text as="span" display="inline-block" fontSize="16px" ms={2}>
                  {item.building}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Button onClick={onStartService} width="100%" mt={4} mb={2}>
                  {i18n.t('start_service')}
                </Button>

                <Button
                  colorScheme="secondary"
                  onClick={onRenewalCertificate}
                  w="100%"
                  mb={2}
                >
                  {i18n.t('certificates.no_renewal')}
                </Button>
                <Button colorScheme="secondary" onClick={onAddNote} w="100%">
                  {i18n.t('add_note')}
                </Button>
              </Flex>
            </MenuList>
          </Menu>
        </GridItem>
      ),
      options: {
        fontWeight: 'normal',
      },
    },
  ];
};
