import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {InfoOutlineIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {Service} from '../../../lib/api/types/Services';
import {Loader} from '../../Common/Loader';

interface Props {
  services: Service[];
  selectedService?: Service;
  onSelectService: (service: Service) => void;
  isLoading: boolean;
  isMultipleStudentsSelected: boolean;
  isInValidMultipleStudentSelection: boolean;
}

export const ServiceSelectorView = ({
  services,
  selectedService,
  onSelectService,
  isLoading,
  isMultipleStudentsSelected,
  isInValidMultipleStudentSelection,
}: Props) => {
  const {t} = useTranslation();

  return (
    <Box mb={10}>
      <>
        <Box height={16} d={['none', 'block']}></Box>
        <FormControl
          mb={2}
          p={4}
          borderWidth="1px"
          borderRadius="md"
          sx={{borderColor: 'gray.200'}}
        >
          <RadioGroup
            paddingStart={1}
            height={['auto', '400px']}
            overflowY="auto"
            value={selectedService?.planDefinitionId}
          >
            <Stack>
              {!isLoading &&
                services.length > 0 &&
                services.map((service) => (
                  <Radio
                    name={service.name}
                    value={service.planDefinitionId}
                    key={service.planDefinitionId.toString()}
                    isDisabled={!service.canBeStarted}
                    onChange={() => onSelectService(service)}
                  >
                    {service.name}
                    {!service.canBeStarted && (
                      <Tooltip
                        label={t('service.no_valid_certificate')}
                        shouldWrapChildren
                        placement="top"
                      >
                        <InfoOutlineIcon ml={1} />
                      </Tooltip>
                    )}
                  </Radio>
                ))}
              {!isLoading && services.length === 0 && (
                <Text>
                  {isMultipleStudentsSelected &&
                  !isInValidMultipleStudentSelection
                    ? t('service.select_multiple_students_for_result')
                    : t('service.no.services.for.user')}
                </Text>
              )}

              {isLoading && <Loader />}
            </Stack>
          </RadioGroup>
        </FormControl>
      </>
    </Box>
  );
};
