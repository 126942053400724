import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const dateInPast = dayjs().subtract(3, 'day').utc().format();
export const dateInFuture = dayjs().add(3, 'day').utc().format();
export const dateInFuturePlus3hours = dayjs()
  .add(3, 'day')
  .add(3, 'hour')
  .utc()
  .format();
export const dateOneMonthFromNow = dayjs().add(1, 'month').utc().format();
export const date11MonthsAgo = dayjs().subtract(11, 'month').utc().format();
export const dateInFutureDaysHours = (days: number, hours: number) => {
  return dayjs()
    .add(days, 'day')
    .add(hours, 'hours')
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};
export const dateInFutureMonthsDaysHours = (
  months: number,
  days: number,
  hours: number,
) => {
  return dayjs()
    .add(months, 'month')
    .add(days, 'day')
    .add(hours, 'hours')
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};

export const dateInPastMonthsDaysHours = (
  months: number,
  days: number,
  hours: number,
) => {
  return dayjs()
    .subtract(months, 'month')
    .subtract(days, 'day')
    .subtract(hours, 'hours')
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};

export const dateFromNow = (days: number, hours: number, minutes: number) => {
  return dayjs()
    .add(days, 'day')
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};
