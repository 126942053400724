import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {ServiceLocationsWithDates} from '../lib/api/types/Services';
import {ServiceLocationsRequest} from '../lib/api/types/Requests';
import {fapper, FapperError} from '../utils/fapper';

const getServiceLocationsWithDates = async (
  data: ServiceLocationsRequest,
): Promise<ServiceLocationsWithDates[]> => {
  const responseData: ServiceLocationsWithDates[] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/ext/v3/appointment/locations`,
    data,
  );
  return responseData;
};

const useServiceLocationsWithDates = (
  data: ServiceLocationsRequest,
  options: UseQueryOptions<
    ServiceLocationsWithDates[],
    FapperError,
    ServiceLocationsWithDates[],
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  return useQuery<ServiceLocationsWithDates[], FapperError>(
    ['ServiceLocationsWithDates', data],
    () => getServiceLocationsWithDates(data),
    options,
  );
};

export {useServiceLocationsWithDates, getServiceLocationsWithDates};
