import {ReactNode, useReducer} from 'react';
import {ErrorDispatchContext} from '../contexts/errorDispatchContext';
import {ErrorStateContext} from '../contexts/errorStateContext';
import {errorReducer, initialErrorState} from '../reducers/errorReducer';

export interface ContextProviderProps {
  children: ReactNode;
}

export const ContextProvider = ({children}: ContextProviderProps) => {
  const [errorState, errorDispatch] = useReducer(
    errorReducer,
    initialErrorState,
  );

  return (
    <ErrorStateContext.Provider value={errorState}>
      <ErrorDispatchContext.Provider value={errorDispatch}>
        {children}
      </ErrorDispatchContext.Provider>
    </ErrorStateContext.Provider>
  );
};
