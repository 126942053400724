import {Box, Divider, Heading, Text} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {TimeslotSetItems} from '../../../lib/api/types/AppointmentSet';
import {RadioCard} from '../../Common/RadioCard/RadioCard';

export interface Props {
  timeslotSet: TimeslotSetItems[];
  selectable?: boolean;
  isSetSelected?: boolean;
  showTitle?: boolean;
}

export const AppointmentSetView = ({
  timeslotSet,
  selectable = true,
  isSetSelected = false,
  showTitle = true,
  ...rest
}: Props) => {
  const {t} = useTranslation();
  const renderView = () => {
    return timeslotSet.map((timeslot, index) => (
      <Box key={index.toString()} mr={1} position="relative">
        {/* get availableCapacity from the first appointment. For all appointments it's the same value. */}
        {index === 0 &&
          timeslot.showAvailableCapacity &&
          timeslot.availableCapacity && (
            <Box
              title={t('service.multiple.available_capacity', {
                capacity: timeslot.availableCapacity,
              })}
              sx={{
                position: 'absolute',
                right: '-25px',
                top: '-13px',
                borderTop: 'solid 40px',
                borderTopColor: isSetSelected
                  ? 'primaryBackgroundDisabled'
                  : 'primary',
                borderLeft: 'solid 40px transparent',
                borderTopRightRadius: 'md',
                borderRight: 'solid 40px',
                borderRightColor: isSetSelected
                  ? 'primaryBackgroundDisabled'
                  : 'primary',
                borderBottom: 'solid 40px transparent',
              }}
            >
              <Box
                as="span"
                sx={{
                  position: 'absolute',
                  color: 'white',
                  top: '-28px',
                  right: '-24px',
                }}
              >
                {timeslot.availableCapacity}
              </Box>
            </Box>
          )}

        {showTitle && <Heading variant="h4">{timeslot.activityTitle}</Heading>}
        <Text as="span" d="block">
          {dayjs(timeslot.startTime).format('dd DD MMM YYYY')}
        </Text>
        <Text as="span" d="block">
          {dayjs(timeslot.startTime).format('HH:mm')} -{' '}
          {dayjs(timeslot.endTime).format('HH:mm')}
        </Text>
        {timeslot.practitionerName && (
          <Text as="span" d="block">
            {timeslot.practitionerName}
          </Text>
        )}

        {timeslot.address && (
          <Text as="span" d="block">
            {timeslot.address}
          </Text>
        )}
        {index < timeslotSet.length - 1 && (
          <Divider mb={5} mt={5} borderColor="primary" />
        )}
      </Box>
    ));
  };

  if (selectable) {
    return <RadioCard {...rest}>{renderView()}</RadioCard>;
  } else {
    return (
      <Box
        p={5}
        sx={{
          border: '4px solid',
          borderColor: 'dividerColor',
          borderRadius: 'md',
        }}
      >
        {renderView()}
      </Box>
    );
  }
};
