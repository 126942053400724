import {environment} from '../configuration';
import {PowerBIReports} from '../constants/PowerBIReports';
import {useAccountGroups} from './useAccountGroups';

export const useFetchPowerBIReport = (reportName: PowerBIReports) => {
  const groups = useAccountGroups();
  const groupId = environment.powerBi.groupId;
  const {type, mappings} = environment.powerBi.reports.find(
    (x) => x.name === reportName,
  ) as any;
  const mapping = mappings.find((x: any) => groups.includes(x.group));
  let id: string = '';
  if (mapping !== undefined) {
    id = mapping.id;
  }

  return {groupId, type, id};
};
