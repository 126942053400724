import {LogLevel, RedirectRequest} from '@azure/msal-browser';
import {environment} from './../configuration';

export const msalConfig = {
  auth: {
    clientId: environment.aad.clientId,
    authority: environment.aad.authority,
    redirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
export const loginRequest: RedirectRequest = {
  scopes: ['User.Read'],
};

export const powerBiTokenRequestScopes: string[] = [
  'https://analysis.windows.net/powerbi/api/.default',
];
export const apiTokenRequestScopes: string[] = [
  `${process.env.REACT_APP_API_URL}/.default`,
];
