import {UpdatePatientRequest} from '../lib/api/types/Requests';
import {fapper} from '../utils/fapper';

const sendUpdateStudent = async (
  accessToken: string,
  patientId?: string,
  data?: UpdatePatientRequest,
): Promise<string> => {
  const responseData = await fapper.patch(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/patient/update`,
    {
      patientId,
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const updateStudent = async (
  accessToken?: string,
  patientId?: string,
  data?: UpdatePatientRequest,
) => {
  if (!accessToken) {
    return new Promise<string | undefined>((resolve) => resolve(undefined));
  }

  return sendUpdateStudent(accessToken, patientId, data);
};

export {updateStudent};
