import dayjs from 'dayjs';
import {ServiceLocationsWithDates} from '../types/Services';
import {mockLocationsWithCapacity} from './mockLocationsWithCapacity';

const mocks = mockLocationsWithCapacity;

export const mockLocationsNoCapacity: ServiceLocationsWithDates[] = mocks.map(
  (mock) => {
    return {
      ...mock,
      activityDefinitionId: 'GUID_ACTIVITYDEFINITIONID_NO_CAPACITY',
      dates: mock.dates.map((d, i) => {
        return {
          ...d,
          showAvailableCapacity: false,
          date: dayjs(d.date).add(i, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        };
      }),
    };
  },
);
