import {useQuery} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {FilteredService} from '../lib/api/types/Services';
import {fapper, FapperError} from '../utils/fapper';

const sendGetFilteredServices = async (
  accessToken: string,
): Promise<FilteredService[]> => {
  const responseData: FilteredService[] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/appointments/services`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const getFilteredServices = async (accessToken?: string) => {
  if (!accessToken) {
    return new Promise<FilteredService[]>((resolve) => resolve([]));
  }

  return sendGetFilteredServices(accessToken);
};
const useFilteredServices = () => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<FilteredService[], FapperError>(
    ['FilteredServices', accessToken],
    () => getFilteredServices(accessToken),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export {useFilteredServices, getFilteredServices};
