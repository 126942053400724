import {LinkIcon, EditIcon} from '@chakra-ui/icons';
import {Box, Stack, Radio, RadioGroup} from '@chakra-ui/react';
import {MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Student} from '../../../lib/api/types/Student';
import {StudentHighlightedText} from './StudentHighlightedText';
import {searchKeyOptions} from './StudentSelectorController';

interface Props {
  students: Student[];
  selectedStudent?: Student[];
  highlight: string;
  searchKey: searchKeyOptions;
  onSelect: (student: Student) => void;
  showDecoupleModal: (
    event: MouseEvent<HTMLDivElement>,
    student: Student,
  ) => void;
  showNoteModal: (event: MouseEvent<HTMLDivElement>, student: Student) => void;
}
export const StudentRadioButtons = ({
  students,
  selectedStudent,
  highlight,
  searchKey,
  onSelect,
  showDecoupleModal,
  showNoteModal,
}: Props) => {
  const {t} = useTranslation();
  return (
    <RadioGroup value={selectedStudent?.length && selectedStudent[0].patientId}>
      <Stack>
        {students
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
          .map((student) => {
            return (
              <Box
                key={student.patientId.toString()}
                mb={2}
                position="relative"
              >
                <Radio
                  sx={{
                    marginTop: -5,
                  }}
                  value={student.patientId.toString()}
                  onChange={() => onSelect(student)}
                >
                  <StudentHighlightedText
                    student={student}
                    highlightString={highlight}
                    searchKey={searchKey}
                  />
                  <Box
                    title={t('add_note')}
                    position="absolute"
                    top="0"
                    right="40px"
                    onClick={(e) => showNoteModal(e, student)}
                  >
                    <EditIcon />
                  </Box>
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={(e) => showDecoupleModal(e, student)}
                  >
                    <LinkIcon />
                  </Box>
                </Radio>
              </Box>
            );
          })}
      </Stack>
    </RadioGroup>
  );
};
