import {
  Container,
  Box,
  Heading,
  Spinner,
  Flex,
  Button,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {
  AppointmentTimeslot,
  TimeslotListSection,
} from '../../../lib/api/types/Services';
import {Alert} from '../../Common/Alert';
import {AppointmentSetView as AppointmentSetViewDetails} from '../../Appointments/AppointmentSetPicker/AppointmentSetView';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {AlertCircle} from 'react-feather';
import {AppointmentTimeslotSelectorAppointment} from './types';
import {StudentMetaData} from '../Appointment/StudentMetaData';
import {TimeslotListController} from '../../Common/TimeslotList/TimeslotListController';
import {CopyIcon, EmailIcon} from '@chakra-ui/icons';

interface Props {
  timeslotSections: TimeslotListSection[];
  isLoading: boolean;
  isLoadingGenerateUrlData?: boolean;
  onSelectTimeslots?: (timeslots: AppointmentTimeslot[]) => void;
  selectedTimeslots?: AppointmentTimeslot[];
  currentAppointment?: AppointmentTimeslotSelectorAppointment;
  onLoadMore?: () => void;
  loadMoreNextMonth?: string;
  hideMoreItems?: boolean;
  showCapacity?: boolean;
  onChangeMonth: (month: string) => void;
  selectedMonth: string;
  generatedTrainingDataUrl?: string;
  hideCopyAndMailButtons?: boolean;
  title?: string;
}

export const AppointmentTimeslotSelectorView = ({
  timeslotSections,
  isLoading,
  isLoadingGenerateUrlData,
  onSelectTimeslots,
  selectedTimeslots,
  currentAppointment,
  onLoadMore,
  loadMoreNextMonth,
  showCapacity,
  onChangeMonth,
  selectedMonth,
  hideMoreItems,
  generatedTrainingDataUrl,
  hideCopyAndMailButtons = false,
  title,
}: Props) => {
  const {t} = useTranslation();
  const {state} = useCustomerPortalState();
  const {student} = state.service.data;
  const {isOpen, onOpen, onClose} = useDisclosure();

  const handleOpenCloseTooltip = () => {
    onOpen();
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const writeLink = async (val?: string) => {
    if (val) {
      try {
        await navigator.clipboard.writeText(val);
        handleOpenCloseTooltip();
      } catch (e: any) {
        alert(t('service.generated_url.not_available'));
      }
    } else {
      alert(t('service.generated_url.not_available'));
    }
  };

  const sendMail = async () => {
    let prefix: string | undefined = '';

    if (state.service.data.isMultipleStudentsSelected) {
      prefix = 'cursist';
    } else {
      prefix =
        state.service.data.student && state.service.data.student[0].firstName;
    }
    if (generatedTrainingDataUrl) {
      const training = state.service.data.service?.name;
      const body = t('service.send_trainingdata_mail_body', {
        training,
        name: prefix,
        url: generatedTrainingDataUrl,
        coordinator_name: `${state.service.data.userProfile?.firstName} ${state.service.data.userProfile?.lastName}`,
      });
      window.location.href = `mailto:?to=&subject=${t(
        'service.send_trainingdata_mail_subject',
        {training},
      )}&body=${encodeURIComponent(body)}`;
    } else {
      alert(t('service.send_generated_url.not_available'));
    }
  };

  return (
    <Container maxW="container.xl">
      <Box mb={10}>
        <Flex justifyContent="space-between">
          <Heading as="h1" variant="h1" mb={0}>
            {title || t('service.title')}
          </Heading>
          {!hideCopyAndMailButtons && !currentAppointment && (
            <Box>
              <Tooltip
                hasArrow
                label={t('service.generated_url_available')}
                isOpen={isOpen}
              >
                <Button
                  leftIcon={<CopyIcon w={6} h={6} />}
                  colorScheme="secondary"
                  onClick={() => writeLink(generatedTrainingDataUrl)}
                  mr={5}
                  disabled={isLoadingGenerateUrlData}
                  rightIcon={isLoadingGenerateUrlData ? <Spinner /> : undefined}
                >
                  {t('copy_link')}
                </Button>
              </Tooltip>

              <Button
                leftIcon={<EmailIcon w={6} h={6} />}
                colorScheme="primary"
                onClick={() => sendMail()}
                disabled={isLoadingGenerateUrlData}
                rightIcon={isLoadingGenerateUrlData ? <Spinner /> : undefined}
              >
                {t('send_to_cursist')}
              </Button>
            </Box>
          )}
        </Flex>
        <Box mb={10}>
          <StudentMetaData />
          {student && student.length > 1 && (
            <Box my={4}>
              <Alert
                message={t('service.capacity_info_label', {
                  capacity: student.length,
                })}
                icon={AlertCircle}
              />
            </Box>
          )}
        </Box>
        {currentAppointment && (
          <>
            <Heading as="h2" variant="h2">
              {t('service.current_appointment')}
            </Heading>
            <Flex>
              <AppointmentSetViewDetails
                selectable={false}
                showTitle={false}
                timeslotSet={currentAppointment.appointments.map(
                  (appointment) => {
                    return {
                      activityTitle: appointment.appointmentTitle,
                      startTime: appointment.startTime,
                      endTime: appointment.endTime,
                      practitionerName: appointment.requester,
                      address: appointment.address,
                    };
                  },
                )}
              />
            </Flex>
            <Heading as="h2" variant="h2" mt={16}>
              {t('service.adjust_to')}
            </Heading>
          </>
        )}
        {isLoading && <Spinner />}
        <>
          {!isLoading && (
            <Box>
              <TimeslotListController
                timeslotSections={timeslotSections}
                onSelect={onSelectTimeslots}
                selected={selectedTimeslots}
                onLoadMore={onLoadMore}
                loadMoreNextMonth={loadMoreNextMonth}
                showCapacity={showCapacity}
                onChangeMonth={onChangeMonth}
                selectedMonth={selectedMonth}
                hideMoreItems={hideMoreItems}
              />
            </Box>
          )}
        </>
      </Box>
    </Container>
  );
};
