import {useQuery} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {FilteredService} from '../lib/api/types/Services';
import {fapper, FapperError} from '../utils/fapper';

const sendGetFilteredCertificatesServices = async (
  accessToken: string,
): Promise<FilteredService[]> => {
  const responseData: FilteredService[] = await fapper.get(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/certificates/services`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const getFilteredCertificatesServices = async (accessToken?: string) => {
  if (!accessToken) {
    return new Promise<FilteredService[] | null>((resolve) => resolve(null));
  }

  return sendGetFilteredCertificatesServices(accessToken);
};
const useFilteredCertificatesServices = () => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<FilteredService[] | null, FapperError>(
    ['FilteredCertificatesServices', accessToken],
    () => getFilteredCertificatesServices(accessToken),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export {useFilteredCertificatesServices, getFilteredCertificatesServices};
