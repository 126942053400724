import {AppointmentDataPayLoad} from '../../../reducers/appointmentsReducer';
import {CertificatesDataPayload} from '../../../reducers/certificatesReducer';
import {ServiceDataPayLoad} from './Services';

export type AppointmentPages = 'appointmentsOverview' | 'appointmentDetails';
export type CertificatesPages = 'certificatesOverview';

export type CustomerPortalServiceState = {
  data: ServiceDataPayLoad;
};

export type CustomerPortalAppointmentState = {
  page: AppointmentPages;
  data: AppointmentDataPayLoad;
};

export type CustomerPortalCertificatesState = {
  page: CertificatesPages;
  data: CertificatesDataPayload;
};

export type CustomerPortalState = {
  service: CustomerPortalServiceState;
  appointment: CustomerPortalAppointmentState;
  certificates: CustomerPortalCertificatesState;
};

export const defaultServiceState: CustomerPortalServiceState = {
  data: {
    service: undefined,
    student: undefined,
    location: undefined,
    date: undefined,
    timeslots: undefined,
    userProfile: undefined,
    currentAppointment: undefined,
    isMultipleStudentsSelected: false,
  },
};
export const defaultAppointmentState: CustomerPortalAppointmentState = {
  page: 'appointmentsOverview',
  data: {
    filter: {
      startDate: undefined,
      patientSearchFilterValue: undefined,
      planDefinitionId: undefined,
      pageSize: undefined,
      page: undefined,
    },
    id: undefined,
    setId: undefined,
  },
};

export const defaultCertificatesState: CustomerPortalCertificatesState = {
  page: 'certificatesOverview',
  data: {
    filter: {
      firstname: undefined,
      lastname: undefined,
      plandefinitionId: undefined,
      expireDate: undefined,
      expireRange: undefined,
      hasSucceedingTrainingDate: undefined,
    },
  },
};
