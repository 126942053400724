import {Box, Grid, GridItem, Text, Flex, Divider} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {Fragment} from 'react';
import {ArrowRightCircle} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {Appointment} from '../../../lib/api/types/AppointmentsOverview';
import {colors} from '../../../theme/colors';

interface Props {
  appointment: Appointment;
  onClick: () => void;
}

export const ListItem = ({appointment, onClick}: Props) => {
  const {t} = useTranslation();
  function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  return (
    <Grid
      templateColumns="0.5fr repeat(2, 1fr) 0.5fr"
      gap={4}
      mb={2}
      fontSize="medium"
      alignItems="center"
      _hover={{bg: '#F2F6F9'}}
      cursor={!appointment.isProcessing ? 'pointer' : undefined}
      onClick={!appointment.isProcessing ? onClick : undefined}
      opacity={!appointment.isProcessing ? 1 : 0.5}
    >
      <GridItem ps="6">
        <Box>
          <Text as="span" d="block" fontWeight="bold">
            {capitalizeFirstLetter(
              dayjs(appointment.startDates[0]).format('MMMM YYYY'),
            )}
          </Text>
          <Flex lineHeight={1} alignItems="center" pb={1}>
            <Flex position="relative">
              {[...Array(appointment.startDates.length)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '5px',
                    height: '5px',
                    borderRadius: '100%',
                    position: 'relative',
                    background: 'secondaryBackground',
                    border: '1px solid white',
                    zIndex: appointment.startDates.length - index,
                    marginLeft: index > 0 ? '-2px' : 0,
                  }}
                />
              ))}
            </Flex>
            {appointment.startDates.map((date, index) => (
              <Fragment key={date}>
                <Text mx={1} as="span" d="block" fontSize={14}>
                  {dayjs(date).format('DD-MM')}
                </Text>
                {index < appointment.startDates.length - 1 && (
                  <Divider
                    orientation="vertical"
                    h="10px"
                    borderColor="secondaryBackground"
                  />
                )}
              </Fragment>
            ))}
          </Flex>
        </Box>
      </GridItem>
      <GridItem ps="6">
        <Text as="span">
          {appointment.patientFullName}{' '}
          {appointment.employeeNumber
            ? '(' + appointment.employeeNumber + ')'
            : ''}
        </Text>
      </GridItem>
      <GridItem ps="6">
        <Text as="span">{appointment.planDefinitionName}</Text>
      </GridItem>
      <GridItem justifySelf="end" pe="2">
        {appointment.isProcessing ? (
          <Text as="span" fontStyle="italic">
            {t('appointment.is_processing')}
          </Text>
        ) : (
          <ArrowRightCircle color={colors.visualListBackground} />
        )}
      </GridItem>
    </Grid>
  );
};
