import {Flex, Button} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';

interface Props {
  onLoadMore: () => void;
  date?: string;
}

export const LoadMore = ({onLoadMore, date}: Props) => {
  const {t} = useTranslation();

  return (
    <Flex>
      <Button
        bg="secondaryBackground"
        textColor="primaryBackground"
        flex={1}
        onClick={onLoadMore}
        rightIcon={<Plus />}
      >
        {date
          ? t('appointments.load_more_month', {
              month: dayjs(date).format('MMMM'),
            })
          : t('appointments.load_more')}
      </Button>
    </Flex>
  );
};
