import {
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  Container,
  CloseButton,
  AlertStatus,
  As,
} from '@chakra-ui/react';

export interface Props {
  message: string;
  status?: AlertStatus;
  icon?: As<any>;
  onClose?: () => void;
}

export const Alert = ({message, status = 'info', icon, onClose}: Props) => {
  return (
    <Container mb={4} p={0} ml={0} mr={0} maxWidth="100%">
      <ChakraAlert borderRadius={8} status={status}>
        <AlertIcon as={icon} />
        <AlertTitle>{message}</AlertTitle>
        {onClose && (
          <CloseButton
            onClick={onClose}
            position="absolute"
            right="8px"
            top="8px"
          />
        )}
      </ChakraAlert>
    </Container>
  );
};
