import {CertificateOverview} from '../types/Certificates';
import {
  dateInFutureMonthsDaysHours,
  dateInFutureDaysHours,
  dateInPastMonthsDaysHours,
} from './dates';

export const mockCertificateOverview: CertificateOverview[] = [
  {
    patientId: '61eaabeebed472113dcb088d',
    firstName: 'Catalina',
    lastName: 'Case',
    dateOfBirth: '12-11-1997',
    employeeNumber: '146347',
    building: 'SPL-400',
    planDefinitionId: '678de674-da0f-ec11-b6e6-002248842310',
    planDefinitionName: 'BHV Herhaling',
    expirationDate: dateInFutureMonthsDaysHours(1, 0, 0),
    succeedingTrainingDate: dateInFutureDaysHours(32, 0),
    observationId: '23b225f2-7964-eb11-a812-000d3ac22751',
    trainingNote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu augue lobortis, sollicitudin leo at, egestas turpis. Morbi placerat nulla et fermentum sollicitudin.',
  },
  {
    patientId: '61eaabeecfb0945cc0c8511b',
    firstName: 'Annabelle',
    lastName: 'Allen',
    dateOfBirth: '05-04-2011',
    employeeNumber: '154556',
    building: null,
    planDefinitionId: '678de674-da0f-ec11-b6e6-002248842310',
    planDefinitionName: 'BHV Herhaling',
    expirationDate: dateInFutureMonthsDaysHours(5, 0, 0),
    succeedingTrainingDate: undefined,
    observationId: '43b225f2-7964-eb11-a812-000d3ac22751',
    trainingNote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu augue lobortis, sollicitudin leo at, egestas turpis. Morbi placerat nulla et fermentum sollicitudin.',
  },
  {
    patientId: '61eaabeeaf695084512c2626',
    firstName: 'Nixon',
    lastName: 'Burks',
    dateOfBirth: '10-10-1974',
    employeeNumber: '105202',
    building: 'SPL-400',
    planDefinitionId: '678de674-da0f-ec11-b6e6-0022488423a6',
    planDefinitionName: 'Ploegleider Basis',
    expirationDate: dateInPastMonthsDaysHours(4, 0, 0),
    succeedingTrainingDate: undefined,
    observationId: '43b225f2-7964-eb11-a812-000d3ac22752',
    trainingNote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu augue lobortis, sollicitudin leo at, egestas turpis. Morbi placerat nulla et fermentum sollicitudin.',
  },
  {
    patientId: '61eaabee45ab8f3cf2b3068c',
    firstName: 'Velma',
    lastName: 'Dudley',
    dateOfBirth: '24-01-2012',
    employeeNumber: '193786',
    building: 'SPL-400',
    planDefinitionId: '678de674-da0f-ec11-b6e6-0022488423a7',
    planDefinitionName: 'Ploegleider Herhaling',
    expirationDate: dateInFutureMonthsDaysHours(2, 0, 0),
    succeedingTrainingDate: dateInFutureDaysHours(15, 0),
    observationId: '43b225f2-7964-eb11-a812-000d3ac22753',
    trainingNote: null,
  },
  {
    patientId: '61eaabee63736d5f6688b679',
    firstName: 'Fuentes',
    lastName: 'Wilder',
    dateOfBirth: '14-06-1983',
    employeeNumber: '178950',
    building: 'SPL-400',
    planDefinitionId: '678de674-da0f-ec11-b6e6-0022488423a9',
    planDefinitionName: 'BHV Basis',
    expirationDate: dateInFutureDaysHours(15, 0),
    succeedingTrainingDate: '',
    observationId: '43b225f2-7964-eb11-a812-000d3ac22754',
    trainingNote: null,
  },
];
