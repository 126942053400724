import {
  Box,
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useUserProfile} from '../../../api/getUserProfile';
import {Loader} from '../../../components/Common/Loader';
import {AlreadyStarted} from '../../../components/Service/Appointment/AlreadyStarted';
import {ServiceSelectorController} from '../../../components/Service/ServiceSelector/ServiceSelectorController';
import {StepperButtonBar} from '../../../components/Service/StepperButtonBar/StepperButtonBar';
import {StudentSelectorController} from '../../../components/Service/StudentSelector/StudentSelectorController';
import {RootRoute, ServiceRouteDetails} from '../../../constants/Routes';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {useErrorDispatch} from '../../../contexts/errorDispatchContext';
import {ErrorActionType} from '../../../reducers/errorReducer';

export const ServiceStartPage = () => {
  const errorDispatch = useErrorDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {state, dispatch} = useCustomerPortalState();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const nextButtonIsEnabled =
    state.service.data.student && state.service.data.service;
  const [
    isInValidMultipleStudentSelection,
    setIsInValidMultipleStudentSelection,
  ] = useState(false);
  const [
    toggleIsInValidMultipleStudentSelection,
    setToggleIsInValidMultipleStudentSelection,
  ] = useState(false);

  const {data, isLoading, isFetching, error, refetch} = useUserProfile();

  useEffect(() => {
    if (toggleIsInValidMultipleStudentSelection) {
      onOpen();
    }
  }, [toggleIsInValidMultipleStudentSelection, onOpen]);

  useEffect(() => {
    dispatch({
      type: 'SERVICE_STORE',
      payload: {
        currentAppointment: undefined,
      },
    });

    if (data) {
      dispatch({
        type: 'SERVICE_STORE',
        payload: {userProfile: data},
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (!isLoading && error) {
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error,
          refetch,
        },
      });
    }
  }, [isLoading, error, errorDispatch, refetch]);

  const onNext = () => {
    if (state.service.data.service?.isActive) {
      onOpen();
    } else {
      onNextPage();
    }
  };

  const onNextPage = () => {
    navigate(ServiceRouteDetails);
  };

  const onPreviousClick = () => {
    navigate(RootRoute);
    dispatch({
      type: 'SERVICE_CLEAR',
    });
  };

  const onIsInValidMultipleStudentSelection = (value: boolean) => {
    setToggleIsInValidMultipleStudentSelection(value);
    setIsInValidMultipleStudentSelection(value);
  };

  const onCloseModal = () => {
    setToggleIsInValidMultipleStudentSelection(false);
    onClose();
  };

  return (
    <>
      <Container maxW="container.xl" pt={8} pb={28}>
        <Box>
          {isLoading || isFetching ? (
            <Loader />
          ) : (
            data && (
              <>
                <Heading as="h1" variant="h1">
                  {t('service.start.title')}
                </Heading>
                <SimpleGrid columns={[1, 2]} spacing={[0, 20]}>
                  <Box>
                    <Heading variant="h2">{t('service.start.who')}</Heading>
                    <StudentSelectorController
                      onIsInValidMultipleStudentSelection={
                        onIsInValidMultipleStudentSelection
                      }
                    />
                  </Box>

                  <Box>
                    <Heading variant="h2">{t('service.which')}</Heading>
                    {state.service.data.student &&
                      state.service.data.student.length > 0 && (
                        <ServiceSelectorController
                          students={state.service.data.student}
                          isMultipleStudentsSelected={
                            state.service.data.isMultipleStudentsSelected!!
                          }
                          isInValidMultipleStudentSelection={
                            isInValidMultipleStudentSelection
                          }
                        />
                      )}
                  </Box>
                </SimpleGrid>
                <StepperButtonBar
                  onPreviousClick={() => {
                    onPreviousClick();
                  }}
                  onNextClick={() => {
                    onNext();
                  }}
                  onCancelClick={() => {
                    navigate('/');
                  }}
                  nextButtonLabel={t('next')}
                  nextButtonIsDisabled={!nextButtonIsEnabled}
                  isRunningStartService={false}
                />
              </>
            )
          )}
        </Box>
      </Container>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay backdropFilter="auto" backdropBrightness="60%" />
        <ModalContent>
          <ModalBody mt={8} pt={2} px={8}>
            <ModalCloseButton />
            {isInValidMultipleStudentSelection ? (
              <>
                {t('Je kan alleen cursisten selecteren van hetzelfde bedrijf.')}
              </>
            ) : (
              <AlreadyStarted />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
