import {base} from './Base';

const baseStyle = {
  fontFamily: 'heading',
  fontWeight: 'normal',
};

const defaultProps = {
  size: 'xl',
};

const variants = {
  ...base.headings,
};

export const Heading = {
  baseStyle,
  defaultProps,
  variants,
};
