import {ServiceTimeslot} from '../types/Services';

export const mockTimeslotsWithCapacity: ServiceTimeslot[] = [
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T07:40:00Z',
    endTime: '2022-01-26T07:45:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T07:45:00Z',
    endTime: '2022-01-26T07:50:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T07:50:00Z',
    endTime: '2022-01-26T07:55:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T07:55:00Z',
    endTime: '2022-01-26T08:00:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:00:00Z',
    endTime: '2022-01-26T08:05:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:05:00Z',
    endTime: '2022-01-26T08:10:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:10:00Z',
    endTime: '2022-01-26T08:15:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:15:00Z',
    endTime: '2022-01-26T08:20:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:20:00Z',
    endTime: '2022-01-26T08:25:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:25:00Z',
    endTime: '2022-01-26T08:30:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:30:00Z',
    endTime: '2022-01-26T08:35:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:35:00Z',
    endTime: '2022-01-26T08:40:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:40:00Z',
    endTime: '2022-01-26T08:45:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:45:00Z',
    endTime: '2022-01-26T08:50:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:50:00Z',
    endTime: '2022-01-26T08:55:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T08:55:00Z',
    endTime: '2022-01-26T09:00:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:00:00Z',
    endTime: '2022-01-26T09:05:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:05:00Z',
    endTime: '2022-01-26T09:10:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:10:00Z',
    endTime: '2022-01-26T09:15:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: '21f7aac7-f679-ec11-8d21-0022489cb2ea',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:15:00Z',
    endTime: '2022-01-26T09:20:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:40:00Z',
    endTime: '2022-01-26T09:45:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:45:00Z',
    endTime: '2022-01-26T09:50:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:50:00Z',
    endTime: '2022-01-26T09:55:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T09:55:00Z',
    endTime: '2022-01-26T10:00:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T10:00:00Z',
    endTime: '2022-01-26T10:05:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T10:05:00Z',
    endTime: '2022-01-26T10:10:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T10:10:00Z',
    endTime: '2022-01-26T10:15:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T10:15:00Z',
    endTime: '2022-01-26T10:20:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T10:20:00Z',
    endTime: '2022-01-26T10:25:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
  {
    resourceAvailabilityId: 'b7d819ca-f679-ec11-8d21-0022489a7706',
    activityDefinitionId: '180b727b-0072-eb11-a812-000d3ada94ea',
    practitionerId: '90b6ce51-2479-ec11-8d21-0022489a7706',
    startTime: '2022-01-26T10:25:00Z',
    endTime: '2022-01-26T10:30:00Z',
    availableCapacity: 6,
    showAvailableCapacity: true,
  },
];
