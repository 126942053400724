import {
  Box,
  Input,
  FormControl,
  Text,
  Heading,
  Select,
  FormLabel,
  Switch,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Spinner,
  Textarea,
  Flex,
} from '@chakra-ui/react';
import {ChangeEvent, useRef, useState, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Student} from '../../../lib/api/types/Student';
import {Alert} from '../../Common/Alert';
import {Loader} from '../../Common/Loader';
import {StudentCheckBoxes} from './StudentCheckboxes';
import {StudentRadioButtons} from './StudentRadioButtons';
import {searchKeyOptions} from './StudentSelectorController';

interface Props {
  isLoading: boolean;
  students: Student[];
  selectedStudent?: Student[];
  search: (key: searchKeyOptions, value: string) => void;
  onSelect: (student: Student) => void;
  onSelectCheckbox: (
    student: Student,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
  handleChangeSearchKey: () => void;
  onMultipleStudentsSelected: (value: boolean) => void;
  isMultipleStudentsSelected: boolean;
  onFinish: (val: boolean) => void;
  onDecoupleStudent: () => void;
  onSetTrainingNote: (value: string) => void;
  trainingNote?: string;
  onUpdateStudent: () => void;
  onSelectStudent: (student: Student | undefined) => void;
  selectedSingleStudent?: Student;
  updateStudentError?: string;
  updateStudentIsLoading: boolean;
  updateStudentIsSuccess: boolean;
  decoupleStudentError?: string;
  decoupleStudentIsLoading: boolean;
  decoupleStudentIsSuccess: boolean;
}

export const StudentSelectorView = ({
  isLoading,
  students,
  selectedStudent,
  search,
  onSelect,
  onSelectCheckbox,
  handleChangeSearchKey,
  onMultipleStudentsSelected,
  isMultipleStudentsSelected,
  onFinish,
  onDecoupleStudent,
  onSetTrainingNote,
  updateStudentIsLoading,
  trainingNote,
  onUpdateStudent,
  onSelectStudent,
  selectedSingleStudent,
  updateStudentError,
  updateStudentIsSuccess,
  decoupleStudentError,
  decoupleStudentIsLoading,
  decoupleStudentIsSuccess,
}: Props) => {
  const {t} = useTranslation();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenNote,
    onOpen: onOpenNote,
    onClose: onCloseNote,
  } = useDisclosure();
  const [searchKey, setSearchKey] = useState<searchKeyOptions>('lastName');
  const [highlight, setHighlight] = useState<string>('');
  const searchValueRef = useRef<HTMLInputElement | null>(null);

  const onChangeSearchKey = (e: ChangeEvent<HTMLSelectElement>) => {
    handleChangeSearchKey();
    if (searchValueRef.current) {
      searchValueRef.current.value = '';
    }
    setSearchKey(e.target.value as searchKeyOptions);
  };

  const onMultipleStudentsChange = (event: ChangeEvent<HTMLInputElement>) => {
    onMultipleStudentsSelected(event.target.checked);
  };

  const showDecoupleModal = (
    event: MouseEvent<HTMLDivElement>,
    student: Student,
  ) => {
    event.preventDefault();
    onFinish(false);
    onSelectStudent(student);
    onOpen();
  };

  const onCloseModal = () => {
    onClose();
    onCloseNote();
    onSelectStudent(undefined);
    onFinish(true);
  };

  const showNoteModal = (
    event: MouseEvent<HTMLDivElement>,
    student: Student,
  ) => {
    event.preventDefault();
    onSelectStudent(student);
    onOpenNote();
  };

  return (
    <>
      <Box mb={10}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Box>
            <Heading as="h3" variant="h3">
              {t('filter')}
            </Heading>
            <FormControl mb={2}>
              <Select onChange={onChangeSearchKey} value={searchKey}>
                <option value="lastName">
                  {t('service.last_name')} {t('contains')}
                </option>
                <option value="firstName">
                  {t('service.first_name')} {t('contains')}
                </option>
                <option value="employeeNumber">
                  {t('service.employee_number')}
                </option>
              </Select>
            </FormControl>
            <FormControl mb={2}>
              <Box>
                <Input
                  ref={searchValueRef}
                  id="searchValue"
                  autoComplete="off"
                  placeholder={t('service.enter_value')}
                  onChange={({target}) => {
                    setHighlight(target.value);
                    search(searchKey, target.value);
                  }}
                />
              </Box>
            </FormControl>
            <FormControl my={6} display="flex" alignItems="center">
              <Switch
                id="multiple-students"
                mr={4}
                size="lg"
                colorScheme="green"
                onChange={onMultipleStudentsChange}
                isChecked={isMultipleStudentsSelected}
              />
              <FormLabel htmlFor="multiple-students" mb={0}>
                {t('service.select_multiple_students')}
              </FormLabel>
            </FormControl>
            <FormControl
              mb={2}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              sx={{borderColor: 'gray.200'}}
              height="400px"
              overflowY="auto"
            >
              {!isLoading && students.length > 0 && (
                <>
                  {isMultipleStudentsSelected ? (
                    <StudentCheckBoxes
                      students={students}
                      selectedStudent={selectedStudent}
                      searchKey={searchKey}
                      highlight={highlight}
                      onSelect={onSelectCheckbox}
                      showDecoupleModal={showDecoupleModal}
                      showNoteModal={showNoteModal}
                    />
                  ) : (
                    <StudentRadioButtons
                      students={students}
                      selectedStudent={selectedStudent}
                      searchKey={searchKey}
                      highlight={highlight}
                      onSelect={onSelect}
                      showDecoupleModal={showDecoupleModal}
                      showNoteModal={showNoteModal}
                    />
                  )}
                </>
              )}
              {!isLoading && students.length === 0 && (
                <Text as="span" d="block">
                  {t('service.no_students_found')}
                </Text>
              )}
              {isLoading && <Loader />}
            </FormControl>
          </Box>
        </form>
      </Box>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay backdropFilter="auto" backdropBrightness="60%" />
        <ModalContent>
          <ModalBody mt={8} pt={2} px={8}>
            <>
              <ModalCloseButton onClick={onCloseModal} />

              <Text>{t('student.decouple')}</Text>

              {decoupleStudentError && (
                <Alert status="error" message={decoupleStudentError}></Alert>
              )}

              {decoupleStudentIsSuccess && (
                <Alert
                  status="success"
                  message={t('student.decouple.success', {
                    student_name: `${selectedSingleStudent?.firstName} ${selectedSingleStudent?.lastName}`,
                  })}
                ></Alert>
              )}

              <ModalFooter>
                {decoupleStudentIsSuccess ? (
                  <Button
                    colorScheme="secondary"
                    mr={3}
                    onClick={() => onCloseModal()}
                  >
                    {t('close')}
                  </Button>
                ) : (
                  <>
                    <Button
                      colorScheme="primary"
                      mr={3}
                      onClick={() => onDecoupleStudent()}
                      disabled={decoupleStudentIsLoading}
                      rightIcon={decoupleStudentIsLoading ? <Spinner /> : <></>}
                    >
                      {t('yes')}
                    </Button>
                    <Button
                      colorScheme="secondary"
                      mr={3}
                      onClick={() => onCloseModal()}
                      disabled={decoupleStudentIsLoading}
                    >
                      {t('no')}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenNote} onClose={onCloseModal}>
        <ModalOverlay backdropFilter="auto" backdropBrightness="60%" />
        <ModalContent>
          <ModalBody mt={8} pt={2} px={8}>
            <>
              <ModalCloseButton onClick={onCloseModal} />
              <Flex flexDirection="column" alignItems="flex-start">
                <Heading as="h3" variant="h3">
                  {t('add_note')}
                </Heading>

                <Box mb={4} w="100%">
                  <Textarea
                    placeholder={t('student.update.input_placeholder')}
                    value={trainingNote || ''}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      onSetTrainingNote(event.target.value)
                    }
                  />
                </Box>

                {updateStudentError && (
                  <Alert status="error" message={updateStudentError}></Alert>
                )}

                {updateStudentIsSuccess && (
                  <Alert
                    status="success"
                    message={t('student.update.success')}
                  ></Alert>
                )}

                <ModalFooter w="100%">
                  <Button
                    alignSelf="flex-end"
                    colorScheme="secondary"
                    mr={3}
                    onClick={() => onUpdateStudent()}
                    disabled={updateStudentIsLoading}
                    rightIcon={updateStudentIsLoading ? <Spinner /> : <></>}
                  >
                    {t('save')}
                  </Button>
                </ModalFooter>
              </Flex>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
