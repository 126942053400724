import {EditIcon, LinkIcon} from '@chakra-ui/icons';
import {Box, Checkbox, CheckboxGroup, Stack} from '@chakra-ui/react';
import {ChangeEvent, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Student} from '../../../lib/api/types/Student';
import {StudentHighlightedText} from './StudentHighlightedText';
import {searchKeyOptions} from './StudentSelectorController';

interface Props {
  students: Student[];
  selectedStudent?: Student[];
  highlight: string;
  searchKey: searchKeyOptions;
  onSelect: (student: Student, e: ChangeEvent<HTMLInputElement>) => void;
  showDecoupleModal: (
    event: MouseEvent<HTMLDivElement>,
    student: Student,
  ) => void;

  showNoteModal: (event: MouseEvent<HTMLDivElement>, student: Student) => void;
}
export const StudentCheckBoxes = ({
  students,
  selectedStudent,
  highlight,
  searchKey,
  onSelect,
  showDecoupleModal,
  showNoteModal,
}: Props) => {
  const {t} = useTranslation();
  return (
    <CheckboxGroup value={selectedStudent?.map((st) => st.patientId)}>
      <Stack>
        {students
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
          .map((student) => {
            return (
              <Box
                key={student.patientId.toString()}
                mb={2}
                position="relative"
              >
                <Checkbox
                  name="student"
                  value={student.patientId.toString()}
                  onChange={(e) => onSelect(student, e)}
                  w="100%"
                >
                  <StudentHighlightedText
                    student={student}
                    highlightString={highlight}
                    searchKey={searchKey}
                  />
                  <Box
                    title={t('add_note')}
                    position="absolute"
                    top="0"
                    right="40px"
                    onClick={(e) => showNoteModal(e, student)}
                  >
                    <EditIcon />
                  </Box>
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={(e) => showDecoupleModal(e, student)}
                  >
                    <LinkIcon />
                  </Box>
                </Checkbox>
              </Box>
            );
          })}
      </Stack>
    </CheckboxGroup>
  );
};
