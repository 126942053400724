import {IRoutesConfig} from 'auth-react-router';
import {Loader} from './components/Common/Loader';
import {LoginPage} from './pages/LoginPage';
import {getConfiguratedRoutes} from './routesConfiguration';
import {Navigate} from 'react-router-dom';
import {UnauthorizedPage} from './pages/UnauthorizedPage';
import {TrainingDataRoute} from './constants/Routes';
import {TrainingData} from './pages/Trainingdata';

export const ClientManagerGroup: string = process.env
  .REACT_APP_AAD_GROUP_CLIENT_MANAGER as string;
export const HRManagerGroup: string = process.env
  .REACT_APP_AAD_GROUP_HR_MANAGER as string;
export const KHSGroup: string = process.env.REACT_APP_AAD_GROUP_KHS as string;
export const MedischSpecialistGroup: string = process.env
  .REACT_APP_AAD_GROUP_MEDISCH_SPECIALIST as string;
export const PcrGroup: string = process.env.REACT_APP_AAD_GROUP_PCR as string;
export const TrainingCoordinatorGroup: string = process.env
  .REACT_APP_AAD_GROUP_TRAINING_COORDINATOR as string;
export const AllGroups: string[] = [
  ClientManagerGroup,
  HRManagerGroup,
  KHSGroup,
  MedischSpecialistGroup,
  PcrGroup,
  TrainingCoordinatorGroup,
];

export const roles = {
  ClientManagerGroup,
  HRManagerGroup,
  KHSGroup,
  MedischSpecialistGroup,
  PcrGroup,
  TrainingCoordinatorGroup,
};

// routes definition
export const routes: IRoutesConfig = {
  publicRedirectRoute: '/login', // redirect to `/login` when unauthorized is trying to access private routes
  privateRedirectRoute: '/', // redirect to `/` when authorized user access a public route
  defaultFallback: <Loader />,
  // if the role of the user (`userRole` props) is not contained in the route `roles`
  InvalidUserRoleFallback: () => <UnauthorizedPage />,
  public: [
    {
      path: '/login',
      component: <LoginPage />,
    },
  ],
  private: getConfiguratedRoutes(),
  common: [
    {
      path: '*',
      component: <Navigate to="/" />,
    },
    {
      path: TrainingDataRoute,
      component: <TrainingData />,
    },
  ],
};
