import {Flex, Container, BoxProps} from '@chakra-ui/react';
import {ReactNode} from 'react';

interface Props extends BoxProps {
  children: ReactNode[];
}
export const NavBarContainer = ({children, ...props}: Props) => {
  return (
    <Container maxW="container.xl">
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        mb={8}
        py={4}
        {...props}
      >
        {children}
      </Flex>
    </Container>
  );
};
