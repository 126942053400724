export const RootRoute: string = '/';
export const LoginRoute: string = '/login';
export const TimelineRoute: string = '/timeline';
export const AppointmentRoute: string = '/appointment';
export const Covid19Route: string = '/covid-19';
export const QuestionnaireRoute: string = '/questionnaire';
export const ResultsRoute: string = '/results';
export const ContentRoute: string = '/content';
export const FlowRoute: string = '/flow';
export const ServiceRoute: string = '/service';
export const ServiceRouteStart: string = '/service/start';
export const CertificatesRoute: string = '/service/certificates';
export const ServiceRouteDetails: string = '/service/details';
export const CoordinatorRoute: string = '/coordinator';
export const AppointmentsOverviewRoute: string = '/appointments';
export const AppointmentDetailsRoute: string = '/appointments/details';
export const TrainingDataRoute: string = '/trainingdata/:hash';
