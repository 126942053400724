import {Box, Select, Spinner} from '@chakra-ui/react';
import {ChangeEvent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFilteredServices} from '../../../api/getFilteredServices';

interface Props {
  onChangePlandefinition: (value: string) => void;
  onError: (error?: Error) => void;
  service: string;
}

export const PlandefinitionSelector = ({
  onChangePlandefinition,
  onError,
  service,
}: Props) => {
  const {t} = useTranslation();

  const {data, isLoading, error} = useFilteredServices();

  useEffect(() => {
    if (error) {
      onError(error);
    } else {
      onError(undefined);
    }
  }, [error, onError]);
  return (
    <Box p="2">
      <Box mb={2}>{t('service')}</Box>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Select
          name="service"
          placeholder={t('service')}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            onChangePlandefinition(e.target.value)
          }
          value={service}
        >
          {data &&
            data.map((item) => (
              <option key={item.planDefinitionId} value={item.planDefinitionId}>
                {item.planDefinitionName}
              </option>
            ))}
        </Select>
      )}
    </Box>
  );
};
