import {CertificateOverview} from '../types/Certificates';
import {
  dateInFutureMonthsDaysHours,
  dateInFutureDaysHours,
  dateInPastMonthsDaysHours,
} from './dates';

export const mockCertificateOverviewPage2: CertificateOverview[] = [
  {
    patientId: '61eaabee420570e77feab2be',
    firstName: 'Brittney',
    lastName: 'Arnold',
    dateOfBirth: '14-08-2016',
    employeeNumber: '190460',
    building: 'SPL-400',
    planDefinitionId: '45a5e56b-be5c-eb11-a812-000d3ab11b53',
    planDefinitionName: 'BHV Herhaling',
    expirationDate: dateInFutureMonthsDaysHours(1, 0, 0),
    succeedingTrainingDate: dateInFutureDaysHours(32, 0),
    observationId: '43b225f2-7964-eb11-a812-000d3ac22751',
    trainingNote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu augue lobortis, sollicitudin leo at, egestas turpis. Morbi placerat nulla et fermentum sollicitudin.',
  },
  {
    patientId: '61eaabee55fbd93b534ac80f',
    firstName: 'Mia',
    lastName: 'Jensen',
    dateOfBirth: '03-05-2000',
    employeeNumber: '131106',
    building: 'SPL-400',
    planDefinitionId: '45a5e56b-be5c-eb11-a812-000d3ab11b53',
    planDefinitionName: 'BHV Herhaling',
    expirationDate: dateInFutureMonthsDaysHours(5, 0, 0),
    succeedingTrainingDate: undefined,
    observationId: '43b225f2-7964-eb11-a812-000d3ac22751',
    trainingNote: null,
  },
  {
    patientId: '61eaabee5a6e704b23b49f25',
    firstName: 'Thomas',
    lastName: 'Carroll',
    dateOfBirth: '04-03-2009',
    employeeNumber: '177018',
    building: 'SPL-400',
    planDefinitionId: '45a5e56b-be5c-eb11-a812-000d3ab11b53',
    planDefinitionName: 'Ploegleider Basis',
    expirationDate: dateInPastMonthsDaysHours(4, 0, 0),
    succeedingTrainingDate: undefined,
    observationId: '43b225f2-7964-eb11-a812-000d3ac22751',
    trainingNote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu augue lobortis, sollicitudin leo at, egestas turpis. Morbi placerat nulla et fermentum sollicitudin.',
  },
  {
    patientId: '61eaabeee22eca0f44e3ad14',
    firstName: 'Church',
    lastName: 'Farmer',
    dateOfBirth: '14-09-1981',
    employeeNumber: '138556',
    building: 'SPL-400',
    planDefinitionId: '45a5e56b-be5c-eb11-a812-000d3ab11b53',
    planDefinitionName: 'Ploegleider Herhaling',
    expirationDate: dateInFutureMonthsDaysHours(2, 0, 0),
    succeedingTrainingDate: dateInFutureDaysHours(15, 0),
    observationId: '43b225f2-7964-eb11-a812-000d3ac22751',
    trainingNote: null,
  },
  {
    patientId: '61eaabee389de4fbf0d5240a',
    firstName: 'Zimmerman',
    lastName: 'Diaz',
    dateOfBirth: '06-12-1999',
    employeeNumber: '124659',
    building: 'SPL-400',
    planDefinitionId: '45a5e56b-be5c-eb11-a812-000d3ab11b53',
    planDefinitionName: 'BHV Basis',
    expirationDate: dateInFutureDaysHours(15, 0),
    succeedingTrainingDate: '',
    observationId: '43b225f2-7964-eb11-a812-000d3ac22751',
    trainingNote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu augue lobortis, sollicitudin leo at, egestas turpis. Morbi placerat nulla et fermentum sollicitudin.',
  },
];
