import {Box, Container, Flex} from '@chakra-ui/react';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {AppointmentSetDetails} from './AppointmentSetDetails';
import {SingleAppointmentDetails} from './SingleAppointmentDetails';
import {Appointment as AppointmentData} from '../../../lib/api/types/Appointment';
import {AppointmentSet} from '../../../lib/api/types/AppointmentSet';
import {EditButton} from './EditButton';
import {CancelButton} from './CancelButton';
import {useAcquireApiToken} from '../../../hooks/useAcquireApiToken';
import {cancelAppointment} from '../../../api/cancelAppointment';
import {getAppointmentCancelConditions} from '../../../api/getAppointmentCancelConditions';
import {i18n} from '../../../providers/i18n/i18n';
import {Alert} from '../../../components/Common/Alert';
import {useDurableFunction} from '../../../hooks/useDurableFunction';
import {useButtonStatus} from './useButtonStatus';
import {StepperButtonBar} from '../../../components/Service/StepperButtonBar/StepperButtonBar';
import {Loader} from '../../../components/Common/Loader';
import {ServiceRouteDetails} from '../../../constants/Routes';
import {ErrorActionType} from '../../../reducers/errorReducer';
import {useErrorDispatch} from '../../../contexts/errorDispatchContext';
import {FapperError} from '../../../utils/fapper';

interface ContentItem {
  body?: string;
}

export const AppointmentDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {accessToken} = useAcquireApiToken();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [error, setError] = useState<Error | null>();
  const [currentAppointment, setCurrentAppointment] =
    useState<AppointmentData>();
  const [currentAppointmentSet, setCurrentAppointmentSet] =
    useState<AppointmentSet>();
  const [cancelConditions, setCancelConditions] = useState<string>();
  const errorDispatch = useErrorDispatch();

  const id = searchParams.get('id');
  const setId = searchParams.get('setId');
  const {
    setdurableStatusUrl,
    setIsRunningDurableFunction,
    setIsLoading: setIsLoadingDurableFunction,
    isLoading: isLoadingDurableFunction,
    error: durableFunctionError,
    isSucceeded,
  } = useDurableFunction();
  const {
    isFreeOfChargeCancellationDateExceeded,
    isDisabled,
    isStarted,
    isProcessing,
  } = useButtonStatus(
    currentAppointment,
    currentAppointmentSet,
    isLoadingCancel,
    isLoadingDurableFunction,
  );

  useEffect(() => {
    const getCancelConditions = async () => {
      if (!cancelConditions && (currentAppointment || currentAppointmentSet)) {
        const plandefinitionId =
          currentAppointment?.planDefinitionId ||
          currentAppointmentSet?.appointments[0].planDefinitionId;

        if (!plandefinitionId) return;

        try {
          const result = await getAppointmentCancelConditions<ContentItem[]>({
            type: 'content',
            tags: ['cancel-consequenties-coordinator', plandefinitionId],
            language: i18n.resolvedLanguage,
          });
          setCancelConditions(result.length > 0 ? result[0].body : '');
        } catch (e: any) {
          console.log(e.message);
        }
      }
    };
    getCancelConditions();
  }, [cancelConditions, currentAppointment, currentAppointmentSet]);

  useEffect(() => {
    if (!id && !setId) {
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error: {statusText: t('appointment.no_id')},
        },
      });
    }
  }, [id, setId, errorDispatch]);

  useEffect(() => {
    if (!isLoading && (error || durableFunctionError)) {
      const err = (error || durableFunctionError) as FapperError;
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error: err,
        },
      });
    }
  }, [isLoading, error, durableFunctionError, errorDispatch]);

  const changeIsLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const onError = (e: Error | null) => {
    setError(e);
  };

  const onEditAppointment = async () => {
    if (id && currentAppointment) {
      navigate(`${ServiceRouteDetails}?id=${id}`);
    }
    if (setId && currentAppointmentSet) {
      navigate(`${ServiceRouteDetails}?setId=${setId}`);
    }
  };

  const onCancelAppointment = async () => {
    if (accessToken) {
      try {
        setIsLoadingCancel(true);
        setError(undefined);
        var response;
        if (currentAppointment) {
          response = await cancelAppointment(
            accessToken,
            currentAppointment?.appointmentId,
          );
        } else if (currentAppointmentSet) {
          response = await cancelAppointment(
            accessToken,
            currentAppointmentSet?.appointmentSetId,
            true,
          );
        }
      } catch (e: any) {
        setError(e);
      }
      if (!response?.statusQueryGetUri) {
        handleError(t('service.start.no_status_uri'));
      } else {
        setIsLoadingDurableFunction(true);
        setdurableStatusUrl(response.statusQueryGetUri);
        setIsRunningDurableFunction(true);
      }
      setIsLoadingCancel(false);
    }
  };

  const handleError = (message: string) => {
    const err = new Error(message);
    console.error(err);
    setError(err);
  };
  if (!id && !setId) {
    return null;
  }

  return (
    <Container maxW="container.xl">
      {isLoading && <Loader />}
      {isSucceeded && (
        <Flex justifyContent="center">
          <Alert
            message={t('appointment.cancel.succes_dashboard_delay')}
            status="success"
          />
        </Flex>
      )}
      {id && (
        <SingleAppointmentDetails
          id={id}
          setIsLoading={changeIsLoading}
          onError={onError}
          onAppointmentResult={setCurrentAppointment}
        />
      )}
      {setId && (
        <AppointmentSetDetails
          id={setId}
          setIsLoading={changeIsLoading}
          onError={onError}
          onAppointmentResult={setCurrentAppointmentSet}
        />
      )}
      {!isLoading && !error && (
        <>
          <Flex mt="20">
            <EditButton
              isStarted={isStarted}
              isFreeOfChargeCancellationDateExceeded={
                isFreeOfChargeCancellationDateExceeded
              }
              onEdit={() => {
                onEditAppointment();
              }}
              disabled={isDisabled || isSucceeded}
              showTooltip={isProcessing}
            />
            <CancelButton
              isStarted={isStarted}
              isFreeOfChargeCancellationDateExceeded={
                isFreeOfChargeCancellationDateExceeded
              }
              cancelConditions={cancelConditions}
              onCancel={() => {
                onCancelAppointment();
              }}
              isLoading={isLoadingCancel || isLoadingDurableFunction}
              disabled={isDisabled || isSucceeded}
              showTooltip={isProcessing}
            />
          </Flex>
          <Box mt={24}>
            <StepperButtonBar onPreviousClick={() => navigate(-1)} />
          </Box>
        </>
      )}
    </Container>
  );
};
