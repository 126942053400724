import {Link} from '@chakra-ui/react';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import {AppointmentsOverviewRoute} from '../../../constants/Routes';
import {Link as RouterLink} from 'react-router-dom';

export const AlreadyStarted = () => {
  return (
    <Trans
      i18nKey="service.already_started"
      values={{APPOINTMENT_OVERVIEW: t('edit_appointment')}}
    >
      <Link
        as={RouterLink}
        to={AppointmentsOverviewRoute}
        sx={{textDecoration: 'underline'}}
      />
    </Trans>
  );
};
