import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {Student} from '../lib/api/types/Student';
import {fapper, FapperError} from '../utils/fapper';

const sendStudents = async (accessToken: string): Promise<Student[]> => {
  const responseData: Student[] = await fapper.get(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/patients`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const getStudents = async (accessToken?: string) => {
  if (!accessToken) {
    return new Promise<Student[]>((resolve) => resolve([]));
  }
  return sendStudents(accessToken);
};

const useStudents = (
  options: UseQueryOptions<Student[], FapperError, Student[], QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  const {accessToken} = useAcquireApiToken();

  return useQuery<Student[], FapperError>(
    ['Students', accessToken],
    () => getStudents(accessToken),
    options,
  );
};

export {useStudents, getStudents};
