import dayjs from 'dayjs';

export const getMinAndMaxRange = (
  range?: string,
  exactDate?: string,
): {minimumExpirationDate?: string; maximumExpirationDate?: string} => {
  if (exactDate) {
    return {
      minimumExpirationDate: dayjs(exactDate).format('YYYY-MM-DD'),
      maximumExpirationDate: dayjs(exactDate)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    };
  }
  switch (range) {
    case 'expired': {
      return {
        minimumExpirationDate: undefined,
        maximumExpirationDate: dayjs().format('YYYY-MM-DD'),
      };
    }
    case 'less_than_1_month': {
      return {
        minimumExpirationDate: dayjs().format('YYYY-MM-DD'),
        maximumExpirationDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
      };
    }
    case 'less_than_3_months': {
      return {
        minimumExpirationDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
        maximumExpirationDate: dayjs().add(3, 'month').format('YYYY-MM-DD'),
      };
    }
    case 'more_than_3_months': {
      return {
        minimumExpirationDate: dayjs().add(3, 'month').format('YYYY-MM-DD'),
        maximumExpirationDate: undefined,
      };
    }
    default: {
      return {
        minimumExpirationDate: undefined,
        maximumExpirationDate: undefined,
      };
    }
  }
};
