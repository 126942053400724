import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {ServiceTimeslotSet} from '../lib/api/types/Services';
import {ServiceTimeslotSetsRequest} from '../lib/api/types/Requests';
import {fapper, FapperError} from '../utils/fapper';

export const defaultPageSize = 5;
const sendGetServiceTimeSlotSets = async (
  data: ServiceTimeslotSetsRequest,
): Promise<ServiceTimeslotSet[][]> => {
  const responseData: ServiceTimeslotSet[][] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/ext/v4/timeslotsets`,
    {
      ...data,
      pageSize: defaultPageSize,
    },
  );
  return responseData;
};

const getServiceTimeslotSets = async (data?: ServiceTimeslotSetsRequest) => {
  if (!data?.accountId && !data?.planDefinitionId) {
    const result: ServiceTimeslotSet[][] = [];
    return new Promise<ServiceTimeslotSet[][]>((resolve) => resolve(result));
  }
  return sendGetServiceTimeSlotSets(data);
};

const useServiceTimeslotSets = (
  data?: ServiceTimeslotSetsRequest,
  options: UseQueryOptions<
    ServiceTimeslotSet[][],
    FapperError,
    ServiceTimeslotSet[][],
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  return useQuery<ServiceTimeslotSet[][], FapperError>(
    ['TimeslotSet', data],
    () => getServiceTimeslotSets(data),
    options,
  );
};

export {useServiceTimeslotSets, getServiceTimeslotSets};
