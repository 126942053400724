export const base = {
  headings: {
    h1: {
      fontSize: '2.375rem',
      lineHeight: '3.75rem',
      mb: 5,
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      mb: 4,
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: 'light',
      mb: 5,
    },
    h4: {
      fontSize: '1.438rem',
      lineHeight: ['2.25rem', null, null, '2.438rem'],

      mb: 3,
    },
  },
};
