import {
  AppointmentTimeslot,
  TimeslotListSection,
} from '../../../lib/api/types/Services';
import {TimeslotListView} from './TimeslotListView';

export interface Props {
  timeslotSections: TimeslotListSection[];
  selected?: AppointmentTimeslot[];
  onSelect?: (val: AppointmentTimeslot[]) => void;
  onLoadMore?: () => void;
  hideMoreItems?: boolean;
  showCapacity?: boolean;
  onChangeMonth: (month: string) => void;
  selectedMonth: string;
  loadMoreNextMonth?: string;
}

export const TimeslotListController = ({
  timeslotSections,
  onSelect,
  selected,
  onLoadMore,
  hideMoreItems,
  showCapacity,
  onChangeMonth,
  selectedMonth,
  loadMoreNextMonth,
}: Props) => {
  return (
    <TimeslotListView
      timeslotSections={timeslotSections}
      onSelect={onSelect}
      selected={selected}
      onLoadMore={onLoadMore}
      showCapacity={showCapacity}
      onChangeMonth={onChangeMonth}
      selectedMonth={selectedMonth}
      loadMoreNextMonth={loadMoreNextMonth}
      hideMoreItems={hideMoreItems}
    />
  );
};
