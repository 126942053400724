import {Button, Flex, Spinner, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {ArrowLeft, ArrowRight, X} from 'react-feather';

export interface StepperButtonBarProps {
  onPreviousClick: () => void;
  onNextClick?: () => void;
  onCancelClick?: () => void;
  nextButtonLabel?: string;
  nextButtonIsDisabled?: boolean;
  isRunningStartService?: boolean;
}

export const StepperButtonBar = ({
  onNextClick,
  onPreviousClick,
  onCancelClick,
  nextButtonLabel,
  nextButtonIsDisabled = false,
  isRunningStartService = false,
}: StepperButtonBarProps) => {
  const {t} = useTranslation();
  return (
    <Flex justifyContent="space-between">
      <Flex>
        <Button
          disabled={isRunningStartService}
          leftIcon={<ArrowLeft />}
          colorScheme="secondary"
          onClick={onPreviousClick}
        >
          <Text as="span" pt={1}>
            {t('previous')}
          </Text>
        </Button>
        {onCancelClick && (
          <Button
            rightIcon={<X />}
            variant="ghost"
            onClick={onCancelClick}
            disabled={isRunningStartService}
            sx={{justifyContent: 'center'}}
          >
            <Text as="span" pt={1}>
              {t('button.cancel')}
            </Text>
          </Button>
        )}
      </Flex>
      {onNextClick && (
        <Button
          disabled={nextButtonIsDisabled || isRunningStartService}
          rightIcon={isRunningStartService ? <Spinner /> : <ArrowRight />}
          onClick={onNextClick}
        >
          <Text as="span" pt={1}>
            {nextButtonLabel ?? t('next')}
          </Text>
        </Button>
      )}
    </Flex>
  );
};
