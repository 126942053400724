import React from 'react';
import ReactDOM from 'react-dom';
import {theme} from './theme/themes/default';
import {ChakraProvider} from '@chakra-ui/react';
import {GlobalStyles} from './theme/components/GlobalStyles';
import {BrowserRouter} from 'react-router-dom';
import {ContextProvider} from './components/ContextProvider';
import {msalConfig} from './authentication/authenticationConfiguration';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {App} from './App';
import {CustomerPortalProvider} from './contexts/customerPortalProvider';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider resetCSS theme={theme}>
        <GlobalStyles />
        <MsalProvider instance={msalInstance}>
          <CustomerPortalProvider>
            <ContextProvider>
              <App />
            </ContextProvider>
          </CustomerPortalProvider>
        </MsalProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
