import {
  HRManagerGroup,
  ClientManagerGroup,
  MedischSpecialistGroup,
  KHSGroup,
  TrainingCoordinatorGroup,
} from '../constants/AadGroups';
import {PageHeader} from '../components/Common/PageHeader/PageHeader';
import {Container, SimpleGrid, Box, Heading} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useAccount, useMsal} from '@azure/msal-react';
import {useAccountGroups} from '../hooks/useAccountGroups';
import {DashboardButton} from '../components/Common/DashboardButtons/DashboardButton';
import {ExtendedRoute} from '../routesConfiguration';
import {useMenu} from '../components/Common/Navigation/useMenu';

export const HomePage = () => {
  const {t} = useTranslation();
  const {accounts} = useMsal();
  const groups = useAccountGroups();
  const account = useAccount(accounts[0] || {});
  const {menuOne, menuTwo} = useMenu();

  const getGroupName = (group: string) => {
    let groupName = '';

    if (group === HRManagerGroup) {
      groupName = t('home.hr_manager');
    } else if (group === ClientManagerGroup) {
      groupName = t('home.manager');
    } else if (group === MedischSpecialistGroup) {
      groupName = t('home.medical_specialist');
    } else if (group === KHSGroup) {
      groupName = t('home.khs_employee');
    } else if (group === TrainingCoordinatorGroup) {
      groupName = t('home.training_coordinator');
    }

    return groupName;
  };

  const groupName = getGroupName(groups[0]);
  const header = t('home.title', {
    groupName: groupName,
    accountName: account?.name,
  });

  const renderGridButtons = (buttons: ExtendedRoute[]) => {
    return (
      <SimpleGrid columns={1} spacing={6}>
        {buttons.map((route) => {
          if (!route.text || !route.icon || !route.path) {
            return null;
          }
          return (
            <DashboardButton
              key={route.text}
              path={route.path}
              text={route.text}
              icon={route.icon}
            />
          );
        })}
      </SimpleGrid>
    );
  };

  return (
    <Container maxW="container.xl" data-cy="HomePage">
      <PageHeader text={header} />

      <SimpleGrid columns={[1, 2]} spacing={6} mt={5}>
        <Box>
          <Heading as="h2" variant="h2">
            {t('for.employee')}
          </Heading>
          {menuOne && renderGridButtons(menuOne?.items)}
        </Box>
        <Box>
          <Heading as="h2" variant="h2">
            {t('for.me')}
          </Heading>
          {menuTwo && renderGridButtons(menuTwo.items)}
        </Box>
      </SimpleGrid>
    </Container>
  );
};
