import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Text,
  Grid,
  GridItem,
  ExpandedIndex,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {ChevronDown} from 'react-feather';
import {
  AppointmentTimeslot,
  TimeslotListSection,
} from '../../../lib/api/types/Services';
import {LoadMore} from '../../../pages/Appointments/AppointmentOverview/LoadMore';
import {MonthSelector} from '../../../pages/Appointments/AppointmentOverview/MonthSelector';
import {colors} from '../../../theme/colors';
import {Alert} from '../Alert';
import {TimeslotListItemView} from '../TimeslotListItem/TimeslotListItemView';

interface Props {
  timeslotSections: TimeslotListSection[];
  onSelect?: (val: AppointmentTimeslot[]) => void;
  selected?: AppointmentTimeslot[];
  onLoadMore?: () => void;
  hideMoreItems?: boolean;
  showCapacity?: boolean;
  onChangeMonth: (month: string) => void;
  selectedMonth: string;
  loadMoreNextMonth?: string;
}

interface AccordionIndex {
  index: number;
  isOpen: boolean;
}

export const TimeslotListView = ({
  timeslotSections,
  onSelect,
  selected,
  onLoadMore,
  hideMoreItems,
  showCapacity,
  onChangeMonth,
  selectedMonth,
  loadMoreNextMonth,
}: Props) => {
  const [indexes, setIndexes] = useState<AccordionIndex[]>([]);
  const [isSettingIndexes, setIsSettingIndexes] = useState(false);
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  useEffect(() => {
    if (timeslotSections.length < indexes.length) {
      setIndexes([]);
    } else if (
      indexes.length !== timeslotSections.length &&
      !isSettingIndexes
    ) {
      setIsSettingIndexes(true);
      const newIndexes = [...indexes];
      timeslotSections.forEach((_, index) => {
        if (
          indexes.find(
            (accordionIndex: AccordionIndex) => accordionIndex.index === index,
          ) === undefined
        ) {
          newIndexes.push({index, isOpen: true});
        }
      });
      setIndexes(newIndexes);
      setIsSettingIndexes(false);
    }
  }, [indexes, isSettingIndexes, timeslotSections]);

  useEffect(() => {
    const newOpenIndexes: number[] = [];
    indexes.forEach((item) => {
      if (item.isOpen) newOpenIndexes.push(item.index);
    });
    setOpenIndexes(newOpenIndexes);
  }, [indexes]);

  const onChange = (expandedIndex: ExpandedIndex) => {
    const newIndexes: AccordionIndex[] = [];
    if (Array.isArray(expandedIndex)) {
      indexes.forEach((item) => {
        newIndexes.push({
          index: item.index,
          isOpen: expandedIndex.includes(item.index),
        });
      });
    }
    setIndexes(newIndexes);
  };

  return (
    <>
      <Box paddingEnd={2}>
        <Grid
          templateColumns="0.75fr 0.20fr 0.05fr"
          gap={6}
          mb={5}
          fontSize="medium"
        >
          <GridItem>
            <Grid
              templateColumns="0.75fr 0.75fr 1.5fr"
              cursor="pointer"
              lineHeight="20px"
            >
              <GridItem paddingEnd={2}>
                <MonthSelector
                  onChangeMonth={onChangeMonth}
                  month={selectedMonth}
                />
              </GridItem>
              <GridItem p={2}>{t('label.time')}</GridItem>
              <GridItem p={2}>{t('label.location')}</GridItem>
            </Grid>
          </GridItem>
          {showCapacity && <GridItem>{t('label.available_capacity')}</GridItem>}
        </Grid>
      </Box>
      {timeslotSections.length === 0 ? (
        <Box>
          <Alert message={t('service.timeslots.empty')} status="warning" />
        </Box>
      ) : (
        <>
          <Accordion
            allowToggle
            allowMultiple
            index={openIndexes}
            onChange={onChange}
          >
            {timeslotSections &&
              timeslotSections.map((section) => {
                return (
                  <AccordionItem key={section.header} border="none">
                    <AccordionButton p={0}>
                      <Flex flex={1} alignItems="center" flexDirection="row">
                        <Text
                          as="span"
                          whiteSpace="nowrap"
                          color={colors.listIconColor}
                          marginRight={2}
                        >
                          {dayjs(section.header).format('MMMM YYYY')}
                        </Text>
                        <Divider orientation="horizontal" />
                        <ChevronDown color={colors.listIconColor} />
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel p={0} mb={6}>
                      {section.items.length === 0 ? (
                        <Box>{t('service.timeslots.empty')}</Box>
                      ) : (
                        section.items.map((item, index) => {
                          return (
                            <Box
                              key={index}
                              borderRadius="10px"
                              borderWidth={2}
                              borderColor={
                                item === selected
                                  ? colors.primaryBackground
                                  : 'white'
                              }
                              bg={
                                item === selected
                                  ? colors.primaryBackground
                                  : index % 2 === 1
                                  ? colors.gray[100]
                                  : ''
                              }
                              color={item === selected ? 'white' : 'current'}
                            >
                              <Box paddingEnd={2}>
                                <TimeslotListItemView
                                  timeslotListItems={item}
                                  onClick={
                                    onSelect ? () => onSelect(item) : undefined
                                  }
                                  selected={item === selected}
                                />
                              </Box>
                            </Box>
                          );
                        })
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
          </Accordion>
        </>
      )}
      {onLoadMore && !hideMoreItems && (
        <LoadMore onLoadMore={onLoadMore} date={loadMoreNextMonth} />
      )}
    </>
  );
};
