import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {Appointment} from '../lib/api/types/AppointmentsOverview';
import {AppointmentsOverviewRequest} from '../lib/api/types/Requests';
import {fapper, FapperError} from '../utils/fapper';

import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import dayjs from 'dayjs';
export const defaultPageSize = 20;
const sendAppointmentOverview = async (
  accessToken: string,
  data?: AppointmentsOverviewRequest,
): Promise<Appointment[]> => {
  const responseData: Appointment[] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/appointments`,
    {
      ...data,
      pageSize: defaultPageSize,
      startDate: dayjs(data?.startDate).format('YYYY-MM-DD[Z]'),
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return responseData;
};

const getAppointmentOverview = async (
  accessToken?: string,
  data?: AppointmentsOverviewRequest,
) => {
  if (!accessToken) {
    return new Promise<Appointment[]>((resolve) => resolve([]));
  }

  return sendAppointmentOverview(accessToken, data);
};

const useAppointmentOverview = (
  data?: AppointmentsOverviewRequest,
  options: UseQueryOptions<
    Appointment[],
    FapperError,
    Appointment[],
    QueryKey
  > = {
    refetchOnWindowFocus: false,
    cacheTime: 1,
  },
) => {
  const {accessToken} = useAcquireApiToken();
  return useQuery<Appointment[], FapperError>(
    [
      'Appointment',
      accessToken,
      data?.patientSearchFilterValue,
      data?.planDefinitionId,
      data?.startDate,
    ],
    () => getAppointmentOverview(accessToken, data),
    options,
  );
};

export {useAppointmentOverview, getAppointmentOverview};
