import {PowerBIPage} from './PowerBIPage';
import {PowerBIReports} from '../../constants/PowerBIReports';

export const QuestionnairePage = () => {
  const header = 'Questionnaire';

  return (
    <PowerBIPage title={header} reportName={PowerBIReports.Questionnaire} />
  );
};
