import {useEffect, useRef, useState} from 'react';
import {makeServer} from './lib/api/mockApi/mockApi';
import {QueryClient, QueryClientProvider} from 'react-query';

import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import {i18n} from './providers/i18n/i18n';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {Loader} from './components/Common/Loader';
import {routes} from './routes';

import {AppRouter, Routes} from 'auth-react-router';
import {Box} from '@chakra-ui/react';
import {ErrorAlert} from './components/Common/ErrorAlert';
import {ChakraNavigationBar} from './components/Common/Navigation/ChakraNavigationBar';
import {useAccountGroups} from './hooks/useAccountGroups';
import {InteractionStatus} from '@azure/msal-browser';
import {ContextVisualizer} from './components/Common/ContextVisualizer';
import {useCustomerPortalState} from './contexts/customerPortalProvider';
import {trackingInitializerGTM} from './utils/trackingGTM';
import {GetFeedback} from './components/Common/Feedback/GetFeedback';

require('dayjs/locale/nl');
dayjs.locale(i18n.language.split('-')[0]);
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);

if (
  process.env.REACT_APP_ENVIRONMENT === 'development' &&
  process.env.REACT_APP_ENABLE_MOCK === 'true'
) {
  makeServer();
}

export const App = () => {
  const {state} = useCustomerPortalState();
  const queryClientRef = useRef(new QueryClient());
  const isAuthenticated = useIsAuthenticated();
  const {inProgress} = useMsal();
  const [isLoaded, setIsLoaded] = useState(false);

  const groups = useAccountGroups();

  useEffect(() => {
    if (!isLoaded) {
      trackingInitializerGTM();
      setIsLoaded(true);
    }
  }, [isLoaded]);

  if (!isAuthenticated && inProgress !== InteractionStatus.None) {
    return <Loader />;
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      {/* `userRole` is optional, use it only if at least one Route has the `roles` property */}
      {/* `userRole` can be an array of roles too, usually you will fetch it from an API and set it here */}
      <AppRouter isAuth={isAuthenticated} routes={routes} userRole={groups}>
        {/* Wrap `Routes` component into a Layout component or add Header */}
        <Box>
          <ChakraNavigationBar />
          <ErrorAlert />
          <Routes />
          {process.env.NODE_ENV === 'development' && (
            <ContextVisualizer state={state} />
          )}
          {isAuthenticated && <GetFeedback />}
        </Box>
      </AppRouter>
    </QueryClientProvider>
  );
};
