import {UseQueryOptions, useQuery, QueryKey} from 'react-query';
import {useAcquireApiToken} from '../hooks/useAcquireApiToken';
import {
  GenerateTrainingDataUrlRequest,
  GetTrainingDataRequest,
} from '../lib/api/types/Requests';
import {AppointmentTimeslot} from '../lib/api/types/Services';
import {TrainingDataUrl} from '../lib/api/types/TrainingData';
import {fapper, FapperError} from '../utils/fapper';
import {getLocationInfoByTimeslots} from './getLocationInfoByTimeslots';

const sendGenerateTrainingDataUrl = async (
  accessToken: string,
  data?: GenerateTrainingDataUrlRequest,
): Promise<TrainingDataUrl> => {
  const responseData: TrainingDataUrl = await fapper.post(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/trainingdata/share`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

const generateTrainingDataUrl = async (
  accessToken?: string,
  data?: GenerateTrainingDataUrlRequest,
) => {
  if (!accessToken) {
    return new Promise<undefined>((resolve) => resolve(undefined));
  }
  return sendGenerateTrainingDataUrl(accessToken, data);
};

const getTrainingDataByhash = async (
  language: string,
  hash?: string,
  data?: GetTrainingDataRequest,
) => {
  if (!hash) {
    return new Promise<AppointmentTimeslot[][]>((resolve) => resolve([]));
  }
  const timeslots: AppointmentTimeslot[][] = await fapper.post(
    `${process.env.REACT_APP_API_URL}/ext/v4/trainingdata/${hash}`,
    {
      ...data,
    },
  );
  const timeslotsWithLocations: AppointmentTimeslot[][] =
    await getLocationInfoByTimeslots(timeslots, language);
  return timeslotsWithLocations;
};

const useGetTrainingDataByHash = (
  language: string,
  hash?: string,
  data?: GetTrainingDataRequest,
  options: UseQueryOptions<
    AppointmentTimeslot[][],
    FapperError,
    AppointmentTimeslot[][],
    QueryKey
  > = {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 1,
  },
) => {
  return useQuery<AppointmentTimeslot[][], FapperError>(
    ['GetTrainingDataByHash', language, hash, data],
    () => getTrainingDataByhash(language, hash, data),
    {
      ...options,
      enabled: !!hash,
    },
  );
};

const useGenerateTrainingDataUrl = (
  data?: GenerateTrainingDataUrlRequest,
  options: UseQueryOptions<
    TrainingDataUrl | undefined,
    FapperError,
    TrainingDataUrl | undefined,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 1,
  },
) => {
  const {accessToken} = useAcquireApiToken();

  return useQuery<TrainingDataUrl | undefined, FapperError>(
    [
      'GenerateTrainingDataUrl',
      data?.accountId,
      data?.planDefinitionId,
      data?.startDate,
      data?.endDate,
      accessToken,
    ],
    () => generateTrainingDataUrl(accessToken, data),
    {
      ...options,
      enabled: !!(
        data?.accountId &&
        data?.planDefinitionId &&
        accessToken !== undefined
      ),
    },
  );
};
export {
  useGenerateTrainingDataUrl,
  useGetTrainingDataByHash,
  getTrainingDataByhash,
};
