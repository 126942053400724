import {darken, SystemStyleObject} from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

function variantSolid(props: Dict) {
  const {colorScheme: c} = props;
  if (c === 'primary') {
    return {
      bg: 'primaryBackground',
      _active: {
        backgroundColor: darken('primaryBackground', 10),
      },
      _hover: {
        opacity: 0.8,
      },
      _focus: {
        boxShadow: 'none',
        outline: 'none',
      },
    };
  } else if (c === 'secondary')
    return {
      bg: 'secondaryBackground',
      color: 'bodyText',
      _active: {
        backgroundColor: darken('secondaryBackground', 10),
      },
      _hover: {
        opacity: 0.8,
      },
      _focus: {
        boxShadow: 'none',
        outline: 'none',
      },
    };
}

const variants = {
  solid: variantSolid,
  'no-outline': {
    bg: 'none',
    border: 'none',
    _focus: {
      boxShadow: 'none',
      outline: 'none',
    },
  },
};

const sizes: Record<string, SystemStyleObject> = {
  md: {
    h: 14,
  },
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'primary',
};

export const Button = {
  variants,
  defaultProps,
  sizes,
};
