import {ServiceTimeslot} from '../types/Services';
import {mockTimeslotsWithCapacity} from './mockTimeslotsWithCapacity';

const mock = mockTimeslotsWithCapacity;
export const mockTimeslotNoCapacity: ServiceTimeslot[] = mock.map(
  (timeslot) => {
    return {
      ...timeslot,
      showAvailableCapacity: false,
    };
  },
);
