import {Container, Box} from '@chakra-ui/react';
import {useEffect} from 'react';

export const GetFeedback = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/getfeedback.js';
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <Container maxW="container.xl" mt={8}>
        <Box py={8}>
          <div ub-in-page="632b11be34d70373cf691be9"></div>
        </Box>
      </Container>
    </>
  );
};
