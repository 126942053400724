import {Global} from '@emotion/react';

export const DefaultGlobalStyles = () => (
  <Global
    styles={`
      body {
        font-family: 'Arial', sans-serif;
        font-weight: normal;
      }                 
    `}
  />
);
