export type Colors = typeof colors;

const paletteDefault = {
  SkyTeamBlue: '#002776',
  KLMDarkBlue2: '#003145',
  LightSkyBlue: '#00A1DE',
  Orange: '#E37222',
  KLMLightBlue2: '#c2deea',
  SilverGrey: '#7C7F7D',
  Purple: '#8093BB',
  Red: '#ED2939',
  Success: '#008A00',
  Black20: '#00000020',
};

export const colors = {
  primary: paletteDefault.KLMDarkBlue2,
  bodyText: paletteDefault.KLMDarkBlue2,
  link: paletteDefault.SkyTeamBlue,
  success: paletteDefault.Success,
  primaryLinkColor: paletteDefault.SkyTeamBlue, //this name might be subject to change
  secondaryLinkColor: paletteDefault.KLMDarkBlue2, //this name might be subject to change
  grey: '#C4C4C4',
  darkGrey: paletteDefault.SilverGrey,
  alertInfo: paletteDefault.KLMLightBlue2,
  alertInfoCaption: paletteDefault.KLMDarkBlue2,
  contactBackground: paletteDefault.LightSkyBlue,
  contactColor: 'white',
  introBackground: paletteDefault.KLMDarkBlue2,
  introColor: 'white',
  introLinkColor: 'white',
  selectedColor: paletteDefault.Orange,
  faqBackground: 'white',
  faqBackgroundHover: 'rgba(0, 0, 0, 0.04)',
  faqHeadingColor: paletteDefault.KLMDarkBlue2,
  faqIconColor: paletteDefault.LightSkyBlue,
  visualListBackground: paletteDefault.LightSkyBlue,
  visualListColor: 'white',
  primaryBackground: paletteDefault.SkyTeamBlue,
  primaryBackgroundDisabled: paletteDefault.Purple,
  primaryButtonColor: 'white',
  secondaryBackground: paletteDefault.KLMLightBlue2,
  secondaryBackgroundIconColor: paletteDefault.KLMDarkBlue2,
  headerBackground:
    'linear-gradient(90.37deg, #FFFFFF 8.78%, rgba(255, 255, 255, 0.62) 96.79%)',
  footerBackground: 'transparent',
  servicesOverviewHeadingColor: paletteDefault.KLMDarkBlue2,
  blogOverviewHeadingColor: paletteDefault.KLMDarkBlue2,
  footerColor: paletteDefault.KLMDarkBlue2,
  footerLinkColor: paletteDefault.SkyTeamBlue,
  footerLinkArrowColor: paletteDefault.SkyTeamBlue,
  footerUspArrowColor: '#008A00',
  blogDetailBackgroundColor: 'transparent',
  closeIconColor: paletteDefault.KLMDarkBlue2,
  closeIconBackgroundHover: 'rgba(0, 0, 0, 0.06)',
  dividerColor: paletteDefault.LightSkyBlue,
  gray: {
    100: '#F2F6F9',
    300: '#82837C',
  },
  green: {
    500: paletteDefault.Success,
  },
  errorBackgroundColor: paletteDefault.Red,
  listIconColor: paletteDefault.LightSkyBlue,
  gridDivider: paletteDefault.Black20,
};
