import {
  AdditionalLocationInfo,
  AppointmentTimeslot,
} from '../lib/api/types/Services';
import {getAdditionalLocationInfo} from './getAdditionalLocationInfo';

export const getLocationInfoByTimeslots = async (
  timeslots: AppointmentTimeslot[][],
  language: string,
) => {
  const timeslotsWithLocations: AppointmentTimeslot[][] = [];
  const additionalInfoCalls: Promise<void>[] = [];
  let uniqueLocationIds = new Set<string>();

  timeslots.forEach((timeslot) => {
    timeslot.forEach((item) => {
      uniqueLocationIds.add(item.locationId.toLowerCase());
    });
  });

  // Parrallel fetch info for each unique locationId
  let locations = new Map<string, AdditionalLocationInfo>();
  uniqueLocationIds.forEach((locationId) => {
    additionalInfoCalls.push(
      getAdditionalLocationInfo({
        tag: locationId,
        language,
      })
        .then((locationData: AdditionalLocationInfo) => {
          if (locationData) {
            locations.set(locationId, locationData);
          }
        })
        .catch(() => Promise.resolve()),
    );
  });
  await Promise.all(additionalInfoCalls);

  // Now we have fetched location info, enrich all appointments
  // with the fetched location data
  timeslots.forEach((timeslot) => {
    const items: AppointmentTimeslot[] = [];
    timeslot.forEach((item) => {
      const locationData = locations.get(item.locationId.toLowerCase());

      if (locationData) {
        items.push({
          ...item,
          address: locationData.address,
        });
      }
    });

    timeslotsWithLocations.push(items);
  });
  return timeslotsWithLocations;
};
