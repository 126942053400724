import {Box} from '@chakra-ui/react';
import {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {decoupleStudent} from '../../../api/decoupleStudent';
import {useStudents} from '../../../api/getStudents';
import {updateStudent} from '../../../api/updateStudent';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {useErrorDispatch} from '../../../contexts/errorDispatchContext';
import {useAcquireApiToken} from '../../../hooks/useAcquireApiToken';
import {Student} from '../../../lib/api/types/Student';
import {ErrorActionType} from '../../../reducers/errorReducer';
import {StudentSelectorView} from './StudentSelectorView';

export type searchKeyOptions = 'lastName' | 'firstName' | 'employeeNumber';

interface Props {
  onIsInValidMultipleStudentSelection: (value: boolean) => void;
}
export const StudentSelectorController = ({
  onIsInValidMultipleStudentSelection,
}: Props) => {
  const {t} = useTranslation();
  const {accessToken} = useAcquireApiToken();
  const [checkedStudents, setCheckedStudents] = useState<Student[]>([]);
  const {dispatch, state} = useCustomerPortalState();
  const [students, setStudents] = useState<Student[]>();
  const errorDispatch = useErrorDispatch();
  const {data, isLoading, error, isFetching, refetch} = useStudents();
  const [isMultipleStudentsSelected, setIsMultipleStudentsSelected] =
    useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const [selectedStudent, setSelectedStudent] =
    useState<Student | undefined>(undefined);
  const [trainingNote, setTrainingNote] =
    useState<string | undefined>(undefined);
  const onSelect = (student: Student) => {
    const stu: Student[] = [student];
    dispatch({type: 'SERVICE_STORE', payload: {student: stu}});
    setCheckedStudents([...stu]);
  };
  const [updateStudentError, setUpdateStudentError] =
    useState<string | undefined>(undefined);
  const [updateStudentIsLoading, setUpdateStudentIsLoading] = useState(false);
  const [updateStudentIsSuccess, setUpdateStudentIsSuccess] = useState(false);

  const [decoupleStudentError, setDecoupleStudentError] =
    useState<string | undefined>(undefined);
  const [decoupleStudentIsLoading, setDecoupleStudentIsLoading] =
    useState(false);
  const [decoupleStudentIsSuccess, setDecoupleStudentIsSuccess] =
    useState(false);

  const hasSameCompany = (result: Student[]) => {
    return result.every((st) => st.accountId === result[0].accountId);
  };

  const onSelectCheckbox = (
    student: Student,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch({type: 'SERVICE_STORE', payload: {service: undefined}});

    if (e.target.checked) {
      const newStudents = [...checkedStudents, student];
      onIsInValidMultipleStudentSelection(!hasSameCompany(newStudents));
      setCheckedStudents(newStudents);
      dispatch({
        type: 'SERVICE_STORE',
        payload: {student: newStudents},
      });
    } else {
      const index = checkedStudents.findIndex(
        (checkedStudent) => checkedStudent.patientId === student.patientId,
      );
      const newStudents = checkedStudents
        .slice(0, index)
        .concat(checkedStudents.slice(index + 1));
      onIsInValidMultipleStudentSelection(!hasSameCompany(newStudents));
      dispatch({type: 'SERVICE_STORE', payload: {student: newStudents}});
      setCheckedStudents(newStudents);
    }
  };

  const handleChangeSearchKey = () => {
    setStudents(data);
  };

  useEffect(() => {
    if (selectedStudent) {
      setTrainingNote(selectedStudent?.trainingNote);
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (data) {
      setStudents(data);
    }
  }, [data]);

  useEffect(() => {
    if (
      state.service.data.student &&
      checkedStudents.length !== state.service.data.student.length
    ) {
      setCheckedStudents(state.service.data.student);
      setIsMultipleStudentsSelected(state.service.data.student.length > 1);
    }
  }, [
    checkedStudents,
    dispatch,
    isMultipleStudentsSelected,
    state.service.data.student,
  ]);

  useEffect(() => {
    setUpdateStudentError(undefined);
    setUpdateStudentIsSuccess(false);
    setDecoupleStudentError(undefined);
    setDecoupleStudentIsSuccess(false);
  }, [isFinished]);

  useEffect(() => {
    if (!isLoading && error) {
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error,
          refetch,
        },
      });
    }
  }, [isLoading, error, errorDispatch, refetch]);

  const removeDiacritics = (value: string) => {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const onSearch = (key: searchKeyOptions, value: string) => {
    const filtered = data?.filter((student: Student) => {
      if (key === 'employeeNumber') {
        const employeeNumber = student[key];
        return (
          employeeNumber != null &&
          employeeNumber.toLowerCase().includes(value.toLowerCase())
        );
      }

      var employee = removeDiacritics(student[key].toLocaleLowerCase());
      return employee.includes(value.toLocaleLowerCase()) ? true : false;
    });
    setStudents(filtered);
  };

  const onMultipleStudentsSelected = (value: boolean) => {
    dispatch({
      type: 'SERVICE_STORE',
      payload: {
        isMultipleStudentsSelected: value,
        student: [],
        service: undefined,
      },
    });
    setCheckedStudents([]);
    setIsMultipleStudentsSelected(value);
  };

  const onUpdateStudent = async () => {
    setUpdateStudentIsLoading(true);
    setUpdateStudentIsSuccess(false);
    try {
      await updateStudent(accessToken, selectedStudent?.patientId, {
        trainingNote,
      });
      setUpdateStudentIsSuccess(true);
      refetch();
    } catch (e: any) {
      setUpdateStudentError(e.statusText || t('student.update.error'));
    }
    setUpdateStudentIsLoading(false);
  };

  const onDecoupleStudent = async () => {
    setDecoupleStudentIsLoading(true);
    setDecoupleStudentIsSuccess(false);
    try {
      await decoupleStudent(accessToken, selectedStudent);
      setDecoupleStudentIsSuccess(true);
      setStudents(
        students?.filter((st) => st.patientId !== selectedStudent?.patientId),
      );
    } catch (e: any) {
      setDecoupleStudentError(e.statusText || t('student.update.error'));
    }
    setDecoupleStudentIsLoading(false);
  };

  return (
    <>
      <Box as="section">
        <StudentSelectorView
          isLoading={isLoading || isFetching}
          students={students || []}
          selectedStudent={state.service.data.student}
          onSelect={onSelect}
          onSelectCheckbox={onSelectCheckbox}
          search={onSearch}
          handleChangeSearchKey={handleChangeSearchKey}
          onMultipleStudentsSelected={onMultipleStudentsSelected}
          isMultipleStudentsSelected={isMultipleStudentsSelected}
          onFinish={setIsFinished}
          onDecoupleStudent={onDecoupleStudent}
          onSetTrainingNote={setTrainingNote}
          trainingNote={trainingNote}
          selectedSingleStudent={selectedStudent}
          onSelectStudent={setSelectedStudent}
          onUpdateStudent={onUpdateStudent}
          updateStudentError={updateStudentError}
          updateStudentIsLoading={updateStudentIsLoading}
          updateStudentIsSuccess={updateStudentIsSuccess}
          decoupleStudentError={decoupleStudentError}
          decoupleStudentIsLoading={decoupleStudentIsLoading}
          decoupleStudentIsSuccess={decoupleStudentIsSuccess}
        />
      </Box>
    </>
  );
};
