import {Box} from '@chakra-ui/react';
import {useEffect} from 'react';
import {useServices} from '../../../api/getServices';
import {useCustomerPortalState} from '../../../contexts/customerPortalProvider';
import {useErrorDispatch} from '../../../contexts/errorDispatchContext';
import {Service} from '../../../lib/api/types/Services';
import {Student} from '../../../lib/api/types/Student';
import {ErrorActionType} from '../../../reducers/errorReducer';
import {ServiceSelectorView} from './ServiceSelectorView';

interface Props {
  students: Student[];
  isMultipleStudentsSelected: boolean;
  isInValidMultipleStudentSelection: boolean;
}

export const ServiceSelectorController = ({
  students,
  isMultipleStudentsSelected,
  isInValidMultipleStudentSelection,
}: Props) => {
  const {state, dispatch} = useCustomerPortalState();
  const errorDispatch = useErrorDispatch();
  const patientIds = students.map((st) => st.patientId);
  const {data, isLoading, error, isFetching, refetch} = useServices(
    patientIds,
    isMultipleStudentsSelected,
    isInValidMultipleStudentSelection,
  );

  const onSelectService = (service: Service) => {
    if (
      service.planDefinitionId !== state.service.data.service?.planDefinitionId
    ) {
      dispatch({type: 'SERVICE_STORE', payload: {location: undefined}});
      dispatch({type: 'SERVICE_STORE', payload: {timeslots: undefined}});
    }

    dispatch({type: 'SERVICE_STORE', payload: {service}});
  };

  useEffect(() => {
    if (!isLoading && error) {
      errorDispatch({
        type: ErrorActionType.ErrorActionSet,
        payload: {
          error,
          refetch,
        },
      });
    }
  }, [isLoading, error, errorDispatch, refetch]);

  return (
    <Box as="section">
      <ServiceSelectorView
        isLoading={isLoading || isFetching}
        services={data || []}
        selectedService={state.service.data.service}
        onSelectService={onSelectService}
        isMultipleStudentsSelected={isMultipleStudentsSelected}
        isInValidMultipleStudentSelection={isInValidMultipleStudentSelection}
      />
    </Box>
  );
};
