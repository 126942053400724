import {UpdateAppointmentRequest} from '../lib/api/types/Requests';
import {DurableActionResponse} from '../lib/api/types/Services';
import {fapper} from '../utils/fapper';

const updateAppointment = async (
  appointmentId: string,
  data: UpdateAppointmentRequest,
  accessToken?: string,
): Promise<DurableActionResponse | undefined> => {
  if (!accessToken) {
    return new Promise<undefined>((resolve) => resolve(undefined));
  }
  const responseData: DurableActionResponse = await fapper.patch(
    `${process.env.REACT_APP_API_URL}/trainingcoordinator/v1/appointments/${appointmentId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return responseData;
};

export {updateAppointment};
