import {Box, Select} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  onChangeMonth: (value: string) => void;
  month: string;
}

export const MonthSelector = ({onChangeMonth, month}: Props) => {
  const {t} = useTranslation();
  const currentDate = dayjs();

  return (
    <Box>
      <Box mb={2}>{t('service.start_month')}</Box>
      <Select
        name="month"
        placeholder={t('service.filter.month_year')}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          onChangeMonth(e.target.value)
        }
        value={dayjs(month).set('date', 1).format('MM-DD-YYYY')}
      >
        {[...Array(12)].map((_, index) => (
          <option
            key={index}
            value={currentDate
              .set('date', 1)
              .add(index, 'month')
              .format('MM-DD-YYYY')}
          >
            {currentDate.add(index, 'month').format('MMMM YYYY')}
          </option>
        ))}
      </Select>
    </Box>
  );
};
