import {ChevronRightIcon} from '@chakra-ui/icons';
import {Stack, Flex, Icon, Link, Box, Text} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {lighten} from '@chakra-ui/theme-tools';

interface NavItem {
  text?: string;
  path?: string;
}

export const DesktopSubNav = ({text, path}: NavItem) => {
  if (!text || !path) {
    return null;
  }
  return (
    <Link
      as={RouterLink}
      to={path}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: lighten('secondaryBackground', 10),
      }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{color: 'primaryLinkColor'}}
            fontWeight={500}
            mb={0}
          >
            {text}
          </Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{opacity: '100%', transform: 'translateX(0)'}}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'primaryLinkColor'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};
