import {Box, Grid, GridItem} from '@chakra-ui/react';
import {MonthSelector} from './MonthSelector';
import {PatientInput} from './PatientInput';
import {PlandefinitionSelector} from './PlandefinitionSelector';

interface Props {
  month: string;
  patient: string;
  service: string;
  onChangeMonth: (value: string) => void;
  onChangePatient: (value: string) => void;
  onChangePlandefinition: (value: string) => void;
  onError: (error?: Error) => void;
  onInputSubmit: any;
}

export const Filters = ({
  month,
  patient,
  service,
  onChangeMonth,
  onChangePatient,
  onChangePlandefinition,
  onError,
  onInputSubmit,
}: Props) => {
  return (
    <Grid
      templateColumns="0.5fr repeat(2, 1fr) 0.5fr"
      gap={4}
      mb={5}
      fontSize="medium"
    >
      <GridItem>
        <Box p={2}>
          <MonthSelector onChangeMonth={onChangeMonth} month={month} />
        </Box>
      </GridItem>
      <GridItem>
        <PatientInput
          onChangePatient={onChangePatient}
          onSubmit={onInputSubmit}
          patient={patient}
        />
      </GridItem>
      <GridItem>
        <PlandefinitionSelector
          onChangePlandefinition={onChangePlandefinition}
          service={service}
          onError={onError}
        />
      </GridItem>
      <GridItem />
    </Grid>
  );
};
