import {Appointment} from '../types/Appointment';
import {ActivityStatus} from '../types/AppointmentShared';
import {dateInFuture, dateInFuturePlus3hours} from './dates';

export const mockAppointmentDetails: Appointment = {
  appointmentId: 'cda81fe7-1f1d-eb11-a813-000d3ad91091',
  activityDefinitionId: '18b978b2-e9ca-4453-ac0a-2082d96637e6',
  title: 'First consult',
  preparations: 'Bring your identification',
  reminder: 'Reminder 1 day before',
  requester: 'Martin aka Practitionervan de Hoef',
  startTime: dateInFuture,
  endTime: dateInFuturePlus3hours,
  location: 'Oost',
  locationImageUri:
    'https://dkhmsmhisa02.blob.core.windows.net/mehealthi-app-content/images/locationmap-default.png',
  status: 'Proposed',
  statusValue: ActivityStatus.Booked,
  dueDate: dateInFuture,
  actionRequired: true,
  actionRequiredHeader: 'This is a test action',
  destination: 'Nederland',
  appointmentType: 'appointment_pcrtest',
  planDefinitionId: '45a5e56b-be5c-eb11-a812-000d3ab11b53',
  patientName: 'Test Testmans',
  planDefinitionName: 'Ploegleider basis',
  accountId: 'fedfe604-c1a0-43d8-854d-d7794a08d5c4',
  isFreeOfChargeCancellationDateExceeded: false,
  isProcessing: false,
  isStarted: false,
  employeeNumber: '178950',
  departmentCode: 'SPL-400',
};
